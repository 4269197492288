import { NgModule } from '@angular/core';
import { CommonModule, DatePipe, HashLocationStrategy, LocationStrategy } from '@angular/common';
import { LoadingBarComponent } from "./components/loading-bar/loading-bar.component";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'; 
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import {DateAdapter, MatNativeDateModule, MatRippleModule, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {MatIconModule} from '@angular/material/icon';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FicheClientComponent } from '../modules/pages/fiche-client/fiche-client.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { NgbdModalContent } from '../modules/components/model/model.component';
import { NumberDirective } from './SharedDirectives/numbers-only.directive';
import { Ng2TelInputModule } from 'ng2-tel-input';

import { MatDatepickerModule } from '@angular/material/datepicker';
import { NgbdModalPayContent } from '../modules/components/modelPay/modelpay.component';
import { CustomDateAdapter, MY_FORMATS } from './SharedDirectives/dateAdapter';
import {MatStepperModule} from '@angular/material/stepper';
import {  GooglePlaceModule } from 'ngx-google-places-autocomplete';
import {NgcCookieConsentConfig, NgcCookieConsentModule} from 'ngx-cookieconsent';
import { MadalRatingContent } from '../modules/components/madal-rating/madal-rating.component';
import {MatDialogModule} from '@angular/material/dialog'; 
import {MatButtonModule} from '@angular/material/button'; 
const cookieConfig:NgcCookieConsentConfig = {
  "cookie": {
    "domain": window.location.hostname
  },
  "position": "bottom",
  "theme": "classic",
  "palette": {
    "popup": {
      "background": "#000000",
      "text": "#ffffff",
      "link": "#ffffff"
    },
    "button": {
      "background": "#ff661c",
      "text": "#ffffff",
      "border": "transparent"
    }
  },
  "type": "opt-in",

  "layout": 'my-custom-layout',
  "layouts": {
    "my-custom-layout": '{{messagelink}}{{compliance}}'
  },
  "elements":{
    "messagelink": `
    <span id="cookieconsent:desc" class="cc-message">{{message}} 
      <a aria-label="Connaitre mieux sur les cookies" tabindex="0" class="cc-link" href="{{cookiePolicyHref}}" target="_blank" rel="noopener">{{cookiePolicyLink}}</a>, 
      <a  tabindex="1" class="cc-link" href="{{privacyPolicyHref}}" target="_blank" rel="noopener">{{privacyPolicyLink}}</a> et 
      <a  tabindex="2" class="cc-link" href="{{tosHref}}" target="_blank" rel="noopener">{{tosLink}}</a>
    </span>
    `, 
  },
  "content":{
    "allow": "Autoriser les cookies",
    "deny": "Refuser",
    "message": "Ce site web utilise des cookies pour s'assurer de son bon fonctionnement, pour personnaliser son contenu et ses publicités et pour analyser son tarif",
    "privacyPolicyLink": 'Vie Privée',
    "privacyPolicyHref": 'form/vie-privee',
    "tosLink": "En savoir plus",
    "tosHref": '/form/formulaire',
  }
}

const modules = [
  MatFormFieldModule,
  MatInputModule,
  MatSelectModule,
  BrowserAnimationsModule,
  MatRippleModule,
  MatIconModule,
  FlexLayoutModule ,
  FormsModule,
  ReactiveFormsModule,
  MatProgressSpinnerModule ,
  FormsModule,
  ReactiveFormsModule,
  MatCheckboxModule,
  MatNativeDateModule,
  MatDatepickerModule, 
  MatStepperModule,
  GooglePlaceModule,
  MatDialogModule,
  MatButtonModule
];

@NgModule({
  declarations: [
    LoadingBarComponent,
    NgbdModalContent,
    NgbdModalPayContent,
    MadalRatingContent,
    NumberDirective
    
  ],
  imports: [
    CommonModule,
    AutocompleteLibModule,
     Ng2TelInputModule.forRoot(),
     NgcCookieConsentModule,
     NgcCookieConsentModule.forRoot(cookieConfig),
    ...modules
  ],

  exports: [
    ...modules,
    Ng2TelInputModule,
    NumberDirective,
    LoadingBarComponent,
    AutocompleteLibModule
    
  ],
  providers: [ DatePipe, 
     {provide: DateAdapter, useClass: CustomDateAdapter},
     {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
    ],
})
export class SharedModule {
}
