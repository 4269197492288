import { GeneriqueService } from 'src/app/core/services/Generiqservice/generiqueservice.service';
import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, CanDeactivate, ParamMap, Route, Router } from '@angular/router';
import { ApicreationService } from 'src/app/core/services/apicreationservice.service';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ValidationUploadComponent } from './popupValidation/validation-upload/validation-upload.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-uploaddocpage',
  templateUrl: './uploaddocpage.component.html',
  styleUrls: ['./uploaddocpage.component.css']
})
export class UploaddocpageComponent implements OnInit {
  id_aff: string = '';
  PI = "c4ca4238a0b923820dcc509a6f75849b"
  CG = "26657d5ff9020d2abefe558796b99584"
  num_contrat: string = "";
  fileInput: File | null = null;
  certif_photo: File;
  piece_id: File;
  show_image: any = null;
  show_imagePiece: any = null;
  consulterFile: boolean = false;
  loader_file: boolean = false;
  showiconPiece: boolean = false;
  showiconcertif: boolean = false;
  showiconPieceerror: boolean = false;
  showiconcertiferror: boolean = false;
  pieceIdentite: boolean = false;
  Attestation: boolean = false;
  disabled: boolean = false;
  mail: string;
  protected urlEspaceClient = environment.urlEspaceClient;

  constructor(private activatedRoute: ActivatedRoute,
    private generiqueService: GeneriqueService,
    public dialog: MatDialog
  ) {
    this.activatedRoute.queryParams
      .subscribe(params => {
        this.mail = params.mail ? params.mail : '';
      });

    this.activatedRoute.paramMap.subscribe((params: ParamMap) => {
      this.id_aff = params.get('id_aff') || '';
      this.num_contrat = params.get('num_contrat?mail') || '';

    });
  }

  ngOnInit(): void {
  }

  openDialog() {
    this.dialog.open(ValidationUploadComponent, {
      data: {
        Attestation: this.Attestation,
        pieceIdentite: this.pieceIdentite,
        mail: this.mail

      },
      width: '500px',
      height: '150px'
    });

  }


  getTransmission(event: any, number: number) {
    this.loader_file = true;

    if (number === 1) {
      this.piece_id = event.target.files[0];
      this.uploaddoc(this.piece_id, 1);
      this.pieceIdentite = true;
      this.disabled = true;

    }
    else if (number === 2) {
      this.certif_photo = event.target.files[0];
      this.uploaddoc(this.certif_photo, 2)
      this.Attestation = true;
      this.disabled = true;

    }

  }
  uploaddoc(file: any, number: number) {
    const formdata: FormData = new FormData();
    if (number === 2)
      formdata.append("id_type_doc", this.CG);
    else if (number === 1)
      formdata.append("id_type_doc", this.PI);

    formdata.append("id_affaire", this.id_aff);

    formdata.append("file[]", file);
    this.generiqueService.uploadDoc(formdata).subscribe((m: any) => {

      this.loader_file = false;
      if (number === 1) {
        this.showiconPiece = true;
      }
      else {
        this.showiconcertif = true;
      }
    }, error => {
      this.loader_file = false;
      if (number === 1)
        this.showiconPieceerror = true;
      else
        this.showiconcertiferror = true;

    }), (error: any) => {
      this.loader_file = false;

    };
  }


  goToEspace() {
   console.log(this.mail)
  window.open(this.urlEspaceClient+'?mail='+this.mail, '_self')
  }

}
