import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subject, Subscription } from 'rxjs';
import { Apisuccessresponse } from 'src/app/shared/models/apisuccessresponse';
import { CheckBoxType } from 'src/app/shared/models/checkboxtype';
import { Insuranceinfo } from 'src/app/shared/models/insuranceinfo';
import { Formula } from 'src/app/shared/models/warranty';


@Injectable({
  providedIn: 'root'
})
export class ApicreationService  implements OnInit {
  private _listnersclient = new Subject<any>();
  private _listnersbike = new Subject<any>();
  private _listnersSubscribe = new Subject<any>();
  private _listnerspass = new Subject<any>();
  private _listnersesign= new Subject<any>();
  public ratedata : Apisuccessresponse[] =[];
  public rate : Apisuccessresponse[] =[];
  public rateaccident: Apisuccessresponse[] =[];
  public ratevalurneuf: Apisuccessresponse[] =[];
  public ratefranchise: Apisuccessresponse[] =[];
  public gift_price :number=0 ;
  public formulachoix : Formula ;
  public checkedoption:CheckBoxType ;
  public insuranceinfo :Insuranceinfo = new Insuranceinfo();
  public passToPay : boolean = false;
  public pagedata : number =0 ;
  private _listnersWarranty = new Subject<any>();
  private _listnersRate = new Subject<any>();
  private _listnersValAcc = new Subject<any>();
  constructor() { }
  ngOnInit(): void {


  }

  listenInfoClient(): Observable<any> {
    return this._listnersclient.asObservable();
  }
  listenInfoWarranty(): Observable<any> {
    return this._listnersWarranty.asObservable();
  }
  pushInfoClient(filterBy: any) {
    this._listnersclient.next(filterBy);
  }
  listenInfoBike(): Observable<any> {
    return this._listnersbike.asObservable();
  }

  listenSubscribe(): Observable<any> {
    return this._listnersSubscribe.asObservable();
  }
  listenpasstopay(): Observable<any> {
    return this._listnerspass.asObservable();
  }
  listenpasstosubscribe(): Observable<any> {
    return this._listnersesign.asObservable();
  }
  listenRate(): Observable<any> {
    return this._listnersRate.asObservable();
  }
  listenvaleuraccident(): Observable<any> {
    return this._listnersValAcc.asObservable();
  }
  pushInfoBike(filterBy: any) {
    this._listnersbike.next(filterBy);
  }
  pushInfoWarranty(filterBy: any , prix:number = 0) {
    let Info ;
    if ( prix != 0)
    Info = {filterBy, prix }
    else Info=filterBy ;
    this._listnersWarranty.next(Info);
  }
   pushInfoSubscribe(filterBy: any) {
    this._listnersSubscribe.next(filterBy);
  }
  pushpasstopay(filterBy: any) {
    this._listnerspass.next(filterBy);
  }
  pushpasstosubscribe(filterBy: any) {
  this._listnersesign.next(filterBy);
  }
  pushInforate(filterBy: any) {
    this._listnersRate.next(filterBy);
  }
  pushvaleuraccident(filterBy: any) {
    this._listnersValAcc.next(filterBy);
  }

  ngOnDestroy() {
    }
}
