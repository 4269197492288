import { Injectable,InjectionToken } from '@angular/core';
import { GeneriqueService } from '../Generiqservice/generiqueservice.service';
export const BASE_PATH = new InjectionToken<string>('basePath');


@Injectable({
  providedIn: 'root'
})
export class RatingserviceService {

  constructor(public generiqueService :GeneriqueService ) {}
  /**
   *Web services that give you the rating client
   *
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
   public Addrating( observe: any = 'body', reportProgress: boolean = false ){
    return this.generiqueService.PutApi(observe,reportProgress, '/rate')
   
  }
}
