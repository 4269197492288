import { DatePipe, DOCUMENT } from '@angular/common';
import { Component, EventEmitter, Inject, Input, OnInit, Output, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ApicreationService } from 'src/app/core/services/apicreationservice.service';
import { GeneriqueService } from 'src/app/core/services/Generiqservice/generiqueservice.service';
import { SubscriptionService } from 'src/app/core/services/subscription/subscriptionservice.service';
import { Apisuccessresponse } from 'src/app/shared/models/apisuccessresponse';
import { Bike } from 'src/app/shared/models/bike';
import { Insuranceinfo } from 'src/app/shared/models/insuranceinfo';
import { Epay_body } from 'src/app/shared/models/pay-body';
import { References } from 'src/app/shared/models/references';
import { Subscribe_body } from 'src/app/shared/models/subscribe_body';
import { NgbdModalContent } from '../../components/model/model.component';
import swal from 'sweetalert2';
import { Subscription } from 'src/app/shared/models/subscription';
import { FormControl, FormGroup } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { Stripe_body } from 'src/app/shared/models/pay-stripe-body';
import { timeout } from 'rxjs/operators';
import { MadalRatingContent } from '../../components/madal-rating/madal-rating.component';
import {ValidatorService} from 'angular-iban';

@Component({
  selector: 'app-ibanpage',
  templateUrl: './ibanpage.component.html',
  styleUrls: ['./ibanpage.component.css']
})
export class IbanpageComponent implements OnInit {
  iban: string = ""
  nomPrenomRs: string = "";
  @Output() changepagenumber: EventEmitter<number> = new EventEmitter();
  @Input() TotalAmount: number = 0;
  @Output() insuranceinfo: Insuranceinfo;
  @Input() pagedata: number = 0;
  @Output() ondatachanged = new EventEmitter<any>();
  useStripe: boolean = environment.useStripe;
  data: Apisuccessresponse;
  subscribe_body: Subscribe_body;
  formIban: FormGroup;
  storeRef: string;
  IndAccident: number;
  choixAccident: string;
  choixNeuf: string;
  urlScures: any;
  disabledNvOption: boolean = true;
  bike: Bike = new Bike();
  url_sign: string;
  DommageYear: number = 0;
  DommageMois: number = 0;
  garantieMois: number = 0;
  garantieYear: number = 0;
  DommageVolMois: number = 0;
  DommageVolYear: number = 0;
  public payementref: string;
  disabledformula: boolean = true;
  Esign_body: Insuranceinfo = new Insuranceinfo();
  stripe_body: Stripe_body = new Stripe_body();
  Epay_body: Epay_body = new Epay_body();
  insuranceinfoData: Insuranceinfo = new Insuranceinfo;
  fraisDossier: number;
  assistanceFees:number; 
  Oui: string = "Oui";
  retour: string = "Retour";
  esign: string;
  valeurTaxe: number
  Valider: string = "Valider";
  checkdonat: boolean = false;
  Non: string = "Non";
  streetNumberCompangnie: string = "";
  streetNameCompangnie: string = "";
  cityCompangnie: string = "";
  postalCodeCompangnie: string = "";
  countryCompangnie: string = "";
  dommage: boolean = false;
  theft: boolean = false;
  dommageAndTheft = false;
  caseiban : boolean=false ;
  value: String = '';
  formula: string = "";
  paymentRef: string = "";
  excessGarantie: boolean = false;
  valeurNeufGarantie: boolean = false;
  accidentGarantie: boolean = false;
  nboption: number = 0;
  loaderTransaction: boolean = false;
  loaderVerification: boolean = false;
  loaderGenration: boolean = false;
  loaderSubscription: boolean = false;
  loaderGloabal: boolean = false;
  checksubscribeTimeout: any;
  checkStripeStatusTimeout: any;
  checkStripeStatusCounter: number = 0;
  passtosubscribeSubscription: any;
  passtopaySubscription: any;
  dateEffet:Date=new Date();
  firstAmount:number;
  firstDateAmount:any;
  firstLastDate:any;
  typeContrat:string=""; 
  protected pathFiles = environment.Pathfiles;
  protected nameFilesNi = environment.namefilesNi;
  protected nameFilesIpidAssistance=environment.namefilesIpidAssistance
  fraisAssistance:number=0;
  isAssistance:boolean=false; 
  constructor(@Inject(DOCUMENT) private document: Document, private datePipe: DatePipe, public sanitizer: DomSanitizer, private modalService: NgbModal, private subscriptionService: SubscriptionService,
    private generiqueService: GeneriqueService, private apicreationService: ApicreationService,) { }

  ngOnInit(): void {

    var date = new Date();
   this.dateEffet.setDate(date.getDate() + 1);
    this.caseiban = false ;
    this.formIban = new FormGroup({
      IBANconsent: new FormControl(),
      iban: new FormControl( '' , ValidatorService.validateIban)  ,
      nomPrenomRs:new FormControl( '')

    });
    let rate = this.apicreationService.ratedata.forEach((data: Apisuccessresponse) => {
      this.firstAmount= data.rate.firstAmount;
      this.firstDateAmount=  this.datePipe.transform( data.rate.firstFromDate, "dd/MM/yyyy");
      this.firstLastDate=this.datePipe.transform( data.rate.firstLastDate, "dd/MM/yyyy");
      this.fraisDossier = data.rate.AdministrationFees; 
      this.assistanceFees=data.rate.AssistanceFees; 
      this.insuranceinfoData = this.apicreationService.insuranceinfo;
      this.nomPrenomRs= this.insuranceinfoData.client.firstName+ " "+this.insuranceinfoData.client.lastName
      if (data.warranty.Excess == "0%") {
        this.excessGarantie = true;
        this.nboption++;
      }

      if (data.warranty.NVOption == "Yes") {
        this.valeurNeufGarantie = true;
        this.nboption++;
      }
      if (data.warranty.PAOption == "Yes") {
        this.accidentGarantie = true;
        this.nboption++;
      }


      let ratev = data.rate.TotalAmountAllTaxesIncluded;

      this.garantieYear = Number(ratev.toFixed(2));
      let num = ratev / 12;
      this.garantieMois = Number(num.toFixed(2));
      this.formula = data.warranty.Formula;

      if (this.insuranceinfoData.client.company != null) {
        this.streetNumberCompangnie = this.insuranceinfoData.client.company.address.streetNumber;
        this.streetNameCompangnie = this.insuranceinfoData.client.company.address.streetName;
        this.cityCompangnie = this.insuranceinfoData.client.company.address.city;
        this.postalCodeCompangnie = this.insuranceinfoData.client.company.address.postalCode;
        this.countryCompangnie = this.insuranceinfoData.client.company.address.country;
      }
      this.isAssistance=this.apicreationService.insuranceinfo.isAssistance; 
      if(this.isAssistance){
        this.fraisAssistance=2.17;  
      }

      if (this.formula == "Damage and Theft") {

        this.dommageAndTheft = true;
        this.typeContrat="Dommage & Vol"

      } else if (this.formula == "Theft") {

        this.theft = true;
        this.typeContrat="Vol"

      }
      else {

        this.dommage = true;
        this.typeContrat="Dommage"

      }

    });


  }

  isActiveIBANconsent(event: any) {
    var idform = this.document.getElementById('invalidCheckIBAN');
    if (event!.target.checked) {
      this.caseiban = true;
      idform?.classList.remove("is-invalid");
    } else {
      this.caseiban = false;
      idform?.classList.add('is-invalid');
    }
  }

  Previouspage(valueemitted: any) {
    this.changepagenumber.emit(valueemitted)
  }


  Nextpage() {
    if (this.formIban.valid) {
      this.openiframe();
    } else if (!this.caseiban){
      this.alertWarning("Merci de cocher la case de consentement de paiement")
    }else if (this.formIban.get('iban')?.touched || this.formIban.get('iban')?.hasError('required')){
      this.alertWarning("Vous devez vérifier votre IBAN")
    }
    else if (this.formIban.get('nomPrenomRs')?.touched || this.formIban.get('nomPrenomRs')?.hasError('required')){
      this.alertWarning("Vous devez verifier le nom & prénom ou RS du titulaire de compte ")
    }


  }



  openiframe() {
    this.loaderGloabal = true;
    this.loaderTransaction = true;
    this.Esign_body = new Insuranceinfo();
    this.Esign_body.references = new References();
    this.data = this.apicreationService?.ratedata[0];
    this.Esign_body = this.apicreationService.insuranceinfo;
    this.Esign_body.references = this.data.references;
    this.apicreationService.insuranceinfo.client.IBAN = this.iban;
    this.apicreationService.insuranceinfo.client.AccountHolder=this.nomPrenomRs;
    this.subscriptionService.electroniquePayStripe(this.Esign_body).pipe(timeout(20000)).subscribe(
      (data: Apisuccessresponse) => {
        this.loaderTransaction = false;
        this.paymentRef = data.references.paymentRef;
        this.loaderVerification = true;
        setTimeout(() => {
          this.checkStripeStatus()
          this.Esign_body.references= data.references;
        
          this.passtopaySubscription = this.apicreationService.listenpasstopay().subscribe(m => {
            clearTimeout(this.checkStripeStatusTimeout)
            if (m === "succeeded") {
              this.passtopaySubscription.unsubscribe()
              this.loaderVerification = false;
              this.loaderGenration = true;
              this.yousing(this.Esign_body)
            } else if (m === "paymentfailed") {
              this.passtopaySubscription.unsubscribe()
              this.alertWarning("Paiment echoué , merci de réessayer ")
              this.loaderTransaction = false;
              this.loaderVerification = false;
              this.loaderGenration = false;
              this.loaderSubscription = false;
              this.loaderGloabal = false;
            }

          })
        }, 2000);

      },
      error => {
        if (error.code === 400 || error?.error?.code === 400) {
          this.alertWarning("Les paramètres transmis ne permettent pas d'établir un paiement")
        } else if (error.code === 422 || error?.error?.code === 422) {
          this.alertWarning("Merci de verfier votre Iban")
        } else {
          this.generiqueService.alertWarning(error)
        }
        this.loaderTransaction = false;
        this.loaderVerification = false;
        this.loaderGenration = false;
        this.loaderSubscription = false;
        this.loaderGloabal = false;
      }
    );



  }


  yousing(Esign_body: any) {
    this.subscriptionService.electroniquesignatureStripe(Esign_body).pipe(timeout(56000)).subscribe(
      (data: Apisuccessresponse) => {

        let options: NgbModalOptions = {
          ariaLabelledBy: 'myBasicModalLabel',
          size: 'xl',
          centered: true,
          animation: true,
          backdrop: 'static',
          keyboard: false

        };

        const modeldoc = this.modalService.open(NgbdModalContent, options);
        modeldoc.componentInstance.loader_garantie = true;
        this.loaderGloabal = false;
        this.esign = data.references.eSignRef
        this.apicreationService.insuranceinfo.references = data.references;
        this.Esign_body.references = data.references
        this.url_sign = data.redirect_url;
        modeldoc.componentInstance.srcurl = this.sanitizer.bypassSecurityTrustResourceUrl(this.url_sign);
        modeldoc.componentInstance.loader_garantie = false;
        this.loaderGenration = true;
        this.checksubscribeTimeout = setTimeout(() => {
          this.checksubscribe();
        }, 700);
        this.loaderGenration = false;
        this.passtosubscribeSubscription = this.apicreationService.listenpasstosubscribe().subscribe(m => {
          if (m) {
            modeldoc.close();
            this.passtosubscribeSubscription.unsubscribe()
            clearTimeout(this.checksubscribeTimeout)
            this.subscriptiondemarche(this.Esign_body)
          }
        });


      },
      error => {
        if (error.code === 422 || error?.error?.code === 422) {
          this.alertWarning("Numéro de téléphone mobile non acceptable pour la signature électronique")
        } else {
          this.generiqueService.alertWarning(error)
        }

        this.loaderTransaction = false;
        this.loaderVerification = false;
        this.loaderGenration = false;
        this.loaderSubscription = false;
        this.loaderGloabal = false;
      }
    );



  }

  alertWarning(error: any) {

    swal.fire({
      title: '',
      icon: 'warning',
      html: error,
      showConfirmButton: true,
      showCloseButton: true,
      confirmButtonText: 'Fermer',
      confirmButtonColor: '#e75e5e',
    })
  }

  checksubscribe() {
    if (this.esign)
      this.subscriptionService.checksign(this.esign).subscribe((test: any) => {
        if (test?.is_signed === true) {
          this.apicreationService.pushpasstosubscribe(true);

        } else
          if (test?.is_signed !== true)
            this.checksubscribe();

      }, error => {
        if (error.code === 400 || error?.error?.code === 400) {
          this.alertWarning("Merci de vérifier vos informations")
        } else if (error.code === 503 || error?.error?.code === 503) {
          this.alertWarning("Service Stripe non disponible")
        } else {
          this.generiqueService.alertWarning(error)
        }
        this.loaderTransaction = false;
        this.loaderVerification = false;
        this.loaderGenration = false;
        this.loaderSubscription = false;
        this.loaderGloabal = false;
      }
      )
  }




  checkStripeStatus() {
    this.subscriptionService.checkPayStripe(this.paymentRef).pipe(timeout(20000)).subscribe(
      (test: any) => {
        if (test?.status === "succeeded" || ++this.checkStripeStatusCounter >= 5) {
          this.apicreationService.pushpasstopay("succeeded");
        } else if (test?.status === "processing") {
          this.checkStripeStatusTimeout = setTimeout(() => {
            this.checkStripeStatus()
          }, 2000);
        } else {
          this.apicreationService.pushpasstopay("paymentfailed");
        }


      },
      error => {
        if (error.code === 400 || error?.error?.code === 400) {
          this.alertWarning("Merci de vérifier vos informations")
        } else if (error.code === 503 || error?.error?.code === 503) {
          this.alertWarning("Service Stripe non disponible")
        } else {
          this.generiqueService.alertWarning(error)
        }
        this.loaderTransaction = false;
        this.loaderVerification = false;
        this.loaderGenration = false;
        this.loaderSubscription = false;
        this.loaderGloabal = false;
      }
    )
  }







  subscriptiondemarche(data: any) {
    this.loaderGloabal = true;
    this.loaderSubscription = true;
    this.subscribe_body = new Subscribe_body();
    this.subscribe_body.insurance = this.apicreationService.insuranceinfo;
    this.subscribe_body.insurance.references = data.references;
    this.subscribe_body.subscription = new Subscription();
    this.subscribe_body.subscription.StartDateOfCover = this.datePipe.transform(new Date(), "yyyy-MM-ddThh:mm:ss") + "Z";
    this.subscribe_body.subscription.StartDateOfPolicy = this.datePipe.transform(new Date(), "yyyy-MM-ddThh:mm:ss") + "Z";
    this.subscribe_body.subscription.EndDateOfCover = this.datePipe.transform((new Date()).setDate((new Date()).getDay() + 365), "yyyy-MM-ddThh:mm:ss")! + "Z";
    this.subscribe_body.subscription.EndDateOfPolicy = this.datePipe.transform((new Date()).setDate((new Date()).getDay() + 365), "yyyy-MM-ddThh:mm:ss")! + "Z";

    this.subscriptionService.AddsubsciptionStripe(this.subscribe_body).pipe(timeout(120000)).subscribe(
      (data: any) => {

        this.loaderGloabal = false;
        this.loaderSubscription = false;
        this.apicreationService.pushInfoSubscribe(data)
        this.changepagenumber.emit(1);
        let optionsRatings: NgbModalOptions = {
          ariaLabelledBy: 'myBasicModalLabel',
          centered: true,
          animation: true,
          backdrop: 'static',
          keyboard: false

        };
        const modelraiting = this.modalService.open(MadalRatingContent, optionsRatings);




      },
      error => {

        if (error.code === 400 || error?.error?.code === 400) {

          this.alertWarning("Merci de vérifier vos informations")
        } else if (error.code === 404 || error?.error?.code === 404) {

          this.alertWarning("Service Stripe non disponible")
        }
        else if (error.code === 406 || error?.error?.code === 406) {

          this.alertWarning("Non acceptable,  merci de vérifier vos informations")
        }
        else if (error.code === 409 || error?.error?.code === 409) {

          this.alertWarning(" QuoteReference Déja abonné , veuillez réessayer de nouveau ... ")
        }
        else if (error.code === 416 || error?.error?.code === 416) {

          this.alertWarning(" veuillez réessayer de nouveau ")
        }
        else {
          this.generiqueService.alertWarning(error)
        }

        this.loaderTransaction = false;
        this.loaderVerification = false;
        this.loaderGenration = false;
        this.loaderSubscription = false;
        this.loaderGloabal = false;

      })


  }


  goToIPID() {
    window.open(`${this.pathFiles}${this.nameFilesIpidAssistance}`, "_blank");
  }

  goToNI() {
    window.open(`${this.pathFiles}${this.nameFilesNi}`, "_blank");
  } 


}
