<div class="col-md-10 offset-md-1 card h-100" id="infovelo">
    <div class="card-body">
        <div class="mb-md-5 mt-md-1  titreRecap text-center ">
            <h3>Informations vélo</h3>
        </div>

        <div [formGroup]="formBike" class="example-form">
            <div class="row mt-md-3">
                <mat-form-field>
                    <mat-label>Type de vélo </mat-label>
                    <mat-select [(ngModel)]="bike.type" required formControlName="Type">
                        <mat-option *ngFor="let biketype of biketypeEnum |keyvalue" [value]="biketype.key">
                            {{biketype.value}}
                        </mat-option>
                    </mat-select>

                    <mat-error *ngIf="formBike.controls['Type'].hasError('required') ">Type de vélo obligatoire
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="row mt-md-3 ">
                <div class="col-md-6 col-sm-12 ">

                    <mat-form-field class="w-100">
                        <mat-label>Prix de votre vélo (€)</mat-label>
                        <input type="number" [(ngModel)]="bike.purchasePrice"
                            pattern="(^[0-9]{0,4}$)|(^[0-9]{0,4}\.[0-9]{0,2}$)" min="0" step=".01"
                            formControlName="Prix dachat" required matInput name="prix">
                        <mat-error *ngIf="formBike.controls['Prix dachat'].hasError('required') ">Prix de vélo
                            obligatoire</mat-error>
                        <mat-error
                            *ngIf="!formBike.controls['Prix dachat'].hasError('required')  && formBike.controls['Prix dachat'].dirty  && formBike.controls['Prix dachat'].invalid ">
                            Prix de vélo doit etre inferieur a 8000 € et superieur a 100 € </mat-error>

                    </mat-form-field>

                </div>
                <div class="col-md-6 col-sm-12 ">


                    <mat-form-field class="w-100">
                        <mat-label>Date d'achat</mat-label>
                        <input type="date" [(ngModel)]="bike.purchaseDate" id="bday" formControlName="Date dachat"
                            max="{{myDate | date:'yyyy-MM-dd'}}" pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}" required matInput
                            name="Date d'achat">
                        <mat-error *ngIf="formBike.controls['Date dachat'].hasError('required') ">Date d'achat de vélo
                            obligatoire</mat-error>
                        <mat-error
                            *ngIf="  formBike.controls['Date dachat'].hasError('notconfirmid') || formBike.controls['Date dachat'].invalid && !formBike.controls['Date dachat'].hasError('required')  ">
                            Merci de vérfier la date d'achat
                        </mat-error>
                    </mat-form-field>

                </div>
            </div>
            <div class="row mt-md-3">
                <div class="col-md-6 col-sm-12 ">
                    <mat-form-field class="w-100">
                        <mat-label>Pour quels types de déplacements ?</mat-label>
                        <mat-select [(ngModel)]="bike.usage" required formControlName="Usage">
                            <mat-option *ngFor="let type of bikeusageEnum |keyvalue" [value]="type.key">
                                {{type.value}}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="formBike.controls['Usage'].hasError('required') ">Type de déplacements
                            obligatoire</mat-error>
                    </mat-form-field>

                </div>


                <div class="col-md-6 col-sm-12 ">
                    <mat-form-field class="w-100">
                        <mat-label>Mon vélo est equipé d'un trackeur GPS</mat-label>
                        <mat-select [(ngModel)]="bike.TrackerGPS" required formControlName="Gps">
                            <mat-option *ngFor="let type of bikeGpsEnum |keyvalue" [value]="type.key">
                                {{type.value}}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="formBike.controls['Gps'].hasError('required') ">GPS obligatoire</mat-error>
                    </mat-form-field>

                </div>
            </div>
            <div class="row mt-md-3 ">
                <div class="col-md-4 col-sm-12 ">
                    <mat-form-field class="w-100">
                        <mat-label>Vitesse maximum (Km/h)</mat-label>
                        <input type="number" numbersOnly matInput [(ngModel)]="bike.maximumSpeed"
                            formControlName="Vitesse maximum" required name="vitesse">
                        <mat-error *ngIf="formBike.controls['Vitesse maximum'].hasError('required') ">Vitesse de vélo
                            obligatoire</mat-error>
                        <mat-error
                            *ngIf="!formBike.controls['Vitesse maximum'].hasError('required')  && formBike.controls['Vitesse maximum'].dirty  && formBike.controls['Vitesse maximum'].invalid ">
                            La vitesse maximale doit être inférieure à 25km/h </mat-error>
                    </mat-form-field>

                </div>

                <div class="col-md-4 col-sm-12 ">
                    <mat-form-field class="w-100">
                        <mat-label>Numéro de série</mat-label>
                        <input name="numeroserie" type="text" [(ngModel)]="bike.serialNumber" formControlName="N°série"
                            matInput numbersOnly>
                    </mat-form-field>
                </div>
                <div class="col-md-4 col-sm-12 ">
                    <mat-form-field class="w-100">
                        <mat-label>Numéro de gravage</mat-label>
                        <input name="numerogravure" type="text" required [(ngModel)]="bike.etchingNumber"
                            formControlName="Numero de gravage" matInput>
                        <mat-error *ngIf="formBike.controls['Numero de gravage'].hasError('required') ">Numéro de
                            gravage obligatoire</mat-error>

                    </mat-form-field>
                </div>
            </div>
            <div class="row mt-md-3">
                <mat-form-field class="w-100">
                    <mat-label>Pour quelle pratique principale ?</mat-label>
                    <mat-select [(ngModel)]="bike.classification" required formControlName="Classification">
                        <mat-option *ngFor="let bikeclass of bikeclassification |keyvalue" [value]="bikeclass.key">
                            {{bikeclass.value}}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="formBike.controls['Classification'].hasError('required') ">pratique principale
                        obligatoire</mat-error>
                </mat-form-field>
            </div>
            <div class="row mt-md-3">
                <div class="col-md-6 col-sm-12 ">
                    <mat-form-field class="w-100">
                        <mat-label>Marque de votre vélo</mat-label>
                        <input name="marque" [(ngModel)]="bike.brand" required formControlName="Marque" type="text"
                            matInput>
                        <mat-error *ngIf="formBike.controls['Marque'].hasError('required') ">Marque de vélo obligatoire
                        </mat-error>

                    </mat-form-field>
                </div>
                <div class="col-md-6 col-sm-12">
                    <mat-form-field class="w-100">
                        <mat-label>Modèle de votre vélo</mat-label>
                        <input name="modèle" [(ngModel)]="bike.model" required formControlName="Modèle" type="text"
                            matInput>
                        <mat-error *ngIf="formBike.controls['Modèle'].hasError('required') ">Modèle de vélo obligatoire
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="row mt-md-3">





                <div class="col-md-12 col-sm-12 form-check ">
                    <input class="form-check-input" formControlName="FormulaireCGU" name='FormulaireCGU'
                        (change)="isActiveformulaireCG($event)" type="checkbox" value=" " id="invalidCheck4"
                        aria-describedby="invalidCheck4Feedback" required>
                    <label class="form-check-label" for="invalidCheck4">
                        J'accepte les <u (click)="goToForm()">CGU</u> de ce site .
                    </label>
                </div>


                <div class="col-md-12 col-sm-12 form-check ">
                    <input class="form-check-input" formControlName="Formulairedeux" name='Formulairedeux'
                        (change)="isActiveformulairedeux($event)" type="checkbox" value=" " id="invalidCheck3"
                        aria-describedby="invalidCheck3Feedback" required>
                    <label class="form-check-label" for="invalidCheck3">
                        J’atteste sur l’honneur ne pas avoir connaissance d’un évènement pouvant entrainer l’application
                        des garanties du présent contrat. En cas de fausses déclarations de mauvaise foi, sur la nature,
                        les causes, les circonstances ou les conséquences
                        d’un sinistre, vous serez entièrement déchu de tout droit à garantie pour ce sinistre.
                    </label>
                </div>


                <div class="col-md-12 col-sm-12 form-check ">
                    <input class="form-check-input" formControlName="Formulaire" name='Formulaire' type="checkbox"
                        value=" " id="invalidCheck2" aria-describedby="invalidCheck2Feedback">
                    <label class="form-check-label">
                        J'autorise Berilish à m'envoyer via email ou courrier postal des informations liées aux
                        contrats assurance auquel
                        je souscris ou les offres susceptibles de m'intéresser.
                    </label>
                </div>

            </div>
        </div>


    </div>
    <div class="card-footer">
        <div class="row mt-4 justify-content-around">
            <app-buttonretour class="w-auto m-1" (changepreviouspage)="Previouspage($event)" [label]="retour">
            </app-buttonretour>
            <app-button class="w-auto m-1" (changepagesuivante)="Nextpage($event)" [label]="suivant"></app-button>
        </div>
    </div>
</div>