export class Warranty {
    Formula:Formula;
    PAOption:string;
    NVOption:string;
    Excess:string;  
}


  export enum Formula {
    'Damage'='Dommage',   
    'Theft'='vol',  
    'Damage and Theft'='Dommages et vol',
  
  }