import { Bike } from "./bike";
import { Client } from "./client";
import { References } from "./references";

export class Insuranceinfo {
    references:References ;
    bike:Bike;
    client:Client;
    CommissionRate:string;
    CommercialLatitude:string;
    isAssistance:boolean=false; 

}
