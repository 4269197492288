<mat-form-field class="col-md-12  col-sm-12 example-full-width" appearance="standard"  >
    <mat-label>Adresse</mat-label>
    <input type="text" matInput ngx-google-places-autocomplete   #placesRef="ngx-places"
           (onAddressChange)="handleAddressChange($event)"
           placeholder="5 rue pirat, ..."  >
    <mat-icon [ngStyle]="{color: iconColor}" matSuffix>room</mat-icon>
  </mat-form-field>
  
  

  