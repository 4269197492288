import { Component, Input, OnInit } from '@angular/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { MadalRatingContent } from '../../components/madal-rating/madal-rating.component';
import { Router } from '@angular/router';
import { Stripe_body } from 'src/app/shared/models/pay-stripe-body';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-successpage',
  templateUrl: './successpage.component.html',
  styleUrls: ['./successpage.component.css']
})
export class SuccesspageComponent implements OnInit {
 @Input() name : string ; 
 @Input() reference :string ; 
 @Input() email :string ; 
 @Input()civilite :string ; 
 useStripe: boolean = environment.useStripe;
 stripeBody : Stripe_body = new Stripe_body();
 Assurer :string ="Assurer un autre vélo"
  constructor(private modalService: NgbModal,private router: Router) { }

  ngOnInit(): void {


  }

  openiratingpage()
  { 
    let options: NgbModalOptions = {
      ariaLabelledBy: 'myBasicModalLabel',
      size: 'md',
      centered :true,
      animation :true,
      backdrop: true,
      keyboard: true,
    };
   
 
    const modeldoc =this.modalService.open(MadalRatingContent,options);
       


    modeldoc.componentInstance.event.subscribe((data:any) => {
      modeldoc.close();
    
    });
  }

  reload()
  {
    localStorage.clear()
    location.reload()

    this.router.navigate(['/']);

  }

}
