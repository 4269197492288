<div class="bodyDiv ">
  <div class="cover "   >

   <div class="card card1 text-center mx-auto">
     <div class="card-body">
       <img class="header-logo" src="/assets/images/logo header.png" alt="bysur Logo">
     </div>
   </div>
 
   <div class="card card2 text-center mx-auto mt-3">
     <div class="card-body text-center mx-auto  " >
          <div class="item3 grid-item">
           <div class="row  " >
           <div class="body-email" >
             <h4 class="mt-3 titleContrat">CONTRAT N° {{num_contrat}}</h4>
             <p class="text-center" style="padding: 40px;"> Afin de finaliser votre contrat d'assurance,
              veuillez nous transmettre votre carte nationale d'identité et
              l'attestation de marquage de votre vélo assuré</p>
              <div class="row buttonRow text-center " >
               <div class="col-6 align-items-center">
                 <div class="Piece_idbutton  row offset-2  ">
                   <div class="d-flex  align-items-center ">
                     <input type="file" id="idpiece" (change)="getTransmission($event,1)" hidden />
       
       
                     <label for="idpiece">
                       <svg  class="mx-3 conup2" xmlns="http://www.w3.org/2000/svg" width="20" height="29.996"
                         viewBox="0 0 30 29.996">
                         <path id="upload-solid"
                           d="M17.344,22.571H12.656a1.4,1.4,0,0,1-1.406-1.406V11.321H6.111a1.17,1.17,0,0,1-.826-2L14.2.4a1.135,1.135,0,0,1,1.6,0l8.918,8.918a1.17,1.17,0,0,1-.826,2H18.75v9.844A1.4,1.4,0,0,1,17.344,22.571ZM30,22.1v6.563a1.4,1.4,0,0,1-1.406,1.406H1.406A1.4,1.4,0,0,1,0,28.664V22.1A1.4,1.4,0,0,1,1.406,20.7H9.375v.469a3.283,3.283,0,0,0,3.281,3.281h4.688a3.283,3.283,0,0,0,3.281-3.281V20.7h7.969A1.4,1.4,0,0,1,30,22.1Zm-7.266,5.156a1.172,1.172,0,1,0-1.172,1.172A1.175,1.175,0,0,0,22.734,27.258Zm3.75,0a1.172,1.172,0,1,0-1.172,1.172A1.175,1.175,0,0,0,26.484,27.258Z"
                           transform="translate(0 -0.075)" fill="#161616" />
                       </svg>
                       Charger "Pièce d'identité"
                     </label>
                   </div>
                 </div>
                 <div class="col img  mt-2">
                  <span *ngIf="showiconPiece" style="color: green;white-space: nowrap;">
                    <img class="mx-1 img-icon" src="/assets/svg/check-o-svgrepo-com.svg" >
                    Document transmis avec succès 
                  </span>
<!--                     <img class="mx-1 img-icon" src="/assets/svg/x-close-delete-svgrepo-com.svg" *ngIf="showiconPieceerror">
 -->                 <!--  <span *ngIf="showiconPieceerror" style="color: #cc0000;white-space: nowrap;">
                    <img class="mx-1 img-icon" src="/assets/svg/x-svgrepo-com.svg" >
                   Erreur lors du téléchargement
                  </span> -->
               </div>
               </div>
               <div class="col-6 align-items-center">
                 <div class="certif_gravbutton row  ">
                   <div class="d-flex  align-items-center " style="white-space: nowrap;">
                     <input type="file" id="idCerti" (change)="getTransmission($event ,2)" hidden  />
                     <label for="idCerti">
                       <svg  class="mx-2 iconup1" xmlns="http://www.w3.org/2000/svg" width="20" height="29.996"
                         viewBox="0 0 30 29.996">
                         <path id="upload-solid"
                           d="M17.344,22.571H12.656a1.4,1.4,0,0,1-1.406-1.406V11.321H6.111a1.17,1.17,0,0,1-.826-2L14.2.4a1.135,1.135,0,0,1,1.6,0l8.918,8.918a1.17,1.17,0,0,1-.826,2H18.75v9.844A1.4,1.4,0,0,1,17.344,22.571ZM30,22.1v6.563a1.4,1.4,0,0,1-1.406,1.406H1.406A1.4,1.4,0,0,1,0,28.664V22.1A1.4,1.4,0,0,1,1.406,20.7H9.375v.469a3.283,3.283,0,0,0,3.281,3.281h4.688a3.283,3.283,0,0,0,3.281-3.281V20.7h7.969A1.4,1.4,0,0,1,30,22.1Zm-7.266,5.156a1.172,1.172,0,1,0-1.172,1.172A1.175,1.175,0,0,0,22.734,27.258Zm3.75,0a1.172,1.172,0,1,0-1.172,1.172A1.175,1.175,0,0,0,26.484,27.258Z"
                           transform="translate(0 -0.075)" fill="#ffffff" />
                       </svg>
                      Charger "Attestation de marquage"
                     </label>
                   </div>
         
                 </div>
                 <div class="col-2 img mt-2">
                  <span *ngIf="showiconcertif" style="color: green;white-space: nowrap;">
                  <img class="mx-1 img-icon" src="/assets/svg/check-o-svgrepo-com.svg" >
                  Document transmis avec succès  
                </span>
             <!--    <span *ngIf="showiconcertiferror" style="color: #cc0000;white-space: nowrap;">
                  <img class="mx-1 img-icon" src="/assets/svg/x-svgrepo-com.svg" >
                 Erreur lors du téléchargement
                </span> -->
              <!--      <img class="mx-1  img-icon" src="/assets/images/check-mark.png" *ngIf="showiconcertif">
                   <img class="mx-1 img-icon" src="/assets/images/cross_icon.png" *ngIf="showiconcertiferror"> -->
                 </div>
               </div>
              </div>
           </div>
           </div>
               <div class=" text-center mt-3 mb-3">
      <button class="validButton" mat-raised-button [disabled]="!disabled" [ngStyle]="{'opacity' :  disabled  ? '1' : '0.5' }"  (click)="openDialog()" style="background-color: green; color: white; width: 250px; justify-content: center;" *ngIf="!Attestation || !pieceIdentite" >Valider le chargement</button>
      <button class="validButton" mat-raised-button [disabled]="!disabled" [ngStyle]="{'opacity' :  disabled  ? '1' : '0.5' }"  (click)="goToEspace()" style="background-color: green; color: white; width: 250px; justify-content: center;" *ngIf="Attestation&& pieceIdentite">Mon espace Bysur</button>

  
     </div>
     </div>
 
   </div>
  
<!--    <button  mat-button (click)="openDialog()">Valider le téléchargement</button>
 -->
 </div> 
  </div>
  <div class="grid-container2 ">
   <div class="grid-item footerLogo"> 
     <img class="logo-footer" src="/assets/images/logo footer (1).png" alt="bysur Logo" >
     <p class="footer-text">
       Bysur SAS - 122 Avenue de Wagram, 75017 Paris</p>
     </div>
   <div class="grid-item item5 ">
     <p class="Copyright"> 2023 ©  <a target="_blank" href="https://bysur.com/">Bysur</a> - Tous Droits Réservés ® .</p>
 
   </div>
 
 </div>
 </div>


<!-- <div class="image_outer  mt-0 " id="changepage">

  <div class="justify-content-center" id="clickpage">
    <div class="row">
      <div class="col-12 p-0 headerstyle">
        <app-header></app-header>
      </div>
    </div>
    <div class="row w-100   marginpage mx-0 ">

      <div class=" d-grid p-3 pt-5  justify-content-center center-card">

        <div class="card  rounded-3   ">
          <div class="card-body ">
            <div class="col-md-10 offset-md-1 border-0 ">

              <div class="card-body border-0">
                <img src="/assets/images/logo header.png" alt="bysur Logo">
              </div>
            </div>
          </div>
        </div>

      </div>
      <div class="col-md-12 p-0 mt-0">
        <div class="card  mb-5 mt-5 rounded-3 offset-xl-3 col-xl-6  offset-lg-2 col-lg-8  offset-md-1 col-md-9 ">
          <div class="card-body p-5">
            <div class="col-md-10 offset-md-1 border-0 card h-100">

              <div class="card-body border-0">
                <div class=" mb-md-5 mt-md-1 text-center">
                  <div class="row">
                    <h3 class="mb-5 contrat"><b>CONTRAT N° {{num_contrat}}</b> </h3>
                    <p class=" text-contrat">
                      Afin de finaliser votre contrat d'assurance, veuillez transmettre votre
                      carte d'identité nationale et le certificat de marquage de votre vélo.</p>
                  </div>
                </div>
                <div class="row d-grid justify-content-center  mb-3" *ngIf="loader_file">
                  <ng-container class="text-center">

                    <div class="spinner-style">
                      <mat-spinner [diameter]="30"></mat-spinner>
                    </div>

                  </ng-container>
                </div>
                <div class="justify-content-center ">
                  <div class="grid-container">
                    <div class="grid-item">
                      <div class="Piece_idbutton  row offset-1  ">
                        <div class="d-flex justify-content-center align-items-center text-center">
                          <input type="file" id="idpiece" (change)="getTransmission($event,1)" hidden />


                          <label for="idpiece">
                            <svg class="mx-3" xmlns="http://www.w3.org/2000/svg" width="30" height="29.996"
                              viewBox="0 0 30 29.996">
                              <path id="upload-solid"
                                d="M17.344,22.571H12.656a1.4,1.4,0,0,1-1.406-1.406V11.321H6.111a1.17,1.17,0,0,1-.826-2L14.2.4a1.135,1.135,0,0,1,1.6,0l8.918,8.918a1.17,1.17,0,0,1-.826,2H18.75v9.844A1.4,1.4,0,0,1,17.344,22.571ZM30,22.1v6.563a1.4,1.4,0,0,1-1.406,1.406H1.406A1.4,1.4,0,0,1,0,28.664V22.1A1.4,1.4,0,0,1,1.406,20.7H9.375v.469a3.283,3.283,0,0,0,3.281,3.281h4.688a3.283,3.283,0,0,0,3.281-3.281V20.7h7.969A1.4,1.4,0,0,1,30,22.1Zm-7.266,5.156a1.172,1.172,0,1,0-1.172,1.172A1.175,1.175,0,0,0,22.734,27.258Zm3.75,0a1.172,1.172,0,1,0-1.172,1.172A1.175,1.175,0,0,0,26.484,27.258Z"
                                transform="translate(0 -0.075)" fill="#2c4f6c" />
                            </svg>
                            Transmettre "Piéce d'identité"
                          </label>
                        </div>
                      </div>
                      <div class="col-2 img">
                        <img class="mx-1 img-icon" src="/assets/images/check-mark.png" *ngIf="showiconPiece">
                        <img class="mx-1 img-icon" src="/assets/images/cross_icon.png" *ngIf="showiconPieceerror">

                      </div>
                    </div>
                    <div class="grid-item">
                      <div class="certif_gravbutton row offset-1 ">
                        <div class="d-flex justify-content-center align-items-center text-center">
                          <input type="file" id="idCerti" (change)="getTransmission($event ,2)" hidden />
                          <label for="idCerti">
                            <svg class="mx-3" xmlns="http://www.w3.org/2000/svg" width="30" height="29.996"
                              viewBox="0 0 30 29.996">
                              <path id="upload-solid"
                                d="M17.344,22.571H12.656a1.4,1.4,0,0,1-1.406-1.406V11.321H6.111a1.17,1.17,0,0,1-.826-2L14.2.4a1.135,1.135,0,0,1,1.6,0l8.918,8.918a1.17,1.17,0,0,1-.826,2H18.75v9.844A1.4,1.4,0,0,1,17.344,22.571ZM30,22.1v6.563a1.4,1.4,0,0,1-1.406,1.406H1.406A1.4,1.4,0,0,1,0,28.664V22.1A1.4,1.4,0,0,1,1.406,20.7H9.375v.469a3.283,3.283,0,0,0,3.281,3.281h4.688a3.283,3.283,0,0,0,3.281-3.281V20.7h7.969A1.4,1.4,0,0,1,30,22.1Zm-7.266,5.156a1.172,1.172,0,1,0-1.172,1.172A1.175,1.175,0,0,0,22.734,27.258Zm3.75,0a1.172,1.172,0,1,0-1.172,1.172A1.175,1.175,0,0,0,26.484,27.258Z"
                                transform="translate(0 -0.075)" fill="#2c4f6c" />
                            </svg>
                            Transmettre "Certificat de gravage"
                          </label>
                        </div>

                      </div>
                      <div class="col-2 img">
                        <img class="mx-1  img-icon" src="/assets/images/check-mark.png" *ngIf="showiconcertif">
                        <img class="mx-1 img-icon" src="/assets/images/cross_icon.png" *ngIf="showiconcertiferror">

                      </div>
                    </div>

                  </div>






                </div>

              </div>

            </div>
          </div>
        </div>
      </div>
    </div>

  </div>

</div> -->


