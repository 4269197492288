
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ApicreationService } from 'src/app/core/services/apicreationservice.service';
import { Apisuccessresponse } from 'src/app/shared/models/apisuccessresponse';
import { Bike } from 'src/app/shared/models/bike';
import { Insuranceinfo } from 'src/app/shared/models/insuranceinfo';
import { Epay_body } from 'src/app/shared/models/pay-body';
import { Subscribe_body } from 'src/app/shared/models/subscribe_body';
import { Formula, Warranty } from 'src/app/shared/models/warranty';



@Component({
  selector: 'app-optionvn',
  templateUrl: './optionvn.component.html',
  styleUrls: ['./optionvn.component.css']
})
export class OptionvnComponent implements OnInit {
  @Input()TotalAmount :number = 0 ;
  @Output() insuranceinfo : Insuranceinfo ;
  @Input() pagedata:number = 0;
  @Output() ondatachanged = new EventEmitter<any>();
  @Output()changepagenumber : EventEmitter<number> = new EventEmitter();
  Totalcouverture :number = 0 ;
  TotalAmountApresDon :number = 0 ;
  TotalMoisApresDon:number;
  TotalMois:number;
  subscribe_body:Subscribe_body;
  storeRef : string ;
  disabledNvOption :boolean = true ;
  bike :Bike = new Bike() ; 
  url_sign : string;
  disabledformula:boolean =true ; 
  Esign_body : Insuranceinfo = new Insuranceinfo();
  Epay_body :Epay_body= new Epay_body();
  Oui :string ="Oui";
  esign: string;
  checkdonat:boolean =false ; 
  Non :string ="Non";
  retour :string ="Retour";
  choixNeuf :string ; 
  choixvaleurneuf :number ; 
  public  payementref :string;
  @Input() loader_garantie:boolean; 
  PAOption:boolean=false;
  NVOption:boolean=false;
  formula= Formula ;
  warranty : Warranty = new Warranty();
  Option :any;
  donationprix :number ;
  data : Apisuccessresponse[];

  constructor( public sanitizer: DomSanitizer,private apicreationService : ApicreationService,) { 
     
    
     
    }

  ngOnInit(): void {
    this.apicreationService.ratefranchise=[]
    if( this.apicreationService.ratevalurneuf.length>0)
    {
    this.data = this.apicreationService.ratevalurneuf;
    this.apicreationService.ratedata =  this.data;
    }
    else{
      this.data = this.apicreationService.ratedata;
    }

    let valeursansvalueraneuf :number ;
     this.apicreationService.ratevalurneuf = this.data; 
     this.data.forEach((rate : Apisuccessresponse) => {
       if ( rate.warranty.NVOption === 'No' &&  rate.warranty.Excess === "10%" )
       {
         let rateSNeuf =rate.rate.TotalAmountAllTaxesIncluded ;
         let num = rateSNeuf/12; 
         valeursansvalueraneuf = num;
       }
     });
     this.data.forEach((rate : Apisuccessresponse) => {
       if (rate.warranty.NVOption === 'Yes' &&  rate.warranty.Excess === "10%" )
       {
         let rateNeuf =rate.rate.TotalAmountAllTaxesIncluded ;
         let num = rateNeuf/12; 
         let value = num;
         this.choixvaleurneuf= Number((value- valeursansvalueraneuf).toFixed(2)); 
       }
     });
  
  }

 

  Previouspage(valueemitted :any){
    localStorage.setItem('warranty', JSON.stringify(this.warranty));
    this.changepagenumber.emit(valueemitted)
  }
 
  Nextpage(event :string){
    this.choixNeuf = event ;
    this.data = this.apicreationService?.ratedata;
    this.apicreationService.ratedata = []; 
    this.data.forEach((rate : Apisuccessresponse) => {
      if (rate.warranty.NVOption === this.choixNeuf)
       this.apicreationService.ratedata.push(rate) ; 
    });
    this.changepagenumber.emit(1 )
  }













}
