import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ClickveloComponent } from './modules/pages/clickvelo/clickvelo.component';
import { GarantiesveloComponent } from './modules/pages/garantievelo/garantiesvelo.component';
import { OptionvnComponent } from './modules/pages/optionvn/optionvn.component';
import { SuccesspageComponent } from './modules/pages/successpage/successpage.component';
import { FormulaireComponent } from './modules/pages/formulaire/formulaire.component';
import { MadalRatingContent } from './modules/components/madal-rating/madal-rating.component';
import { ViePriveeComponent } from './modules/pages/vie-privee/vie-privee.component';
import { UploaddocpageComponent } from './modules/pages/uploaddocpage/uploaddocpage.component';
import { InsetionFlotteComponent } from './modules/pages/insetion-flotte/insetion-flotte.component';
const routes: Routes = [
  {path: '', component: ClickveloComponent},
  {
    path: ':storeRef',
    component: ClickveloComponent,
  },
  {
    path: ':storeRef/option',
    component: OptionvnComponent,
  },
  {
    path: ':storeRef/formulaire',
    component: FormulaireComponent,
  },

  {
    path: ':storeRef/vie-privee',
    component: ViePriveeComponent,
  },
  {
    path: 'affaires/:id_aff/:num_contrat?mail',
    component: UploaddocpageComponent,
  },
  {
    path: ':insertion/Flotte',
    component: InsetionFlotteComponent,
  }






];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
