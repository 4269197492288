<div class="container-fluid">
    <div class="card">
      <div class="card-header">
        <div class="container">
          <div class="row rowmobile">
            <div class="col-md-12 centertext">
              <h3 class="m-0">Conditions Générales d'Utilisation </h3>
            </div>
      
          </div>
        </div>
      </div>
      <div class="card-body">
        <div class="container">
          <label>Préambule
          </label><br /><br />
          <span>
            La présente politique de confidentialité définit et vous informe de la manière dont Berilish utilise et protège les informations 
            que vous transmettez, le cas échéant, lorsque l’utilisateur utilise le présent site accessible à partir de l’URL suivante :
             https://berilish.com<br /><br />
Ce site a pour objet de vous fournir des informations et des actualités au sujet de la couverture vélo obligatoire et 
complémentaire en FRANCE et sur les différentes offres et services proposés par les différentes entités de la société 
Berilish, filiales et succursales. berilish est principalement un site comparateur d’offres de complémentaires vélo 
et de mutuelles vélo de la société Berilish, offres que seuls les sites internet du groupe possèdent l’exclusivité,
 ou de ses partenaires assurances, réassurances, mutuelles et associations d’assurés, GIE (Groupement d’Intérêt Economique) et courtiers grossistes.
 <br /><br />
Les présentes conditions générales définissent les conditions d'utilisation par les Utilisateurs du site berilish et des différents Outils, services et Moteurs de Recherche proposés.
<br /><br />
L'utilisation ou l'accès au site berilish et aux Outils, services et Moteurs de Recherche proposés impliquent l'acceptation sans réserve ni restriction des présentes conditions générales d'utilisation.
<br /><br />
Directeur de la publication du site berilish : Monsieur Sedki CHAYATA
<br /><br />
L'Editeur de ce site  est la Société Berilish – Siège Social :  54 AV DE LA MOTTE PICQUET - 75015 PARIS
SARL au Capital de 1 000,00  Euro –	Paris B 844 505 495  Garantie Financière et Assurances Responsabilité Civile conformes au 
code des Assurances N° ORIAS 21003751 – www.orias.fr
<br /><br />
L'Hébergeur du site « Berilish » est assuré par 1&1 internet SARL – 7 place de la Gare – BP 701069 57201 Sarreguemines Cedex – France.
         </span><br /><br />
  
  
  
         <label> Définitions:
  
      </label><br /><br />
      <span>
        <br /><br /> CNIL<br />
        Désigne la « Commission Nationale de l'Informatique et des Libertés ». 3 Place de Fontenoy - TSA 80715 - 75334 PARIS CEDEX 07. Tél : 01 53 73 22 22.
        <br /><br />  RGPD<br />
        Désigne le règlement nᵒ 2016/679 de l'Union européenne, Règlement Général sur la Protection des Données, la règlementation de référence en matière de protection des données à caractère personnel.
        <br /><br /> ACPR<br />
        Désigne l' « Autorité de Contrôle Prudentiel et de Résolution », une institution intégrée à la Banque de France, chargée de la surveillance de plusieurs entités notamment les assurances et les courtiers. 4 place de Budapest 75436 Paris Cedex 09 . 
        <br /><br />  Utilisateur<br />
        Désigne toute personne utilisant l'un des Services, Outils et Moteurs de Recherche proposés par le Site.
        <br /><br />  DPO :<br />
        Délégué à la protection des données personnelles.
        <br /><br /> Services / Offres :<br />
        Désigne les différents services et les offres commerciales proposés par la société Berilish, filiales et succursales, ou ses partenaires assurances, réassurances, mutuelles et associations d’assurés, GIE* et courtiers grossistes. Par l'intermédiaire du site « Berilish ».
        <br /><br /> Outils et Moteurs de recherches :<br />
        Désigne les Outils développés et les Moteurs de Recherche internes du site internet « Berilish » à partir desquels l'Utilisateur accède aux résultats, offres et informations recherchés et disponibles aux Services/Offres.
        <br /><br /> Sites :<br />
        Désigne les sites internet à partir desquels l'Utilisateur accède aux informations, aux Services et Offres proposés par la société Berilish.
        <br /><br />Filiales et Succursales<br />
        Désigne les sociétés de services et sociétés commerciales de la société Berilish proposant leurs services et leurs offres par l'intermédiaire du site « Berilish »
     </span><br /><br />
  
  

       
     <label>     Protection des données à caractère personnel
  
    </label><br /><br />
    <span>
    
D’une manière générale, il vous est possible de visiter le site de Berilish sans communiquer aucune information personnelle vous concernant. En toute hypothèse, vous êtes en aucune manière obligé de transmettre ces informations à Berilish.

Néanmoins, en cas de refus, il se peut que vous ne puissiez pas bénéficier de certaines informations ou services que vous avez demandés. A ce titre, Berilish peut être amené dans certains cas à vous demander de renseigner votre nom, prénom, adresse mail, numéro de téléphone (ci-après « Les données traitées »).
En fournissant ces informations, vous acceptez expressément qu’elles soient traitées par la société Berilish et ces différentes entités filiales et succursales, aux fins indiquées « III-4 / Destinations des données » ci-dessous ainsi qu’aux fins rappelées à la fin de chaque formulaire.

Conformément au Règlement Général sur la Protection des Données du 25 mai 2018 RGPD adopté par le Parlement européen le 14 avril 2016, et à la Loi Informatique et Libertés du 6 janvier 1978 modifiée, Berilish vous informe des points suivants :
   </span><br /><br />
 


  
     <label> Identité et coordonnées du responsable du traitement :</label><br /><br />
  <span>
    Le responsable du traitement est la société Berilish, ayant son siège social au 54 AV DE LA MOTTE PICQUET 75015 PARIS. Et ces différentes entités filiales et succursales.
    <br/>Tél : + 33 (0)08 92 97 66 38  ; Fax : + 33 (0)08 92 97 66 38     .
     
  </span><br /><br />
  
  
  
  


  
  

  
  <label> Les données traitées :</label><br /><br />
  <span>
      Selon le cas, le site « Berilish » traite les données personnelles, directement collectées auprès du l’Utilisateur. La société Berilish ses différentes filiales et succursales sont également susceptible d’être destinataire de données qui ont été collectées par un tiers auprès de l’Utilisateur.
      Le site « Berilish » ne traite aucune donnée ou une catégorie de données que si elle est strictement nécessaire à la finalité poursuivie.
      Vous pouvez retrouver des informations sur ces finalités définies à l'article « A quoi servent les données ? ».
      La société Berilish traite les catégories de données suivantes :
  
      <ul>
          <li> Données d’identification : Nom, prénom, carte nationale d’identité, …</li>
          <li>Données de contact : Adresse postale, email, numéro de téléphone…</li>
          <li>Données familiales : Situation familiale, nombre d’enfants, …</li>
          <li>Données professionnelles</li>
          <li>Produits et services détenus ou utilisés</li>
      </ul>
     
  </span><br /><br />






<label> A quoi servent les données ?

</label><br /><br />
<span>
    Les données à caractère personnel de l'Utilisateur collectées sur le site « Berilish » font l'objet de traitements informatiques par la société Berilish ses filiales et ses succursales, pour la réalisation des Services, le fonctionnement des Outils et Moteurs de Recherche proposés par l'intermédiaire du Site, décrits à l’article «V- Informations sur les Services et les Outils proposés ».
    Dans l'intérêt de la société Berilish, les données à caractère personnel communiquées par l'Utilisateur peuvent faire l'objet de traitements pour l’informer des différentes offres d'assurances ou de services commercialisés par tout moyen de communication détenus par les différentes entités de la société Berilish, filiales et succursales en exclusivité.
    Si l'Utilisateur l'accepte, il peut également recevoir les offres des partenaires de la société Berilish.
    L'Utilisateur peut à tout moment faire valoir ses choix et, le cas échéant, son opposition à la prospection selon les modalités décrites ci-après dans la rubrique « Vos droits »).
</span><br /><br />






<label> Destinations des données :
</label><br /><br />
<span>
    En fournissant ces informations, l’Utilisateur accepte expressément qu’elles soient traitées par les entités de la société Berilish filiales et succursales en exclusivité, aux fins indiquées précédemment ainsi qu’aux fins rappelées à la fin de chaque formulaire.
    Lorsque l'Utilisateur demande à être mis en relation avec un conseiller commercial par le biais des formulaire « ÊTRE RAPPELÉ(e) », « RECEVOIR UN DEVIS », « NOUS VOUS RAPPELONS », «  RENCONTRER UN CONSEILLER », « SOUSCRIRE » ou sans le demander, il autorise à la société Berilish, ses différentes entités filiales et Succursales concernées, situées sur le territoire français ou en dehors de l'Union Européenne, à communiquer aux conseillers(ères), commerciaux(alles), salarié(e)s ou mandataires de la société Berilish les informations enregistrées afin que ce dernier puisse contacter l’Utilisateur en temps réel pour finaliser son dossier ou le conseiller.
    L’Utilisateur consent à être démarché ultérieurement dans les limites de la durée légale de conservation des données suivant la réglementation RGPD, ‘‘3 ans pour les données inactives et recueil du consentement pour les cookies au-delà de 13 mois’’.
    Les données collectées sont destinées aux services internes de la société Berilish et ses différentes entités, filiales et succursales.
    Les données peuvent également être traitées par des partenaires de Berilish.
    Il s’agit des situations où des partenaires interviennent pour la fourniture de prestations.
    Il peut également s’agir de traitements pour lesquels un consentement est sollicité.
</span><br /><br />

  
  
  


<label> Durée de conservation     :
</label><br /><br />
<span>
    Berilish ne conserve pas vos données au-delà de la durée nécessaire pour les opérations pour lesquelles elles ont été collectées 
    (assistance par exemple), ou de celles prévues par les normes et autorisations de la CNIL (pack de conformité pour le secteur de l’assurance)
     ou par la loi (telles que les prescriptions légales),
</span><br /><br />

<label> Vos droits :
</label><br /><br />
<span>
    Berilish ne conserve pas vos données au-delà de la durée nécessaire pour les opérations pour lesquelles elles ont été collectées (assistance par exemple), ou de celles prévues par les normes et autorisations de la CNIL (pack de conformité pour le secteur de l’assurance)
     ou par la loi (telles que les prescriptions légales),

    <ul>
        <li>Droit d’accès : l’utilisateur peut accéder à ses données pour les modifier, ou bien demander une copie de ses informations personnelles.
        </li>
        <li>Droit de rectification : l’utilisateur peut demander à Berilish la correction d’informations inexactes sur sa base de données.
        </li>
        <li>Droit à l’effacement : l’utilisateur peut demander la suppression de ses données personnelles en remplissant le formulaire de «  <u class="mention" (click)="goViePrive()" >droit à l’oubli</u> ».
        </li>
        <li>Droit d’opposition : l’utilisateur peut s’opposer à tout moment au traitement de ses données personnelles.
        </li>
        <li>Droit de limitation du traitement : l’utilisateur peut demander la suspension d’un traitement le concernant le temps d’une vérification.
        </li>
        <li>Droit à la portabilité : l’utilisateur peut obtenir les données personnelles qu’il a fourni.
        </li>
    </ul>
    Vous pouvez exercer vos droits en contactant notre DPO dpo@Berilish.fr ou bien en nous écrivant à l’adresse postale Berilish, 
     54 AV DE LA MOTTE PICQUET 75015 PARIS, en joignant une photocopie de sa pièce d'identité (recto-verso).
    
</span><br /><br />


<label> Délais de réponse :
</label><br /><br />
<span>
    Le site « Berilish » s’engage à répondre à votre demande d’accès, de rectification ou d’opposition ou toute autre demande complémentaire d’informations dans un délai raisonnable qui ne saurait dépasser un mois à compter de la réception de votre demande.
    Toutefois la société Berilish met en données vos contacts dans une liste d’opposition pour qu’aucune sollicitation ne soit effectuée dans le futur.
    
</span><br /><br />



<label> Transfert des données hors de l’Union Européenne :
</label><br /><br />
<span>
    La société Berilish, s’est doté d’une politique et d’une gouvernance dédiées à la protection des données à caractère personnel à l’international vu sa présence dans plusieurs pays.
    Elles comprennent un contrôle strict des transferts des données de l’Utilisateur recueillies par le biais des divers formulaires présents sur le site « Berilish » et autres sites de la Société Berilish, en particulier lorsqu’ils se font hors de l’Union européenne et sa législation protectrice, dans des conditions conformes à la réglementation.
    Les données de l’Utilisateur sont susceptibles d’être transférées hors Union Européenne, dans la mesure où c’est le cas, elles sont traitées en exclusivité par les entités du Groupe, filiales et succursales suivantes en-dehors de l’Union européenne : Une succursale de la Société Berilish située en Tunisie à des fins de gestion des contrats d’assurance et de télémarketing.
    Une société de la Société Berilish située en Tunisie pour certaines opérations de maintenance informatique, certains traitements comptables, Ces transferts sont encadrés par des règles d'entreprise contraignantes et des clauses contractuelles types, au sujet desquelles vous pouvez obtenir un complément d'information auprès de dpo@Berilish.fr.
</span><br /><br />



<label>Politique d’utilisation des cookies
</label><br /><br />

<ul>
    <li>Durée de conservation des cookies :</li>
</ul>
<span>

La société Berilish, s’est doté d’une politique et d’une gouvernance dédiées à la protection des données à caractère personnel à l’international vu sa présence dans plusieurs pays.
Elles comprennent un contrôle strict des transferts des données de l’Utilisateur recueillies par le biais des divers formulaires présents sur le site « Berilish » et autres sites de la Société Berilish, en particulier lorsqu’ils se font hors de l’Union européenne et sa législation protectrice, dans des conditions conformes à la réglementation.
Les données de l’Utilisateur sont susceptibles d’être transférées hors Union Européenne, dans la mesure où c’est le cas, elles sont traitées en exclusivité par les entités du Groupe, filiales et succursales suivantes en-dehors de l’Union européenne : Une succursale de la Société Berilish située en Tunisie à des fins de gestion des contrats d’assurance et de télémarketing.
Une société de la Société Berilish située en Tunisie pour certaines opérations de maintenance informatique, certains traitements comptables, Ces transferts sont encadrés par des règles d'entreprise contraignantes et des clauses contractuelles types, au sujet desquelles vous pouvez obtenir un complément d'information auprès de dpo@Berilish.fr.
</span>

<br /><br />
<ul>
    <li>Droit d’opposition :    </li>
</ul>
<span>

    Les cookies peuvent être utilisés pour des fins statistiques notamment pour optimiser les services rendus à l'utilisateur, à partir du traitement des informations concernant la fréquence d'accès, la personnalisation des pages ainsi que les opérations réalisées et les informations consultées.
    Vous êtes informé que l'éditeur est susceptible de déposer des cookies sur votre terminal.
    Le cookie enregistre des informations relatives à la navigation sur le service (les pages que vous avez consultées, la date et l'heure de la consultation...) que nous pourrons lire lors de vos visites ultérieures.</span>


<br /><br />





<label> Accès aux Services, Outils et Moteurs de recherche proposés sur le site « Berilish »
</label><br /><br />
<span>
Les Outils, les Moteurs de recherche et les différents services proposés par la société Berilish sont accessibles gratuitement (à l'exception du coût de connexion) à tout Utilisateur disposant d'un accès à internet.
Tous les coûts afférents à l'accès aux Services, aux Outils et aux Moteurs de recherche, que ce soient les frais matériels, logiciels ou d'accès à internet, sont exclusivement à la charge de l'Utilisateur qui est seul responsable du bon fonctionnement de son équipement informatique ainsi que de son accès à internet.
Certains Services et Outils sur le site « Berilish » peuvent être réservés aux Utilisateurs titulaires d'un contrat assurance par l’intermédiaire de la société Berilish après identification à l'aide de leur Identifiant et de leur Code confidentiel.
La Société Berilish se réserve le droit de refuser l'accès à tout ou partie des Services et des Outils, unilatéralement et sans notification préalable, à tout Utilisateur ne respectant pas les présentes conditions générales d'utilisation.
Lorsqu'ils sont invités à fournir des informations, les Utilisateurs s'engagent à fournir des informations exactes et à jour.
Les Utilisateurs s'engagent à ne pas copier, modifier, altérer, ou tenter de copier, modifier ou altérer de quelque manière que ce soit le contenu ou la programmation informatique des pages des Sites permettant l'accès aux Services, aux Moteurs de recherche et aux Outils proposés ainsi que leur utilisation.
Les Utilisateurs s'engagent à respecter toutes les réglementations applicables du pays dans lequel ils utilisent les Services et, en particulier, s'interdisent de déposer tout document, donnée ou information de nature diffamatoire, injurieuse, contrefaisante, illicite, erronée, falsifiée ou portant atteinte à l'ordre public, aux bonnes mœurs, ou au fonctionnement informatique des Sites ou des Services.
La Société Berilish se réserve le droit de supprimer une demande d'accès aux Services et/ou aux Outils proposés sur le Site « Berilish » qui s'avérerait manifestement contraire à l'ordre public ou aux bonnes mœurs ou de poursuivre en justice tout Utilisateur qui aurait commis un acte de nature à enfreindre les présentes dispositions ou les lois et règlements en vigueur.
</span><br /><br />




<label> Outil Comparateur
</label><br /><br />
<span>

    Le directeur de la publication pour la rubrique « comparateur » est Sedki CHAYATA, Directeur Général, Berilish.
    Toutes questions relatives à l’Outil Comparateur devront être adressées à l'adresse suivante : Société Berilish,  54 AV DE LA MOTTE PICQUET 75015 PARIS – France, ou par e-mail à  comparateur@Berilish.fr.
    Cet e-mail est protégé contre les robots collecteurs de mails, votre navigateur doit accepter le Java script pour accéder à l’Outil Comparateur.
    La partie automatisée concernant les offres est gérée directement par la société Berilish ou/et ses filiales, sous le contrôle de la CNIL et respectant les directives du RGPD.

</span><br /><br />

<label>  Description de l’Outils Comparateur :

</label><br /><br />
<span>
    Le site « Berilish » propose, gratuitement et sans engagement, d'établir plusieurs devis pour votre recherche de contrats d'assurance, en présentant les devis qui correspondent aux besoins de l’Utilisateur, pour une comparaison facile, rapide et impartiale.
    Cet Outils permet aux Utilisateurs de consulter les offres proposées par les partenaires de la Société Berilish, assurances, réassurances, mutuelles, associations d’assurés, GIE (Groupement d’Intérêt Economique) et courtiers grossistes, « Berilish » présente les offres de ses assureurs partenaires en toute exhaustivité et sans aucune présélection, l’Outils se base principalement sur le choix de couverture que les Utilisateurs expriment sur les Outils de collecte d’informations.
    Une confirmation de la réception des informations et leurs traitements est envoyée instantanément à l'adresse e-mail indiquée par l'Utilisateur, en particulier, lorsqu’il a répondu à tous les champs demandés.
    La Société Berilish se réserve la possibilité de prendre ultérieurement contact avec tout Utilisateur via mail ou téléphone par le billet de son service commercial, les services centres d’appels filiales , succursale ou sous-traitants, dans le respect de la durée de conservation des données et sous réserve que l'Utilisateur concerné n'ait pas demandé à la société Berilish la suppression des données le concernant.
    Le site « Berilish » permet également aux Utilisateurs qui utilisent l’Outil comparateur et qui le souhaitent de recevoir, par e-mail, les nouvelles offres, informations et actualités de la société Berilish, en fonction des critères prédéterminés par l'Utilisateur.
</span><br /><br />



<label>  Champ d'application :

</label><br /><br />
<span>
    L'objet de l’Outil Comparateur est principalement d’afficher les offres contrats complémentaire vélo et contrats mutuelle vélo aux utilisateurs sur lequel déboucherait éventuellement une action de souscription en ligne, le cas échéant un espace de mise en relation entre les Utilisateurs et les conseillers commerciaux de la société Berilish, filiales et/ou succursale situées sur le territoire français ou en dehors de l'Union Européenne.
    Dans ce cas, le processus sera régi par des conditions distinctes des présentes et sera conduit sous le contrôle de la CNIL et de la réglementation 
    de l’ACPR.</span><br /><br />



    <label>  Données relatives aux Utilisateurs dans le cadre de l'utilisation de l’Outil Comparateur :

    </label><br /><br />
    <span>
        La collecte de données personnelles auprès des Utilisateurs est réalisée exclusivement afin de permettre le champ d’application définie « V-1-b/ Champ d'application ».
Les données indispensables aux comparatifs et affichages des résultats sont affichées avec un astérisque.
A défaut de réponse à ces champs obligatoires, la demande de comparatif ne pourra être faite et aucune action ne sera appliquée.
Les autres données personnelles demandées sont facultatives.
En aucun cas, les réponses aux champs obligatoires dans le cadre de l’Outil Comparateur ou l'absence de réponse à des champs facultatifs ne font pas l'objet d'un traitement discriminatoire de la part de la société Berilish.
De la même manière, les champs auxquels il est imposé aux Utilisateurs de répondre dans le cadre de l’Outil Comparateur ne feront, en aucun cas, l'objet d'un traitement discriminatoire de la part de la société Berilish.
Le caractère obligatoire ou facultatif des données est indiqué sur les formulaires de collecte.
Les champs facultatifs ont pour objectif de mieux connaître l’Utilisateur et ainsi mieux répondre à sa demande.
Le défaut de réponse dans l'un des champs obligatoires entraîne l'impossibilité pour nos services de traiter votre demande.
A ce titre la collecte des données de l’Utilisateur permet uniquement au bon fonctionnement de l’Outil Comparateur pour l’affichage des résultats, de s'adresser ultérieurement aux Utilisateurs de manière pertinente et personnalisée.</span><br /><br />



<label>  Destination des données collectées :

</label><br /><br />
<span>
    Les informations de l'Utilisateur seront accessibles à l'ensemble des entités de la société Berilish intéressées par son profil, situées sur le territoire français ou en dehors de l'Union Européenne.
    Le cas échéant, les informations collectées pourront être également transmises à des entités externes à la Société Berilish pour les besoins de la procédure de traitements et archivages.
    En répondant aux champs, l'Utilisateur consent à une telle transmission.
    En tout état de cause, les données fournies par l'Utilisateur ne seront utilisées que pour étudier ses besoins en termes de couverture d’assurances, mutuelle ou toutes autres solutions proposées par la Société Berilish et ne seront jamais cédées ou divulguées à d'autres fins.
    L'Utilisateur peut demander à accéder, faire modifier, faire rectifier ou faire supprimer les données le concernant dans les conditions définies à l'article « III - Protection des données à caractère personnel ».
    Les données personnelles collectées via l’Outil Comparateur ne seront conservées par l'Editeur que pendant une durée de trois ans à compter de la dernière consultation de l'Utilisateur du site « Berilish » ou à sa connexion à son compte suivant les recommandations de la règlementation RGPD.
    Il est toutefois rappelé que l'Utilisateur peut, à tout moment, demander la suppression des données le concernant, dans les conditions visées à l'article « III - Protection des données à caractère personnel ».
    L'Editeur de l’Outils Comparateur s'engage à mettre en œuvre des moyens conformes à l'état de l'art destinés à éviter toute atteinte à l'intégrité et à la confidentialité des données par des tiers non autorisés.
    Toutefois, la sécurité, la confidentialité ou l'intégrité des données transmises par le biais d'Internet, en raison des caractéristiques techniques du réseau, ne peut être garanties par l'Editeur de l’Outils Comparateur.</span><br /><br />


    <label>  Responsabilité

    </label><br /><br />
    <span>

        L'Editeur de l’Outil Comparateur ne peut être tenu responsable des changements des tarifs et des garanties, l’acceptation et le refus des dossiers par les partenaires, assurances, réassurances, mutuelles, associations d’assurés, GIE (Groupement d’Intérêt Economique) et courtiers grossistes, ceci étant sous la responsabilité exclusive de ses derniers.
Les offres mises à disposition sur l’Outil Comparateur sont mises à jour par l’éditeur de l’Outil Comparateur après validation de ses partenaires, assurances, réassurances, mutuelles, associations d’assurés, GIE (Groupement d’Intérêt Economique) et courtiers grossistes.
Le traitement des données transmises par l’Utilisateur via l’Outil Comparateur pourra être utilisé en exclusivité et partout moyen de communication détenus par les différentes entités de la société Berilish, filiales et succursales, situées sur le territoire français ou en dehors de l'Union Européenne, l’Utilisateur consent d’être rappelé en exclusivité par ses dernières, ceci ne pourra faire objet en l’état de réclamation.
A ce titre l’éditeur de l’Outils Comparateur décline toute responsabilité par les différentes entités de la société Berilish, filiales et succursales, et l’Utilisateur consent à renoncer à son droit de s’y opposer.
</span><br /><br />
    
    
<label>  Moteurs de recherche Résiliation et Outil Résiliation :

</label><br /><br />
<span>
    Le directeur de la publication pour la rubrique « Résiliation » est : Sedki CHAYATA, Directeur Général, Berilish. Toutes questions relatives à l’Outil Résiliation devront être adressées à l'adresse suivante : Société Berilish,  54 AV DE LA MOTTE PICQUET 75015 PARIS – France, ou par e-mail à  e-resiliation@Berilish.fr .
    Cet e-mail est protégé contre les robots collecteurs de mails, votre navigateur doit accepter le Java script pour accéder à l’Outil Résiliation. La partie automatisée concernant les offres est gérée directement par la société Berilish ou/et ses filiales, sous le contrôle de la CNIL et respectant les directives du RGPD.

</span><br /><br />



<label>  Description du Moteur de Recherche de l’Outils Résiliation :

</label><br /><br />
<span>
    « Berilish » propose, gratuitement et sans engagement, de retrouver de façon rapide et facile l’adresse de votre assurance, mutuelle, association d’assurés, GIE (Groupement d’Intérêt Economique) ou courtier grossiste qui gère votre couverture vélo. <
        Ce Moteur de Recherche permet à l’utilisateur de passer à un Outil proposé par le site « Berilish » qui permet d’éditer une lettre de résiliation personnalisée, « Berilish », l’Outil collecte les informations obligatoires à mettre sur une lettre de résiliation, les transcrit sur un document qui sera généré en format .pdf.
        Une confirmation de du traitement et de la réalisation de la lettre est envoyée à l'adresse e-mail indiquée par l'Utilisateur.
        En particulier, lorsque l'Utilisateur a répondu à tous les champs demandés.
        L’Utilisateur peut dans ce cas télécharger le fichier et en faire usage.
        La société Berilish se réserve la possibilité de prendre ultérieurement contact avec tout Utilisateur via mail ou téléphone par le billet de son service commercial, les services centres d’appels filiales , succursales, dans le respect de la durée de conservation des données et sous réserve que l'Utilisateur concerné n'ait pas demandé à la société Berilish la suppression des données le concernant. Le site « Berilish » permet également aux Utilisateurs qui utilisent l’Outil Résiliation et qui le souhaitent, de recevoir, par e-mail, les nouvelles offres, informations et actualités de la société Berilish, 
        en fonction des critères prédéterminés par l'Utilisateur.
</span><br /><br />


<label>  Champ d'application:

</label><br /><br />
<span>
    L'objet de l’Outil Résiliation est principalement d’éditer une lettre de résiliation à l’Utilisateur dont il fera bon usage, en particulier l’envoi à son assurance, mutuelle, association d’assuré, GIE (Groupement d’Intérêt Economique) ou courtier grossiste, qui gère sa couverture vélo, par e-mail ou bien par lettre recommandée avec accusé de réception, au même temps un espace de mise en relation entre les Utilisateurs et les conseillers commerciaux de la société Berilish, filiales et/ou succursale situées sur le territoire français ou en dehors de l'Union Européenne.
    Dans ce cas le processus sera régi par des conditions distinctes des présentes et sera conduit sous le contrôle de la CNIL et la réglementation de l’ACPR    
</span><br /><br />


<label>  Données relatives aux Utilisateurs dans le cadre de l'utilisation de l’Outil Résiliation :

</label><br /><br />
<span>
    La collecte des données personnelles auprès des Utilisateurs est réalisée exclusivement afin de permettre le champ d’application définie « V-2-b/ Champ d'application ».
    Les données indispensables à l’édition de la lettre de résiliation et son envoi sont affichés avec un astérisque.
    A défaut de réponse à ces champs obligatoires, la demande ne pourra être faite et aucune action ne sera appliquée.
    Les autres données personnelles demandées sont facultatives.
    En aucun cas, les réponses aux champs obligatoires dans le cadre de l’Outil Résiliation ou l'absence de réponse à des champs facultatifs ne font l'objet d'un traitement discriminatoire de la part de la société Berilish.
    De la même manière, les champs auxquels il est imposé aux Utilisateurs de répondre dans le cadre de l’Outil Résiliation ne feront, en aucun cas, l'objet d'un traitement discriminatoire de la part de la société Berilish.
    Le caractère obligatoire ou facultatif des données est indiqué sur les formulaires de collecte.
    Les champs facultatifs ont pour objectif de mieux connaître l’Utilisateur, mieux répondre à sa demande et ainsi éditer sa lettre de résiliation.
    Le défaut de réponse dans l'un des champs obligatoires entraîne l'impossibilité pour nos services de traiter la demande de ce dernier.
    A ce titre la collecte des données de l’Utilisateur permet uniquement au bon fonctionnement de l’Outil Résiliation pour l’édition de la lettre de résiliation, de s'adresser ultérieurement aux Utilisateurs de manière pertinente et personnalisée.  
</span><br /><br />


<label>  Destination des données collectées :

</label><br /><br />
<span>

    Les informations de l'Utilisateur seront accessibles à l'ensemble des entités de la société Berilish intéressées par son profil, situées sur le territoire français ou en dehors de l'Union Européenne.
    Le cas échéant, les informations collectées pourront être également transmises à des entités externes à la société Berilish pour les besoins de la procédure de traitements et archivages.
    En répondant aux champs, l'Utilisateur consent à une telle transmission.
    En tout état de cause, les données fournies par l'Utilisateur ne seront utilisées que pour étudier ses besoins en termes de couverture d’assurances, mutuelle ou toutes autres solutions proposées par la société Berilish et ne seront jamais cédées ou divulguées à d'autres fins.
    L'Utilisateur peut demander à accéder, faire modifier, faire rectifier ou faire supprimer les données le concernant dans les conditions définies à l'article « III - Protection des données à caractère personnel ».
    Les données personnelles collectées via l’Outil Résiliation ne seront conservées par l'Editeur que pendant une durée de trois ans à compter de la dernière consultation de l'Utilisateur du site « Berilish » ou à sa connexion à son compte suivant les recommandations de la règlementation RGPD.
    Il est toutefois rappelé que l'Utilisateur peut, à tout moment, demander la suppression des données le concernant, dans les conditions visées à l'article « III - Protection des données à caractère personnel ».
    L'Editeur de l’Outils Résiliation s'engage à mettre en œuvre des moyens conformes à l'état de l'art destinés à éviter toute atteinte à l'intégrité et à la confidentialité des données par des tiers non autorisés.
    Toutefois, la sécurité, la confidentialité ou l'intégrité des données transmises par le biais d'Internet, en raison des caractéristiques techniques du réseau, ne peut être garanties par l'Editeur de l’Outil Résiliation.
</span><br /><br />



<label>Responsabilité :

</label><br /><br />
<span>

    L'Editeur de l’Outil Résiliation ne peut être tenu responsable des changements d’adresses, de l’acceptation et le refus de la lettre par les assurances, réassurances, mutuelles, associations d’assurés, GIE (Groupement d’Intérêt Economique) et courtiers grossistes, ceci étant sous la responsabilité exclusive de ses derniers, ou l’erreur d’envoi postal, ceci étant sous la responsabilité exclusive de l’utilisateur.
    Les adresses des compagnies mises à disposition sur le Moteur de Recherche Adresses de Résiliation sont mises à jour par l’éditeur de ce dernier après vérification des données et informations sur les sites et les pages internet des, assurances, réassurances, mutuelles, associations d’assurés, GIE (Groupement d’Intérêt Economique) et courtiers grossistes.
    Le traitement des données transmises par l’Utilisateur via l’Outil Résiliation pourra être utilisé en exclusivité et par tout moyen de communication détenus par les différentes entités de la société Berilish, filiales et succursales, situées sur le territoire français ou en dehors de l'Union Européenne, l’Utilisateur consent d’être rappelé en exclusivité par ses dernières, ceci ne pourra faire objet en l’état de réclamation.
    A ce titre l’éditeur de l’Outil Résiliation décline toute responsabilité par les différentes entités de la société Berilish, 
    filiales et succursales, et l’Utilisateur consent à renoncer à son droit de s’y opposer.  </span><br /><br />





<label>Accessibilité :

</label><br /><br />
<span>
    Le site « Berilish » est accessible 24 heures/24 et 7 jours/7.
    La société Berilish peut fermer ou rendre inaccessible, temporairement, tout ou une partie du Site afin de réaliser des opérations de maintenance ou suite à la demande de toute autorité judiciaire et en cas de force majeure.
    La société Berilish n’est pas responsable de la non accessibilité du Site et des Services et de toute interruption totale ou partielle de la consultation de sa page.
    L'Utilisateur doit bien vérifier qu'il possède les outils et les moyens de communication électroniques,
     des droits d'accès donnés par la société Berilish et des compétences permettant l'accès au Site et aux Services.  </span><br /><br />




<label>Propriété :

</label><br /><br />
<span>
    Le site « Berilish », ainsi que ses différentes pages permettent l'accès aux différents services, Outils et Moteurs de Recherche proposés, mais aussi tous les éléments composant et/ou effectués pour la fonctionnalité de ces derniers , et y compris les photographies, images, animations, logiciels, interfaces, marques, logos, textes, dessins, icônes et illustrations sont sous la protection du droit de la propriété industrielle et intellectuelle à l’échelle mondiale.
    En général, l'accès au site, aux services, Outils et Moteurs de Recherche proposés ne donne pas à l'utilisateur un privilège sur les droits de propriété intellectuelle liés aux données, qui sont propre exclusivement à la société Berilish ou, le cas échéant, aux titulaires tiers.
    L'utilisateur a la possibilité de copier les pages du site qu’à titre d'information personnelle et pour un usage privé uniquement.
    En outre, l'Utilisateur est interdit de copier, rééditer, représenter, modifier, traduire, créer des produits dérivés, et de diffuser tout ou partie des Données ou les utiliser de toute autre manière en dehors du service employé.
    Toute utilisation non agréée ou interdite des données peut engendrer la responsabilité civile et pénale de l'utilisateur
     en fonction des lois appliqués.


    </span><br /><br />

    <label>Base de données:

    </label><br /><br />
    <span>
        Les Services proposés peuvent mettre en œuvre des bases de données regroupant les informations communiquées par les Utilisateurs.
        Ces Bases de Données sont la propriété exclusive de la société Berilish.
        Conformément à la loi française, les Utilisateurs des Services s'interdisent de porter atteinte aux droits de propriété intellectuelle de la société Berilish sur la Base de Données et s'interdisent notamment toute extraction substantielle ou systématique des données ainsi que toute utilisation des données à des fins autres que strictement personnelles et non commerciales.
        Les Utilisateurs s'interdisent tout accès au contenu de la Base de Données autrement que via et dans 
        les conditions prévues par le Service concerné. </span><br /><br />



        <label>Limitation de responsabilité

        </label><br /><br />
        <span>
            Les Services proposés peuvent mettre en œuvre des bases de données regroupant les informations communiquées par les Utilisateurs.
            Ces Bases de Données sont la propriété exclusive de la société Berilish.
            Conformément à la loi française, les Utilisateurs des Services s'interdisent de porter atteinte aux droits de propriété intellectuelle de la société Berilish sur la Base de Données et s'interdisent notamment toute extraction substantielle ou systématique des données ainsi que toute utilisation des données à des fins autres que strictement personnelles et non commerciales.
            Les Utilisateurs s'interdisent tout accès au contenu de la Base de Données autrement que via et dans les 
            conditions prévues par le Service concerné. </span><br /><br />
    

            <label>Force majeure

            </label><br /><br />
            <span>
                La société Berilish ne sera responsable d'aucun préjudice, dommage ou perte résultant d'un cas de force majeure tel que reconnu par la jurisprudence française. </span><br /><br />
    
    

                <label>Modification des conditions d'utilisation du Site et des Services, Outils et Moteurs de Recherche

                </label><br /><br />
                <span>
                    La société Berilish se réserve le droit, à tout moment, de modifier tout ou partie des conditions d'utilisation du Site ou des Services, Outils et Moteurs de Recherche proposés.
Les modifications entreront en vigueur dès leur mise en ligne.
Il appartient donc à l'Utilisateur de revoir de manière régulière les termes des présentes.
Le simple fait de continuer à utiliser les Services proposés implique l'acceptation par l'Utilisateur de toutes modifications apportées aux présentes.

 </span><br /><br />
            


 <label>Convention sur la preuve

</label><br /><br />
<span>
    Il est expressément convenu que toutes les données, informations, fichiers et tout autre élément numérique échangés entre l'Utilisateur et la société Berilish ou conservés par elles, notamment dans ses bases de données ou sur ses serveurs, constitueront des preuves recevables, valides, opposables et ayant la force probante d'un acte sous seing privé.
    L'Utilisateur s'engage à ne pas contester la recevabilité, la validité, l'opposabilité ou la force probante des éléments de nature ou sous format électronique précités, sur le fondement de leur nature électronique.
    Sauf preuve contraire, ces éléments seront valables et opposables de la même manière, dans les mêmes conditions et avec la même force probante que tout document qui serait établi, reçu ou conservé par écrit.
</span><br /><br />




<label>Langue

</label><br /><br />
<span>
    Ces termes sont rédigés en français en cas de traduction par des outils traducteurs externes au site et en cas de contradiction entre la version française originale et la version anglaise à laquelle sera traduite par un outil externe au site des présentes, seule la rédaction française originale doit être prise en compte pour interpréter les termes des présentes.
</span><br /><br />



<label> Invalidité

</label><br /><br />
<span>
    Si une ou plusieurs stipulations des présentes conditions d'utilisation sont tenues pour non valides ou déclarées telles en application d'une loi, d'une règlementation ou à la suite d'une décision devenue définitive d'une juridiction compétente, les autres stipulations garderont toute leur force et leur portée.
</span><br /><br />



<label> Loi applicable

</label><br /><br />
<span>

    Les présentes conditions d'utilisation du site et les Services proposés sont soumis au droit français.
    Tout litige relatif à l'interprétation ou l'exécution des présentes conditions d'utilisation ou à l'exécution des Services, Outils et Moteurs de Recherche proposés sera soumis au Tribunal compétent de TOULON, France.
    
    </span><br /><br />



        </div>
      </div>
    </div>
  </div>
