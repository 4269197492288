import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
  Inject,
} from '@angular/core';
import { QuotationService } from 'src/app/core/services/quotation/quotationservice.service';
import {
  Client,
  Address,
  Occupation,
  ClienttypeEnum,
  Company,
} from 'src/app/shared/models/client';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  FormGroupDirective,
  NgForm,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { CorrectCityZipCodeResponseSchema } from 'src/app/shared/models/correctCityZipCodeResponseSchema';
import { Insuranceinfo } from 'src/app/shared/models/insuranceinfo';
import { Router } from '@angular/router';
import { DatePipe, DOCUMENT } from '@angular/common';

import { GeneriqueService } from 'src/app/core/services/Generiqservice/generiqueservice.service';
import { ErrorStateMatcher } from '@angular/material/core';
import { ApicreationService } from 'src/app/core/services/apicreationservice.service';

import swal from 'sweetalert2';
import { AdressEmitter } from '../../components/auto-complete-adresses/auto-complete-adresses.component';
import { PhoneNumberUtil } from "google-libphonenumber";



@Component({
  selector: 'app-fiche-client',
  templateUrl: './fiche-client.component.html',
  styleUrls: ['./fiche-client.component.css'],
})
export class FicheClientComponent implements OnInit {
  state: boolean = true;
  formClient: FormGroup;
  listcodepostals: number[] = [];
  selectedVille: string;
  occupation = Occupation;
  selectedCodepostal: number;
  listvilles: string[] = [];
  donationprix: number = 5;
  suivant: string;
  retour: string = 'Retour';
  loader_codepostals: boolean = false;
  key_press_mobile: boolean = false;
  notValidPhone:boolean=false; 
  key_press_phone: boolean = false;
  signatureFormGroup: FormGroup;
  @Input() pagedata: number = 0;
  insuranceinfo: Insuranceinfo = new Insuranceinfo();
  client: Client = new Client();
  address: Address = new Address();
  company: Company = new Company();
  loader_villes: boolean = false;
  clienttypeEnum = ClienttypeEnum;
  pro: boolean = false;
  form: FormControl;
  currentDate : Date =new Date();
  matcher = new MyErrorStateMatcher();
  internationalNumber = "";
  dialCountry:any;
  @Output() changepagenumber: EventEmitter<number> = new EventEmitter();
  @Output() ondatachanged: EventEmitter<any> = new EventEmitter<any>();
  constructor(
    private apicreationService: ApicreationService,
    private datePipe: DatePipe,
    private quotationService: QuotationService,
    @Inject(DOCUMENT) private document: Document
  ) {



  }
  ngOnInit(): void {
        const youngerThanValidator = (maxAge: number): ValidatorFn => control =>
    (new Date()).getFullYear() - (new Date(control.value)).getFullYear() < maxAge 
      ? { younger: { maxAge } } 
      : null;
    //localStorage.clear();
    this.client = new Client();
    this.address = new Address();
    this.company = new Company();
    this.suivant = 'suivant';
    this.formClient = new FormGroup({
      Prénom: new FormControl(),
      Nom: new FormControl(),
      Email: new FormControl(),
      'Email de confirmation': new FormControl(),
      Civilité: new FormControl(),
      Téléphone: new FormControl(),
      Mobile: new FormControl( "",[Validators.minLength(12), 
      Validators.maxLength(13)]),
      Type: new FormControl(),
      'Date de naissance': new FormControl(null, youngerThanValidator(18)),
      Occupation:new FormControl(),
      'Numéro de rue': new FormControl(),
      'Nom de rue': new FormControl(),
      Ville: new FormControl(),
      Pays: new FormControl(),
      'Code postal': new FormControl('', Validators.max(99999)),
      "Complément d 'Adresse": new FormControl(),
      donnation: new FormControl('', Validators.min(2)),
      pro: new FormControl(),
      siren: new FormControl(),
      RS: new FormControl(),
      // iban: new FormControl('', Validators.max(999999999)),
    }
    );
    this.formClient.controls['Pays'].setValue(
     "France"
    );
    this.client.type = 'Private';
    if (localStorage.getItem('Client') !== null) {
      this.client = JSON.parse(localStorage.getItem('Client')!);
      this.formClient.controls['Email de confirmation'].setValue(
        this.client.email
      );
      this.pro = this.client.type == 'Private' ? false : true;
      this.address = this.client.address;
      if (this.client.type == 'Private') {
        this.company = new Company();
      } else {
        this.company = this.client.company;
      }

      this.getVilleList();
    }
  }
  Previouspage(valueemitted: any) {
    this.changepagenumber.emit(valueemitted);
  }

  onKeyMobile(event: any) {
    this.key_press_mobile = true;
    this.handleNumberChange( event)
  }

  onBlurMobile(event: any) {
    this.key_press_mobile = false;
    this.handleNumberChange( event)
  }

  onKeyPhone(event: any) {
    this.key_press_phone = true;
  }

  onBlurPhone(event: any) {
    this.key_press_phone = false;
  }
  checkSame() {
 
    if (
      this.formClient.controls['Email de confirmation'].value !==
      this.formClient.controls['Email'].value
    ){
      this.formClient.controls['Email'].setErrors({
        notconfirmid: true,
      });
      this.formClient.controls['Email de confirmation'].setErrors({
        notconfirmid: true,
      });

  }else{
   
    if (this.formClient.controls['Email'].value!=="" &&       this.formClient.controls['Email de confirmation'].value !== "") {
    this.formClient.controls['Email'].setErrors(
     null
    );

    this.formClient.controls['Email de confirmation'].setErrors(
   null
    );

  }
  else{

    this.formClient.controls['Email'].setErrors({
      notconfirmid: true,
    });
    this.formClient.controls['Email de confirmation'].setErrors({
      notconfirmid: true,
    });

  }
  }
    }



    checkSameEmail() {
     if (this.formClient.controls['Email de confirmation'].value   !== null && this.formClient.controls['Email de confirmation'].value   !== "" &&  this.formClient.controls['Email de confirmation'].value  !== undefined   ){
     this.checkSame()
  
      }
    }

  onChangeAdresseSaisieAutoDest(adress: AdressEmitter) {
    this.company.address = new Address();
    this.company.address.country = adress.pays;
    this.company.address.streetName = adress.route;
    this.company.address.streetNumber = adress.street_number;
    this.company.address.postalCode = adress.postal_code;
    this.company.address.city = adress.locality;
    this.client.company.address = this.company.address;
  }
  numberOnly(event: any): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }


  getNumber($event: any, type: string) {
    this.handleNumberChange( $event); 
    switch (type) {
      case 'telephone':
        this.client.phone = $event;
        break;
      case 'mobile':
        this.client.mobile = $event;
      
        break;

      default:
        break;
    }
  }

  Nextpage(valueemitted: any) {
    this.state = true;

    const mapped = Object.keys(this.formClient.controls).map((key) => ({
      type: key,
      value: this.formClient.controls[key],
    }));
    mapped.forEach((value: { type: string; value: any }) => {
      if (
        value.value.status === 'INVALID' &&
        (value.value.value === null ||
          value.value.value === '' ||
          value.value.value === undefined)
      ) {
        if (this.pro) {
          if (
            (value.type == 'siren' && value.value.status == 'INVALID') ||
            (value.type == 'RS' && value.value.status == 'INVALID')
          ) {
            this.alertWarning('Pour un contrat pro, vous devez remplir vos coordonnées professionnelles');
            this.state = false;
          }
        }  else {
          if (value.type == 'siren' || value.type == 'RS') {
            value.value.status = 'VALID';
          } else {
            this.alertWarning('Vous devez remplir les champs obligatoires');
            this.state = false;
          }
        }
      }
      else if ((value.type == 'siren' && value.value.status == 'INVALID' ||  value.type == 'RS' && value.value.status == 'INVALID')){
        if (this.pro) {
          if (
            (value.type == 'siren' && value.value.status == 'INVALID') ||
            (value.type == 'RS' && value.value.status == 'INVALID')
          ) {
            this.alertWarning('Pour un contrat pro, vous devez vérifier vos coordonnées professionnelles');
            this.state = false;
          }
        }  
      }
      
      else if ((value.type=="Email" && value.value.status == 'INVALID' ) || (value.type=="Email de confirmation" && value.value.status == 'INVALID' )  ){
        this.alertWarning('Vous devez verifier les champs obligatoires');
        this.state = false;
      }

     else if (value.type=="Date de naissance" && value.value.status == 'INVALID' ) {
       if(value.value.errors["younger"]){
        this.state = false;
        this.alertWarning("Désolé, la souscription est réservée au plus de 18 ans");
       }else{
        this.state = false;
        this.alertWarning("Merci de vérifier votre date de naissance ");
       }
       
      } 
      else if (value.type=="Mobile" && value.value.status == 'INVALID' ) {
        this.state = false;
        this.alertWarning("Merci de vérifier le numéro mobile");
      } 
      
    });
    if (this.state) {
      if (this.pro) {
        this.client.company = this.company;
      }
      this.handleNumberChange(this.client.mobile)
      if(this.notValidPhone==false){
        this.client.birthDate =
        this.datePipe
          .transform(this.client.birthDate, 'yyyy-MM-dd')
          ?.toString() || '';
        this.client.address = this.address;
        localStorage.setItem('Client', JSON.stringify(this.client));
        this.insuranceinfo.client = this.client;
        this.apicreationService.pushInfoClient(this.client);
        this.changepagenumber.emit(valueemitted);

      }else{
        this.state = false;
        this.alertWarning("Merci de vérifier le numéro mobile");
      }
  
      
    }
  }


  EmptyPhone(event:any){
    this.client.mobile=""; 
    this.formClient.controls['Mobile'].setValue("");
  }

  handleNumberChange(event:any) {

    const phoneUtil = PhoneNumberUtil.getInstance();
  
    this.internationalNumber=this.client.mobile
    const parsedInput = phoneUtil.parseAndKeepRawInput(
      this.internationalNumber, 'FR'
    );
     if (parsedInput.getNationalNumber() && parsedInput.getCountryCode()) {
      this.dialCountry = parsedInput;
    } else {
      this.dialCountry = null;
    } 
    if(this.dialCountry?.getCountryCode()==33 && phoneUtil?.isValidNumber(parsedInput) && phoneUtil?.getNumberType(parsedInput)==1  ) {
      this.formClient.controls['Mobile'].setErrors(
        null
         );
         this.notValidPhone=false; 
    }else{
      if(this.dialCountry?.getCountryCode()==33 && phoneUtil?.isValidNumber(parsedInput) ){
        this.formClient.controls['Mobile'].setErrors({
          notconfirmid: true,
        });
        this.notValidPhone=true
      }else
      {
        if(phoneUtil?.isValidNumber(parsedInput)){
          this.formClient.controls['Mobile'].setErrors(
            null
             );
             this.notValidPhone=false
        }else{
          this.formClient.controls['Mobile'].setErrors(
           { notvalid:true,}
             );
            this.notValidPhone=true
        }
       
      }
      
    }

  }

ageRangeValidator (control: AbstractControl):  boolean  | null   {
  var ageDifMs = Date.now() - control.value.getTime();
  var ageDate = new Date(ageDifMs);
  var age = Math.abs(ageDate.getUTCFullYear() - 1970);
  if (age > 17) {
    return true;
  } else {
    return null;
  }
}



  onKeyUp(e: any) {
    if (e.target.value.length === 5) this.getVilleList();
  }

  alertWarning(error: any) {
    swal.fire({
      title: '',
      icon: 'warning',
      html: error,
      showConfirmButton: true,
      showCloseButton: true,
      confirmButtonText: 'Fermer',
      confirmButtonColor: '#e75e5e',
    });
  }

  getVilleList() {
    this.loader_villes = true;
    this.quotationService
      .citieszipcode('z', this.address.postalCode)
      .subscribe((data: CorrectCityZipCodeResponseSchema) => {
        this.loader_villes = false;
        this.formClient.controls['Ville'].setValue(data.cities[0]);
        this.listvilles = data.cities;
      });
  }

  contratPro(event: any) {
    if (event.target.checked) {

      this.pro = true;
      this.client.type = 'Professionnal';
      this.formClient.controls['Type'].setValue('Professionnal');
    } else {
      this.pro = false;
      this.client.type = 'Private';
      this.formClient.controls['Type'].setValue('Private');
    }
  }



  
  telHasError(status: any) {
 
    if(status !== null) {
      this.handleNumberChange(this.client.mobile);  
    }      
  };
  getCodepostalList() {
    this.loader_codepostals = true;
    this.quotationService
      .citieszipcode('c', this.address.city)
      .subscribe((data: CorrectCityZipCodeResponseSchema) => {
        this.listcodepostals = data.zipcodes;
        this.loader_codepostals = false;
      });
  }

  isValid(field: string) {
    return this.formClient?.controls[field].invalid;
  }
}
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(
    control: FormControl | null,
    form: FormGroupDirective | NgForm | null
  ): boolean {
    const isSubmitted = form && form.submitted;
    return (control && control.invalid)!;
  }
}
