import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { GooglePlaceDirective } from "ngx-google-places-autocomplete";
import { Address } from "ngx-google-places-autocomplete/objects/address";

export interface State {
  flag: string;
  name: string;
  population: string;
}

export class AdressEmitter {
  street_number: string = '';
  route: string = '';
  name: string = '';
  locality: string = '';
  postal_code: string = '';
  pays: string = '';
  longitude: number = 0;
  latitude: number = 0;
  geometry: any = null;
}

@Component({
  selector: 'app-auto-complete-adresses',
  templateUrl: './auto-complete-adresses.component.html',
  styleUrls: ['./auto-complete-adresses.component.css']
})
export class AutoCompleteAdressesComponent implements OnInit ,AfterViewInit {
  @Output() onChangeAdresse: EventEmitter<AdressEmitter> = new EventEmitter();
  @ViewChild("placesRef") placesRef: GooglePlaceDirective;
  @ViewChild("filterName") input: ElementRef
  @Input() iconColor = "black";
  @Input() isReadOnlyAdresse: boolean=false;
  adress: AdressEmitter = new AdressEmitter()
 
 

  constructor() {

  }
  ngAfterViewInit(): void {
    this.placesRef.options.componentRestrictions={ country: 'FR' }
    this.placesRef.options.fields=["address_components", "geometry", "icon", "name"]; 
  }

  ngOnInit(): void {
    
  }

  public handleAddressChange(address: Address) {
    this.adress= new AdressEmitter() ; 
    this.adress.name = address.name
    address.address_components.forEach(elem => {
      if (elem.types.includes("street_number")) {
        this.adress.street_number = elem.long_name
      }
      if (elem.types.includes("route")) {
        this.adress.route = elem.long_name
      }
      if (elem.types.includes("postal_code")) {
        this.adress.postal_code = elem.long_name
      }
      if (elem.types.includes("locality") && elem.types.includes("political")) {
        this.adress.locality = elem.long_name
      }
      if (elem.types.includes("country")) {
        this.adress.pays = elem.long_name
      }
    })
    this.adress.latitude = address.geometry.location.lat()
    this.adress.longitude = address.geometry.location.lng()
    this.adress.geometry = address.geometry
    this.onChangeAdresse.emit(this.adress);
  

  }

 
}
