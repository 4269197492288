import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { SharedModule } from "./shared/shared.module";
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PipePipe } from './shared/pipes/pipe.pipe';
import { FicheClientComponent } from './modules/pages/fiche-client/fiche-client.component';
import { FicheVeloComponent } from './modules/pages/fiche-velo/fiche-velo.component';
import { ClickveloComponent } from './modules/pages/clickvelo/clickvelo.component';
import { FooterComponent } from './modules/components/footer/footer.component';
import { ButtonComponent } from './shared/components/button/buttonsuivant/button.component';
import { ButtonretourComponent } from './shared/components/button/buttonretour/buttonretour.component';
import { DatePipe } from '@angular/common';
import { GeneriqueService } from './core/services/Generiqservice/generiqueservice.service';
import { QuotationService } from './core/services/quotation/quotationservice.service';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { ApicreationService } from './core/services/apicreationservice.service';
import { FormulaireComponent } from './modules/pages/formulaire/formulaire.component';
import { SuccesspageComponent } from './modules/pages/successpage/successpage.component';
import { ScanpageComponent } from './modules/pages/scanpage/scanpage.component';
import { HeaderComponent } from './modules/components/header/header.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { GarantiesveloComponent } from './modules/pages/garantievelo/garantiesvelo.component';
import { OptionvnComponent } from './modules/pages/optionvn/optionvn.component';
import { OptionaccidentComponent } from './modules/pages/optionaccident/optionaccident.component';

import { AutoCompleteAdressesComponent } from './modules/components/auto-complete-adresses/auto-complete-adresses.component';
import { ViePriveeComponent } from './modules/pages/vie-privee/vie-privee.component';
import { IbanpageComponent } from './modules/pages/ibanpage/ibanpage.component';
import { FranchiseComponent } from './modules/pages/franchise/franchise.component';
import { AngularIbanModule } from 'angular-iban';
import { AssistanceComponent } from './modules/pages/assistance/assistance.component';
import { UploaddocpageComponent } from './modules/pages/uploaddocpage/uploaddocpage.component';
import { ValidationUploadComponent } from './modules/pages/uploaddocpage/popupValidation/validation-upload/validation-upload.component';
import { InsetionFlotteComponent } from './modules/pages/insetion-flotte/insetion-flotte.component';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
@NgModule({
  declarations: [
    AppComponent,
    PipePipe,
    ScanpageComponent,
    FicheClientComponent,
    FicheVeloComponent,
    ClickveloComponent,
    FooterComponent,
    HeaderComponent,
    ButtonComponent,
    ButtonretourComponent,
    FormulaireComponent,
    SuccesspageComponent,
    GarantiesveloComponent,
    OptionvnComponent,
    OptionaccidentComponent,
    AutoCompleteAdressesComponent,
    ViePriveeComponent,
    IbanpageComponent,
    FranchiseComponent,
    AssistanceComponent, 
    UploaddocpageComponent, ValidationUploadComponent, InsetionFlotteComponent
  ],
  imports: [
    BrowserAnimationsModule,
    AppRoutingModule,
    SharedModule,
    BrowserModule,
    HttpClientModule,
    RouterModule,
    AngularIbanModule,
    MatSlideToggleModule,
    MatProgressSpinnerModule,
    
  ],
  providers: [  DatePipe,GeneriqueService,ApicreationService,QuotationService],
  exports: [SharedModule],
  bootstrap: [AppComponent]
})
export class AppModule { }
