import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { DomSanitizer } from '@angular/platform-browser';

import { ApicreationService } from 'src/app/core/services/apicreationservice.service';
import { Apisuccessresponse } from 'src/app/shared/models/apisuccessresponse';
import { Bike } from 'src/app/shared/models/bike';
import { Insuranceinfo } from 'src/app/shared/models/insuranceinfo';
import { Epay_body } from 'src/app/shared/models/pay-body';
import { Subscribe_body } from 'src/app/shared/models/subscribe_body';
import { Formula, Warranty } from 'src/app/shared/models/warranty';

@Component({
  selector: 'app-franchise',
  templateUrl: './franchise.component.html',
  styleUrls: ['./franchise.component.css']
})
export class FranchiseComponent implements OnInit {
  @Input()TotalAmount :number = 0 ;
  @Output() insuranceinfo : Insuranceinfo ;
  @Input() pagedata:number = 0;
  @Output() ondatachanged = new EventEmitter<any>();
  @Output()changepagenumber : EventEmitter<number> = new EventEmitter();
  Totalcouverture :number = 0 ;
  TotalAmountApresDon :number = 0 ;
  TotalMoisApresDon:number;
  TotalMois:number;
  subscribe_body:Subscribe_body;
  storeRef : string ;
  disabledNvOption :boolean = true ;
  bike :Bike = new Bike() ; 
  url_sign : string;
  disabledformula:boolean =true ; 
  Esign_body : Insuranceinfo = new Insuranceinfo();
  Epay_body :Epay_body= new Epay_body();
  Oui :string ="Oui";
  esign: string;
  checkdonat:boolean =false ; 
  Non :string ="Non";
  retour :string ="Retour";
  franchise :string ; 
  choixvaleurneuf :number ; 
  public  payementref :string;
  @Input() loader_garantie:boolean; 
  PAOption:boolean=false;
  NVOption:boolean=false;
  formula= Formula ;
  warranty : Warranty = new Warranty();
  Option :any;
  donationprix :number ;
  data : Apisuccessresponse[];
   valeursansoption :number=0 ;
   valeuravecoption :number=0 ;
   franchiseOptionValue:number=0; 
  datarateFranchise: Apisuccessresponse[];
  constructor(public sanitizer: DomSanitizer,private apicreationService : ApicreationService,) { 
  
    }

  ngOnInit(): void {

    if( this.apicreationService.ratefranchise.length>0)
    {
    this.data = this.apicreationService.ratefranchise;
    this.apicreationService.ratedata =  this.data;
    }
    else 
    this.data = this.apicreationService.ratedata;
    this.apicreationService.ratefranchise = this.data;
    

   this.data.forEach((rate : Apisuccessresponse) => {
  
      if (rate.warranty.Excess === '10%' )
      {
        let ratefranchise =rate.rate.TotalAmountAllTaxesIncluded ;
        let num = ratefranchise/12; 
       this.valeursansoption = num;
      }
    });

    this.data.forEach((rate : Apisuccessresponse) => {
  
      if (rate.warranty.Excess === '0%' )
      {
        let ratefranchiseZero =rate.rate.TotalAmountAllTaxesIncluded ;
        let num = ratefranchiseZero/12; 
        this.valeuravecoption = num;
      }
    });
    
    this.franchiseOptionValue= Number((this.valeuravecoption- this.valeursansoption).toFixed(2)); 

  }

 

  Previouspage(valueemitted :any){

    if ( this.apicreationService?.rateaccident?.length === 8){
      this.changepagenumber.emit(valueemitted);
    }else 
       {
        this.changepagenumber.emit(valueemitted-1);
       }
  }
 
  Nextpage(event :string){
    this.franchise = event ;
    this.data = this.apicreationService?.ratedata;
    this.datarateFranchise = this.apicreationService?.ratedata;
    this.apicreationService.ratedata = []; 
    this.data.forEach((rate : Apisuccessresponse) => {
      if (rate.warranty.Excess === this.franchise)
       this.apicreationService.ratedata.push(rate) ; 
    });
    this.changepagenumber.emit(1 )
  }






}
