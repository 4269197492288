import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-scanpage',
  templateUrl: './scanpage.component.html',
  styleUrls: ['./scanpage.component.css']
})
export class ScanpageComponent implements OnInit {
  Ignorer :string = "Ignorer";
  Scaner:string = "Scanner votre facture";
  @Output()changepagenumber : EventEmitter<number> = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }
  Nextpage(){
   
    this.changepagenumber.emit(1)
  }
}
