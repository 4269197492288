<div class="col-md-12 pt-md-2 h-100 card h-100 align-items-center" style="background-color: transparent;" id="infoperso">


    <div class="row ofsset-md-2 col-md-8 d-flex flex-wrap mt-5 justify-content-around" id="mobileversion">

     <!--    <button type="button" class="btn btn-white mb-4 m-1">
                            <label class="text-retour ">Scanner ma Facture</label>   
            </button> -->


        <button type="button" (click)="Nextpage()" class="btn btn-blue mb-4 m-1">
                            <label class="text-aller">Souscrire</label>   
            </button>



    </div>

    <div class="card-footer">
        <div class="row ">
            <div class="form-group taille">
            </div>

        </div>
    </div>


</div>