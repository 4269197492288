export class References {
    storeRef:	string
    quoteRef:	string
    eSignRef:	string
    paymentRef:	string
    opportunityRef:	string
    policyRef:	string;
    affaireRef:string ;
    sepa_rum:string ;
}
