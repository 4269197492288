<div class="col-md-10 offset-md-1 card h-100" id="infoperso">
    <div class="card-body">
        <div class="mb-md-5 mt-md-1 titreRecap text-center">
            <h3>Informations personnelles</h3>
        </div>
        <div [formGroup]="formClient" class="example-form">



            <div class="pro mt-2 mb-3">
                <div class="row  mb-md-3 mt-md-3 ">
                    <div class="col-md-12 col-sm-12   ">
                        <div class="row px-3">
                            <div class="col-md-1 col-xs-1 text-right">
                                <label class="switch-m space-switch">
                                    <input type="checkbox" [(ngModel)]="pro" formControlName="pro"
                                        (change)='contratPro($event)' name='pro'>
                                    <span class="slider-m round"></span>
                                </label>
                            </div>
                            <div class="col-md-5 col-xs-5 p-0 pt-1">
                                <span class="libelleCheckBox">Contrat PRO</span>
                            </div>
                        </div>

                    </div>

                </div>
                <div class="row mt-md-2  " *ngIf="pro">
                    <div class="col-md-6 col-sm-12  ">
                        <mat-form-field class="w-100 ">
                            <mat-label>Raison Social RS</mat-label>
                            <input matInput required [(ngModel)]="company.RS" formControlName="RS" type="text"
                                name="RS">
                            <mat-error *ngIf="formClient.controls['RS'].hasError('required') ">Raison Social obligatoire
                            </mat-error>
                        </mat-form-field>


                    </div>

                    <div class="col-md-6 col-sm-12 ">

                        <mat-form-field class="w-100 ">
                            <mat-label>SIREN</mat-label>
                            <input (keypress)="numberOnly($event)" required [(ngModel)]="company.siren" type="text"
                                formControlName="siren" matInput placeholder="123 456 789" min="0" pattern="([0-9]{9})">
                            <mat-error *ngIf="!formClient.controls['siren'].hasError('required')  && formClient.controls['siren'].dirty  &&
                            formClient.controls['siren'].invalid "><span> SIREN invalid doit contenir 9 chiffres
                                </span></mat-error>
                            <mat-error *ngIf="formClient.controls['siren'].hasError('required') ">Raison Social
                                obligatoire</mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row mt-md-2" *ngIf="pro">
                    <div class="col-md-12 col-sm-12  col-12">
                        <app-auto-complete-adresses iconColor="#9b9b9b"
                            (onChangeAdresse)="onChangeAdresseSaisieAutoDest($event)">
                        </app-auto-complete-adresses>
                    </div>

                </div>

            </div>
            <div class="row mt-md-3">
                <div class="col-md-6 col-sm-12">
                    <mat-form-field class="w-100">
                        <mat-label>Civilité</mat-label>
                        <mat-select [(ngModel)]="client.title" formControlName="Civilité">
                            <mat-option value="Mr">Mr</mat-option>
                            <mat-option value="Mrs">Mme</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-md-6 col-sm-12">

                    <mat-form-field class="w-100">
                        <mat-label>Nom</mat-label>
                        <input type="text" formControlName="Nom" [(ngModel)]="client.firstName" required matInput
                            pattern="[a-z A-Z]*" name="nom">
                        <svg matSuffix xmlns="http://www.w3.org/2000/svg " width="24 " height="24 "
                            viewBox="0 0 24 24 ">
                            <g id="Groupe_29 " data-name="Groupe 29 " transform="translate(-1498 -454) ">
                                <rect id="Rectangle_4756 " data-name="Rectangle 4756 " width="24 " height="24 "
                                    transform="translate(1498 454) " fill="none " />
                                <g id="fi-rr-user " transform="translate(1504 458) ">
                                    <path id="Tracé_1629 " data-name="Tracé 1629 "
                                        d="M9.833,7.665A3.833,3.833,0,1,0,6,3.833,3.833,3.833,0,0,0,9.833,7.665Zm0-6.388A2.555,2.555,0,1,1,7.278,3.833,2.555,2.555,0,0,1,9.833,1.278Z "
                                        transform="translate(-4.084)
                                             " fill="#aaa" />
                                    <path id="Tracé_1630 " data-name="Tracé 1630 "
                                        d="M8.749,14A5.755,5.755,0,0,0,3,19.749a.639.639,0,1,0,1.278,0,4.471,4.471,0,0,1,8.943,0,.639.639,0,1,0,1.278,0A5.755,5.755,0,0,0,8.749,14Z "
                                        transform="translate(-3-5.057)
                                             " fill="#aaa" />
                                </g>
                            </g>
                        </svg>
                        <mat-error *ngIf="formClient.controls['Nom'].hasError('required') ">Nom obligatoire</mat-error>

                    </mat-form-field>

                </div>


            </div>
            <div class="row mt-md-3">
                <div class="col-md-6 col-sm-12 ">
                    <mat-form-field class="w-100">
                        <mat-label>Prénom</mat-label>
                        <input type="text" formControlName="Prénom" [(ngModel)]="client.lastName" required matInput
                            pattern="[a-z A-Z]*" name="Prenom">
                        <svg matSuffix xmlns="http://www.w3.org/2000/svg " width="24 " height="24 "
                            viewBox="0 0 24 24 ">
                            <g id="Groupe_29 " data-name="Groupe 29 " transform="translate(-1498 -454) ">
                                <rect id="Rectangle_4756 " data-name="Rectangle 4756 " width="24 " height="24 "
                                    transform="translate(1498 454) " fill="none " />
                                <g id="fi-rr-user " transform="translate(1504 458) ">
                                    <path id="Tracé_1629 " data-name="Tracé 1629 "
                                        d="M9.833,7.665A3.833,3.833,0,1,0,6,3.833,3.833,3.833,0,0,0,9.833,7.665Zm0-6.388A2.555,2.555,0,1,1,7.278,3.833,2.555,2.555,0,0,1,9.833,1.278Z "
                                        transform="translate(-4.084)
                                         " fill="#aaa " />
                                    <path id="Tracé_1630 " data-name="Tracé 1630 "
                                        d="M8.749,14A5.755,5.755,0,0,0,3,19.749a.639.639,0,1,0,1.278,0,4.471,4.471,0,0,1,8.943,0,.639.639,0,1,0,1.278,0A5.755,5.755,0,0,0,8.749,14Z "
                                        transform="translate(-3-5.057)
                                          " fill="#aaa " />
                                </g>
                            </g>
                        </svg>
                        <mat-error *ngIf="formClient.controls['Prénom'].hasError('required') ">Prenom obligatoire
                        </mat-error>

                    </mat-form-field>
                </div>
                <div class="col-md-6 col-sm-12 ">
                    <mat-form-field class="w-100">
                        <mat-label>Date de naissance </mat-label>
                        <input type="date" [(ngModel)]="client.birthDate" id="bday" formControlName="Date de naissance"
                            max="{{currentDate|date:'yyyy-MM-dd'}}" pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}" required matInput name="date_naiss_user">
                        <mat-error *ngIf="formClient.controls['Date de naissance'].hasError('required') ">Date de
                            naissance obligatoire</mat-error>
                        <mat-error style='color:red'
                            *ngIf=" formClient.get('Date de naissance')?.touched   &&  formClient.get('Date de naissance')?.getError('younger') as error">
                            La souscription est réservée au plus de {{ error.maxAge }} ans
                        </mat-error>
                        <mat-error style='color:red'
                        *ngIf=" !formClient.controls['Date de naissance'].hasError('required') && formClient.controls['Date de naissance'].invalid &&  !formClient.get('Date de naissance')?.getError('younger') as error ">
                        Mettez une date de naissance valide
                    </mat-error>
                    </mat-form-field>



                </div>
            </div>

            <div class="row mt-md-3 ">

                <div class="col-md-6 col-sm-12 ">

                    <mat-form-field class="w-100 ">
                        <mat-label>Email</mat-label>
                        <input pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$" matInput type="email"
                            (change)="checkSameEmail()" formControlName="Email" required [(ngModel)]="client.email"
                            oncopy="return false" oncut="return false"
                            placeholder="mon.email@email.com ">

                        <mat-icon matSuffix style="color:gray">alternate_email</mat-icon>

                        <mat-error
                            *ngIf="  !formClient.controls['Email'].hasError('required')  && formClient.controls['Email'].dirty  && formClient.controls['Email'].invalid ">
                            Mettez une adresse email valide
                        </mat-error>
                        <mat-error *ngIf="formClient.controls['Email'].hasError('required')">
                            L'email est obligatoire
                        </mat-error>


                    </mat-form-field>

                </div>
                <div class="col-md-6 col-sm-12 ">

                    <mat-form-field class="w-100 ">
                        <mat-label>Confirmation de l'email</mat-label>
                        <input pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$" (change)="checkSame()" matInput
                            type="email" value="email2" formControlName="Email de confirmation" required
                            onpaste="return false" placeholder="mon.email@email.com ">

                        <mat-icon matSuffix style="color:gray ">alternate_email</mat-icon>
                        <mat-error
                            *ngIf="  !formClient.controls['Email de confirmation'].hasError('required') && !formClient.controls['Email de confirmation'].hasError('notconfirmid')   && formClient.controls['Email de confirmation'].dirty  && formClient.controls['Email de confirmation'].invalid ">
                            Mettez une adresse email valide
                        </mat-error>

                        <mat-error
                            *ngIf="  formClient.controls['Email de confirmation'].hasError('notconfirmid') && formClient.controls['Email de confirmation'].invalid  ">
                            Merci de verfier votre email
                        </mat-error>
                        <mat-error *ngIf="formClient.controls['Email de confirmation'].hasError('required')">
                            La validation de votre email est obligatoire
                        </mat-error>
                    </mat-form-field>

                </div>
            </div>
            <div class="row mt-md-3 ">
                <div class="col-md-6 col-sm-12 ">

                    <mat-form-field class="w-100 ">
                        <mat-label
                            [ngClass]="{'ng2tel-field-label': (client.mobile === '' || client.mobile === undefined ) && key_press_mobile === false}">
                            Mobile </mat-label>
                        <input (keypress)="numberOnly($event) " (click)="onKeyMobile($event)"
                            (hasError)="telHasError($event)" (blur)="onBlurMobile($event)"
                            (countryChange)="EmptyPhone($event)" ng2TelInput matInput [(ngModel)]="client.mobile"
                            (ng2TelOutput)="getNumber($event, 'mobile')" formControlName="Mobile" required
                            name="telephone"
                            [ng2TelInputOptions]="{initialCountry: 'fr',autoHideDialCode: false,'preferredCountries': ['fr']} "
                            (ngModelChange)="handleNumberChange($event)">
                        <svg matSuffix xmlns="http://www.w3.org/2000/svg " width="24" height="24" viewBox="0 0 24 24 ">
                            <g id="Groupe_26 " data-name="Groupe 26 " transform="translate(-1499 -555) ">
                                <rect id="Rectangle_4758 " data-name="Rectangle 4758 " width="24 " height="24 "
                                    transform="translate(1499 555) " fill="none " />
                                <g id="fi-rr-smartphone " transform="translate(1506 559) ">
                                    <path id="Tracé_1577 " data-name="Tracé 1577 " d="M11.026,0H7.194A3.2,3.2,0,0,0,4,3.194v8.943a3.2,3.2,0,0,0,3.194,3.194h3.833a3.2,3.2,0,0,0,3.194-3.194V3.194A3.2,3.2,0,0,0,11.026,0ZM7.194,1.278h3.833a1.916,1.916,0,0,1,1.916,1.916V10.22H5.278V3.194A1.916,1.916,0,0,1,7.194,1.278Zm3.833,12.775H7.194a1.916,1.916,0,0,1-1.916-1.916V11.5h7.665v.639A1.916,1.916,0,0,1,11.026,14.053Z
                            " transform="translate(-4) " fill="#aaa " />
                                    <ellipse id="Ellipse_587 " data-name="Ellipse 587 " cx="0.639 " cy="0.639 "
                                        rx="0.639 " ry="0.639 " transform="translate(4.471 12.137) " fill="#aaa " />
                                </g>
                            </g>
                        </svg>
                        <mat-error style="color: red;" *ngIf="  formClient.controls['Mobile'].hasError('required') ">
                            Numéro mobile est obligatoire
                        </mat-error>

                        <mat-error
                            *ngIf="  formClient.controls['Mobile'].hasError('notconfirmid') && !formClient.controls['Mobile'].hasError('required') &&  !formClient.controls['Mobile'].hasError('notvalid')   ">
                            Un mobile est obligatoire pour le signature électronique
                        </mat-error>

                        <mat-error
                            *ngIf="!formClient.controls['Mobile'].invalid && formClient.controls['Mobile'].hasError('notvalid') && !formClient.controls['Mobile'].hasError('notconfirmid') && !formClient.controls['Mobile'].hasError('required') ">
                            Mettez un muméro mobile valide
                        </mat-error>

                        <mat-error
                            *ngIf=" formClient.controls['Mobile'].invalid  && !formClient.controls['Mobile'].hasError('required') && !formClient.controls['Mobile'].hasError('notconfirmid')  ">
                            Mettez un muméro mobile valide
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-md-6 col-sm-12 ">

                    <mat-form-field class="w-100 ">
                        <mat-label
                            [ngClass]="{'ng2tel-field-label': (client.phone === '' || client.phone === undefined ) && key_press_phone === false}">
                            Télèphone</mat-label>
                        <input (keypress)="numberOnly($event)" (click)="onKeyPhone($event)" (blur)="onBlurPhone($event)"
                            ng2TelInput matInput numbersonly [(ngModel)]="client.phone"
                            (ng2TelOutput)="getNumber($event, 'telephone') " formControlName="Téléphone" name="num_tel"
                            [ng2TelInputOptions]="{initialCountry:'fr','preferredCountries': ['fr']}">
                        <svg matSuffix xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                            <g id="Groupe_25 " data-name="Groupe 25 " transform="translate(-1449 -598) ">
                                <rect id="Rectangle_4761 " data-name="Rectangle 4761 " width="24 " height="24 "
                                    transform="translate(1449 598) " fill="none " />
                                <g id="fi-rr-call " transform="translate(1453 602) ">
                                    <path id="Tracé_7145 " data-name="Tracé 7145 " d="M12.187,9.787a1.569,1.569,0,0,0-2.427,0l-.958.894H8.737l-.064-.064c-.128-.064-.192-.128-.319-.192A11.264,11.264,0,0,1,5.735,8.063a5.358,5.358,0,0,1-.767-1.214l.575-.575.064-.064.128-.128.128-.128a1.637,1.637,0,0,0,0-2.491l-.894-.894a.77.77,0,0,0-.319-.319L4.01,1.611A1.6,1.6,0,0,0,2.86,1.1a1.745,1.745,0,0,0-1.214.511L.5,2.761a2.177,2.177,0,0,0-.7,1.6A5.8,5.8,0,0,0,.242,6.849,13.545,13.545,0,0,0,2.733,11a14.9,14.9,0,0,0,5.11,4.024,7.593,7.593,0,0,0,2.938.83h.192a2.693,2.693,0,0,0,1.98-.83h0a3.855,3.855,0,0,1,.511-.511l.064-.064L13.975,14a1.6,1.6,0,0,0,.511-1.214,1.573,1.573,0,0,0-.575-1.214Zm-.128,4.408a1.4,1.4,0,0,1-1.086.447h-.128a6.391,6.391,0,0,1-2.427-.767,14.66,14.66,0,0,1-4.727-3.7,14.51,14.51,0,0,1-2.3-3.833,4.82,4.82,0,0,1-.383-1.916,1.03,1.03,0,0,1,.383-.83L2.477,2.505a.552.552,0,0,1,.383-.192.543.543,0,0,1,.319.192l.575.575.128.128.128.128L4.9,4.23a.552.552,0,0,1,.192.383.608.608,0,0,1-.128.447L4.9,5.124l-.192.192a4.538,4.538,0,0,1-.83.767h0a.752.752,0,0,0-.192.894h0A9.552,9.552,0,0,0,4.777,8.765a11.563,11.563,0,0,0,2.938,2.683A1.336,1.336,0,0,1,8.1,11.7c.128.064.192.128.319.192h.064a.908.908,0,0,0,.383.128.82.82,0,0,0,.639-.256l1.086-1.086a.546.546,0,0,1,.383-.192.543.543,0,0,1,.319.192l1.789,1.789a.456.456,0,0,1,.064.7h-.128V13.3l-.256.256H12.7A6.022,6.022,0,0,0,12.059,14.195Z
                            " transform="translate(0.216 -0.206) " fill="#aaa " />
                                    <path id="Tracé_7146 " data-name="Tracé 7146 " d="M14.783,6.363a3.953,3.953,0,0,1,1.086,2.108.622.622,0,0,0,.639.511h.064a.647.647,0,0,0,.447-.256,1.184,1.184,0,0,0,.128-.447A5.3,5.3,0,0,0,12.867,4h-.128a.622.622,0,0,0-.639.511.539.539,0,0,0,.128.447c.064.128.256.192.383.256A4.779,4.779,0,0,1,14.783,6.363Z
                            " transform="translate(-4.233 -1.253) " fill="#aaa " />
                                    <path id="Tracé_7147 " data-name="Tracé 7147 " d="M17.632,2.063A8.451,8.451,0,0,0,13.1-.3h-.128a.522.522,0,0,0-.575.511.633.633,0,0,0,.511.7,7.591,7.591,0,0,1,3.9,1.98,6.74,6.74,0,0,1,1.98,3.9.622.622,0,0,0,.639.511h.064a.431.431,0,0,0,.383-.256A.608.608,0,0,0,20,6.6,8.908,8.908,0,0,0,17.632,2.063Z
                            " transform="translate(-4.336 0.3) " fill="#aaa " />
                                </g>
                            </g>
                        </svg>

                    </mat-form-field>

                </div>

            </div>
            <div class="row mt-md-3 ">
                <div class="col-md-12 col-sm-12 ">

                    <mat-form-field class="w-100">
                        <mat-label>Occupation</mat-label>
                        <mat-select [(ngModel)]="client.occupation" required formControlName="Occupation">
                            <mat-option *ngFor="let occup of occupation |keyvalue" [value]="occup.key">
                                {{occup.value}}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="formClient.controls['Occupation'].hasError('required') ">Occupation
                            obligatoire</mat-error>
                    </mat-form-field>

                </div>

            </div>
            <div class="row mt-md-3 ">
                <div class="col-md-6 col-sm-12" id="codepostal">
                    <mat-form-field class="w-100">
                        <mat-label>Code postal</mat-label>
                        <input matInput (keypress)="numberOnly($event)" min="0" type="text" numbersonly
                            (keyup)="onKeyUp($event)" maxlength="5" formControlName="Code postal" required
                            [(ngModel)]="address.postalCode" name="code">
                        <mat-error *ngIf="formClient.controls['Code postal'].hasError('required') ">Code postal
                            obligatoire</mat-error>
                        <mat-error *ngIf="!formClient.controls['Code postal'].hasError('required')  && formClient.controls['Code postal'].dirty  &&
                         formClient.controls['Code postal'].invalid ">Code postal invalide </mat-error>
                    </mat-form-field>


                </div>

                <div class="col-md-6 col-sm-12" id="ville ">
                    <ng-container *ngIf="loader_villes ">
                        <mat-spinner [diameter]="20"></mat-spinner>
                    </ng-container>
                    <mat-form-field class="w-100">
                        <mat-label>Ville</mat-label>
                        <mat-select [(ngModel)]="address.city" required formControlName="Ville">
                            <mat-option *ngFor="let ville of listvilles |keyvalue " [value]="ville.value">
                                {{ville.value}}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="formClient.controls['Ville'].hasError('required') ">Ville obligatoire
                        </mat-error>
                    </mat-form-field>

                </div>
            </div>
            <div class="row mt-md-3 ">
                <div class="col-md-6 col-sm-12 ">
                    <mat-form-field class="w-100 ">
                        <mat-label>N°de Voie</mat-label>
                        <input matInput [(ngModel)]="address.streetNumber" min="0" formControlName="Numéro de rue"
                            (keypress)="numberOnly($event)" required type="text" maxlength="5" name="streetNumber">
                        <mat-error *ngIf="formClient.controls['Numéro de rue'].hasError('required') ">N°de Voie
                            obligatoire</mat-error>

                    </mat-form-field>

                </div>
                <div class="col-md-6 col-sm-12 ">

                    <mat-form-field class="w-100 ">
                        <mat-label>Voie</mat-label>
                        <input matInput [(ngModel)]="address.streetName" formControlName="Nom de rue" required
                            type="text" name="streetName">
                        <mat-error *ngIf="formClient.controls['Nom de rue'].hasError('required') ">Voie obligatoire
                        </mat-error>
                    </mat-form-field>

                </div>
                <!-- 
                <div class="col-md-4 col-sm-12 ">
                    <mat-form-field class="w-100 ">
                        <mat-label>Pays</mat-label>
                        <mat-select [(ngModel)]="address.country" required formControlName="Pays">
                            <mat-option value="France" >
                                France
                            </mat-option>
                        </mat-select>
                    </mat-form-field>


                </div>
 -->
            </div>
            <div class="row mt-md-3">

                <mat-form-field class="w-100">
                    <textarea placeholder="Complément d 'Adresse" [(ngModel)]="address.supplement"
                        formControlName="Complément d 'Adresse" matInput name="supplement" rows="3"></textarea>
                </mat-form-field>
            </div>




        </div>


        <div class="card-footer ">
            <div class="row mt-4 justify-content-around">
                <app-buttonretour class="w-auto m-1" (changepreviouspage)="Previouspage($event)" [label]="retour">
                </app-buttonretour>
                <app-button class="w-auto m-1" (changepagesuivante)="Nextpage($event)" [label]="suivant"></app-button>
            </div>
        </div>
    </div>