import { Component, OnInit } from '@angular/core';

import { DomSanitizer, SafeUrl } from "@angular/platform-browser";
import { NgbActiveModal, NgbModal, NgbModalOptions } from "@ng-bootstrap/ng-bootstrap"
import { ApicreationService } from 'src/app/core/services/apicreationservice.service';
import { GeneriqueService } from 'src/app/core/services/Generiqservice/generiqueservice.service';
import { RatingserviceService } from 'src/app/core/services/rate/ratingservice.service';
import { References } from 'src/app/shared/models/references';
export class Rating {
  note: string
  references: References
  comment: string

}
@Component({
  selector: 'app-madal-rating',
  templateUrl: './madal-rating.component.html',
  styleUrls: ['./madal-rating.component.css']
})
export class MadalRatingContent {
  rating: Rating = new Rating;
  sliderrange: number = 50;
  note: string = "";
  comment: string = "";
aide:boolean=false; 
  public x = { rating: 3 };

  constructor(public activeModal: NgbActiveModal, public ratingserviceService: RatingserviceService, public apicreationService: ApicreationService, private generiqueService: GeneriqueService,) {

  }


  Envoyer() {
    if (this.sliderrange >= 0 && this.sliderrange <= 20) {
      this.note = "1"
    } else if (this.sliderrange > 20 && this.sliderrange <= 40) {
      this.note = "2"
    }
    else if (this.sliderrange > 40 && this.sliderrange <= 60) {
      this.note = "3"
    }
    else if (this.sliderrange > 60 && this.sliderrange <= 80) {
      this.note = "4"
    } else {
      this.note = "5"
    }
    this.rating.note = this.note
    this.rating.comment = this.comment;
    this.rating.references = this.apicreationService.insuranceinfo.references;



    this.ratingserviceService.Addrating(this.rating).subscribe(
      (data: any) => {

        this.aide=true; 


      }, error => {
        this.generiqueService.alertWarning(error)
      }

    );
  }


close(){
  this.activeModal.dismiss('Cross click')
}

  checkSliderValue() {
    let range = document.querySelector(".range");
    let slider = document.querySelector(".slider");
    let gradientStop = document.querySelector("#gradient-stop");
    let gradientGrey = document.querySelector("#gradient-grey");

    let colorBad = "#ff5722";
    let colorOk = "#ff9800";
    let colorGood = "#36d896";
    let colorGreat = "#2C4F6C";

    gradientGrey?.setAttribute("offset", 100 - this.sliderrange + "%");

    if (this.sliderrange > 0 && this.sliderrange <= 25) {
      gradientStop?.setAttribute("stop-color", colorBad);
    }
    if (this.sliderrange > 25 && this.sliderrange <= 50) {
      gradientStop?.setAttribute("stop-color", colorOk);
    }
    if (this.sliderrange > 50 && this.sliderrange <= 75) {
      gradientStop?.setAttribute("stop-color", colorGood);
    }
    if (this.sliderrange > 75 && this.sliderrange <= 100) {
      gradientStop?.setAttribute("stop-color", colorGreat);
    }
  }
}
