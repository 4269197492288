import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UploaddocpageComponent } from '../../uploaddocpage.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-validation-upload',
  templateUrl: './validation-upload.component.html',
  styleUrls: ['./validation-upload.component.css']
})
export class ValidationUploadComponent implements OnInit {
  appear : boolean = true;
  protected urlEspaceClient = environment.urlEspaceClient;
  url: string;
  constructor( @Inject(MAT_DIALOG_DATA) public data: any,) {
    console.log(data);
    this.url = this.urlEspaceClient+'?mail='+ data.mail;
    if (this.data.Attestation && this.data.pieceIdentite ){
      this.appear = false;
 }
  }

 

  ngOnInit(): void {
  }

  close(){
 window.close();
  }
}
