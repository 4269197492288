import {  Injectable} from '@angular/core';
import { Apierrorresponse } from 'src/app/shared/models/apierrorresponse';
import { Esign_body } from 'src/app/shared/models/esign_body';
import { Epay_body } from 'src/app/shared/models/pay-body';
import { GeneriqueService } from '../Generiqservice/generiqueservice.service';

@Injectable({
  providedIn: 'root'
})

export class SubscriptionService {
 
  constructor(public generiqueService :GeneriqueService ) { }
 /**
   * web services to subscibe
   *
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */

  public electroniquesignature( observe: any = 'body', reportProgress: boolean = false ) {

    return this.generiqueService.PostApi(observe,reportProgress, '/e-sign')
  
  }



 /**
   * web services to subscibe
   *
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */

  public electroniquesignatureStripe( observe: any = 'body', reportProgress: boolean = false ) {

    return this.generiqueService.PostApi(observe,reportProgress, '/e-yousign')
  
  }


   /**
   * web services to subscibe
   *
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */

    public electroniquePayStripe( observe: any = 'body', reportProgress: boolean = false ) {

      return this.generiqueService.PostApi(observe,reportProgress, '/pay')
    
    }
  

  /**
   * * add subscription
  *
  * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
  * @param reportProgress flag to report request and response progress.
  */
  public Addsubsciption( observe: any = 'body', reportProgress: boolean = false ){
    return this.generiqueService.PostApi(observe,reportProgress, '/subscribe')
   
  }



  /**
   * * add subscription for the Stripe process
  *
  * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
  * @param reportProgress flag to report request and response progress.
  */
   public AddsubsciptionStripe( observe: any = 'body', reportProgress: boolean = false ){
    return this.generiqueService.PostApi(observe,reportProgress, '/substripe')
   
  }

    /**
   * * check signing
  *
  * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
   public checksign( parametre : string , observe: any = 'body', reportProgress: boolean = false  ) {
    return this.generiqueService.GetApi(observe, reportProgress, `/signing-status?eSignRef=${parametre}`);
  
   }
   /**
   * * check signing
  *
  * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
    public checkPay( parametre : string , observe: any = 'body', reportProgress: boolean = false  ) {
      return this.generiqueService.GetApi(observe, reportProgress, `/pay-status?paymentRef=${parametre}`);
    
   }
 


      /**
   * * check signing stripe
  *
  * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
       public checkPayStripe( parametre : string , observe: any = 'body', reportProgress: boolean = false  ) {
        return this.generiqueService.GetApi(observe, reportProgress, `/paystripe-status?paymentRef=${parametre}`);
      
     }
}