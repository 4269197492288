<div class=" card h-100 border-0">
  <div class="card-body p-0">
    <div class="mb-md-3 mt-md-1  mx-xl-4 mx-lg-4 titreRecap text-center mx-sm-4 mx-md-4">
      <h3>Récapitulatif de vos garanties</h3>
    </div>

    <div class="col-md-12 m-0" id="Garanties">


      <div class="row m-3" *ngIf="theft">
        <div class="col-xl-4  p-0 col-lg-12 col-md-12  justify-content-center text-center " id="imagefirst">

          <div class="col-lg-12 col-md-12 "
            [ngClass]="{'option3': nboption===3 ,'option2': nboption===2 ,'option1': nboption===1 ,'mt-5': nboption===0 }">
            <svg xmlns="http://www.w3.org/2000/svg" width="80.592" height="71.33" viewBox="0 0 80.592 71.33">
              <g id="Groupe_10011" data-name="Groupe 10011" transform="translate(-845.743 -367.063)">
                <g id="Groupe_10008" data-name="Groupe 10008" transform="translate(860.138 367.063)">
                  <path id="Tracé_7325" data-name="Tracé 7325"
                    d="M942.032,495.493H894.3V465.116a8.679,8.679,0,0,1,8.68-8.678h30.376a8.679,8.679,0,0,1,8.68,8.678v30.377Zm0,0"
                    transform="translate(-892.264 -426.197)" fill="#2c4f6c" />
                  <path id="Tracé_7326" data-name="Tracé 7326"
                    d="M960.691,486.425a4.339,4.339,0,1,0-6.749,3.61l-1.93,13.749h8.678l-1.93-13.749a4.336,4.336,0,0,0,1.93-3.61Zm0,0"
                    transform="translate(-930.45 -443.167)" fill="#db6b5e" />
                  <path id="Tracé_7327" data-name="Tracé 7327"
                    d="M931.41,395.465V377.777A10.725,10.725,0,0,0,920.7,367.063H907.678a10.725,10.725,0,0,0-10.713,10.714v17.688a10.731,10.731,0,0,0-8.68,10.518v30.376a2.034,2.034,0,0,0,2.034,2.034h47.734a2.033,2.033,0,0,0,2.034-2.034V405.982a10.729,10.729,0,0,0-8.679-10.517Zm-23.732-24.334H920.7a6.653,6.653,0,0,1,6.644,6.646V395.27H901.034V377.777a6.652,6.652,0,0,1,6.644-6.646Zm28.343,63.194H892.354V405.982A6.652,6.652,0,0,1,899,399.338h30.377a6.653,6.653,0,0,1,6.645,6.644Zm0,0"
                    transform="translate(-888.286 -367.063)" fill="#2c4f6c" />
                  <path id="Tracé_7328" data-name="Tracé 7328"
                    d="M952.374,476.074a6.374,6.374,0,0,0-4.58,10.807l-1.774,12.642a2.054,2.054,0,0,0,2.015,2.317h8.678a2.054,2.054,0,0,0,2.014-2.317l-1.774-12.642a6.374,6.374,0,0,0-4.58-10.807Zm.4,10.264,1.6,11.433h-4l1.6-11.433a2.037,2.037,0,0,0-.883-1.973,2.3,2.3,0,1,1,2.558,0,2.032,2.032,0,0,0-.884,1.973Zm0,0"
                    transform="translate(-926.473 -439.189)" />
                </g>
                <g id="Groupe_10010" data-name="Groupe 10010" transform="translate(845.743 403.949)">
                  <rect id="Rectangle_4988" data-name="Rectangle 4988" width="80.592" height="26.264" rx="13.132"
                    transform="translate(0 0)" fill="#8ea0af" />
                  <g id="Groupe_10009" data-name="Groupe 10009" transform="translate(13.655 8.217)">
                    <path id="Tracé_7329" data-name="Tracé 7329"
                      d="M891.266,500.361l1.22,3.755h3.947l-3.193,2.32,1.22,3.754-3.194-2.32-3.193,2.32,1.219-3.754-3.193-2.32h3.947Zm0,0"
                      transform="translate(-886.099 -500.361)" fill="#f0f2f5" />
                    <path id="Tracé_7330" data-name="Tracé 7330"
                      d="M933.575,500.361l1.22,3.755h3.948l-3.193,2.32,1.22,3.754-3.194-2.32-3.193,2.32,1.219-3.754-3.193-2.32h3.948Zm0,0"
                      transform="translate(-914.092 -500.361)" fill="#f0f2f5" />
                    <path id="Tracé_7331" data-name="Tracé 7331"
                      d="M975.885,500.361l1.22,3.755h3.948l-3.193,2.32,1.22,3.754-3.194-2.32-3.193,2.32,1.219-3.754-3.193-2.32h3.948Zm0,0"
                      transform="translate(-942.086 -500.361)" fill="#f0f2f5" />
                    <path id="Tracé_7332" data-name="Tracé 7332"
                      d="M1018.2,500.361l1.22,3.755h3.948l-3.193,2.32,1.22,3.754-3.194-2.32L1015,510.19l1.219-3.754-3.193-2.32h3.948Zm0,0"
                      transform="translate(-970.08 -500.361)" fill="#f0f2f5" />
                  </g>
                </g>
              </g>
            </svg>


          </div>
          <div class="col-lg-12 col-md-12 mt-3">
            <p>Vol</p>
          </div>
        </div>
        <div class="col-xl-8 col-lg-12 col-md-12">
          <div class="row">
            <div class="col-xl-6 col-lg-12 col-md-12" style="background-color: #D8DEE4">
              <div class="row p-1" style="background-color: #D8DEE4">
                <div class="col-sm-10">

                  <svg xmlns="http://www.w3.org/2000/svg" width="11.834" height="9.571" viewBox="0 0 11.834 9.571">
                    <path id="Union_18" data-name="Union 18"
                      d="M-4388.536,1404.571a2.4,2.4,0,0,1-2.465-2.326v-2.325a2.4,2.4,0,0,1,2.465-2.326h1.337l.943-1.8A1.5,1.5,0,0,1-4385,1395l.091,0a1.57,1.57,0,0,1,.458.068,1.422,1.422,0,0,1,1.039,1.35,1.363,1.363,0,0,1-.02.225l-.166.949h1.969a2.526,2.526,0,0,1,1.858.8,2.251,2.251,0,0,1,.607,1.522,2.258,2.258,0,0,1-.024.329l-.348,2.326a2.434,2.434,0,0,1-2.44,2Zm6.557-.931a1.459,1.459,0,0,0,1.461-1.2l.349-2.326a1.517,1.517,0,0,0,.015-.188,1.351,1.351,0,0,0-.363-.922,1.517,1.517,0,0,0-1.116-.48h-2.547a.507.507,0,0,1-.376-.164.452.452,0,0,1-.117-.3.4.4,0,0,1,.006-.076l.26-1.489a.43.43,0,0,0,.007-.079.462.462,0,0,0-.123-.312.541.541,0,0,0-.374-.16h-.038l-.04,0a.53.53,0,0,0-.4.253l-1.079,2.063a.466.466,0,0,1-.113.136v5.241Zm-6.557,0h.986v-5.117h-.986a1.439,1.439,0,0,0-1.479,1.4v2.325A1.439,1.439,0,0,0-4388.536,1403.64Z"
                      transform="translate(4391 -1395)" fill="#ff661c" />
                  </svg>

                  <span>Effraction</span>
                </div>
              </div>
              <div class="row p-1 " style="background-color: #D8DEE4 ">
                <div class="col-sm-10">

                  <svg xmlns="http://www.w3.org/2000/svg" width="11.834" height="9.571" viewBox="0 0 11.834 9.571">
                    <path id="Union_18" data-name="Union 18"
                      d="M-4388.536,1404.571a2.4,2.4,0,0,1-2.465-2.326v-2.325a2.4,2.4,0,0,1,2.465-2.326h1.337l.943-1.8A1.5,1.5,0,0,1-4385,1395l.091,0a1.57,1.57,0,0,1,.458.068,1.422,1.422,0,0,1,1.039,1.35,1.363,1.363,0,0,1-.02.225l-.166.949h1.969a2.526,2.526,0,0,1,1.858.8,2.251,2.251,0,0,1,.607,1.522,2.258,2.258,0,0,1-.024.329l-.348,2.326a2.434,2.434,0,0,1-2.44,2Zm6.557-.931a1.459,1.459,0,0,0,1.461-1.2l.349-2.326a1.517,1.517,0,0,0,.015-.188,1.351,1.351,0,0,0-.363-.922,1.517,1.517,0,0,0-1.116-.48h-2.547a.507.507,0,0,1-.376-.164.452.452,0,0,1-.117-.3.4.4,0,0,1,.006-.076l.26-1.489a.43.43,0,0,0,.007-.079.462.462,0,0,0-.123-.312.541.541,0,0,0-.374-.16h-.038l-.04,0a.53.53,0,0,0-.4.253l-1.079,2.063a.466.466,0,0,1-.113.136v5.241Zm-6.557,0h.986v-5.117h-.986a1.439,1.439,0,0,0-1.479,1.4v2.325A1.439,1.439,0,0,0-4388.536,1403.64Z"
                      transform="translate(4391 -1395)" fill="#ff661c" />
                  </svg>
                  <span>Agression</span>
                </div>

              </div>
              <div class="row p-1 " style="background-color: #D8DEE4">
                <div class="col-sm-10">

                  <svg xmlns="http://www.w3.org/2000/svg" width="11.834" height="9.571" viewBox="0 0 11.834 9.571">
                    <path id="Union_18" data-name="Union 18"
                      d="M-4388.536,1404.571a2.4,2.4,0,0,1-2.465-2.326v-2.325a2.4,2.4,0,0,1,2.465-2.326h1.337l.943-1.8A1.5,1.5,0,0,1-4385,1395l.091,0a1.57,1.57,0,0,1,.458.068,1.422,1.422,0,0,1,1.039,1.35,1.363,1.363,0,0,1-.02.225l-.166.949h1.969a2.526,2.526,0,0,1,1.858.8,2.251,2.251,0,0,1,.607,1.522,2.258,2.258,0,0,1-.024.329l-.348,2.326a2.434,2.434,0,0,1-2.44,2Zm6.557-.931a1.459,1.459,0,0,0,1.461-1.2l.349-2.326a1.517,1.517,0,0,0,.015-.188,1.351,1.351,0,0,0-.363-.922,1.517,1.517,0,0,0-1.116-.48h-2.547a.507.507,0,0,1-.376-.164.452.452,0,0,1-.117-.3.4.4,0,0,1,.006-.076l.26-1.489a.43.43,0,0,0,.007-.079.462.462,0,0,0-.123-.312.541.541,0,0,0-.374-.16h-.038l-.04,0a.53.53,0,0,0-.4.253l-1.079,2.063a.466.466,0,0,1-.113.136v5.241Zm-6.557,0h.986v-5.117h-.986a1.439,1.439,0,0,0-1.479,1.4v2.325A1.439,1.439,0,0,0-4388.536,1403.64Z"
                      transform="translate(4391 -1395)" fill="#ff661c" />
                  </svg>
                  <span>Galerie & porte vélo</span>
                </div>


              </div>
              <div class="row p-1 " style="background-color: #D8DEE4">
                <div class="col-sm-10" style="white-space: nowrap;">

                  <svg xmlns="http://www.w3.org/2000/svg" width="11.834" height="9.571" viewBox="0 0 11.834 9.571">
                    <path id="Union_18" data-name="Union 18"
                      d="M-4388.536,1404.571a2.4,2.4,0,0,1-2.465-2.326v-2.325a2.4,2.4,0,0,1,2.465-2.326h1.337l.943-1.8A1.5,1.5,0,0,1-4385,1395l.091,0a1.57,1.57,0,0,1,.458.068,1.422,1.422,0,0,1,1.039,1.35,1.363,1.363,0,0,1-.02.225l-.166.949h1.969a2.526,2.526,0,0,1,1.858.8,2.251,2.251,0,0,1,.607,1.522,2.258,2.258,0,0,1-.024.329l-.348,2.326a2.434,2.434,0,0,1-2.44,2Zm6.557-.931a1.459,1.459,0,0,0,1.461-1.2l.349-2.326a1.517,1.517,0,0,0,.015-.188,1.351,1.351,0,0,0-.363-.922,1.517,1.517,0,0,0-1.116-.48h-2.547a.507.507,0,0,1-.376-.164.452.452,0,0,1-.117-.3.4.4,0,0,1,.006-.076l.26-1.489a.43.43,0,0,0,.007-.079.462.462,0,0,0-.123-.312.541.541,0,0,0-.374-.16h-.038l-.04,0a.53.53,0,0,0-.4.253l-1.079,2.063a.466.466,0,0,1-.113.136v5.241Zm-6.557,0h.986v-5.117h-.986a1.439,1.439,0,0,0-1.479,1.4v2.325A1.439,1.439,0,0,0-4388.536,1403.64Z"
                      transform="translate(4391 -1395)" fill="#ff661c" />
                  </svg>
                  <span>Remise en état ou remplacement</span>
                </div>

              </div>

              <div class="row p-1 " style="background-color: #D8DEE4 " *ngIf="accidentGarantie">
                <div class="col-sm-10 " style="white-space: nowrap;">

                  <svg xmlns="http://www.w3.org/2000/svg" width="12.393" height="12.39" viewBox="0 0 12.393 12.39">
                    <path id="Union_22" data-name="Union 22"
                      d="M-10550.051,1539.493a.837.837,0,0,1-.247-.6v-4.509h-4.509a.838.838,0,0,1-.6-.246.839.839,0,0,1-.249-.6.846.846,0,0,1,.845-.845h4.509v-4.509a.845.845,0,0,1,.843-.842.838.838,0,0,1,.6.246.839.839,0,0,1,.249.6v4.509h4.507a.838.838,0,0,1,.6.246.842.842,0,0,1,.249.6.845.845,0,0,1-.845.842h-4.507v4.509a.845.845,0,0,1-.845.842A.835.835,0,0,1-10550.051,1539.493Z"
                      transform="translate(10555.651 -1527.35)" fill="#ff661c" />
                  </svg>
                  <span> Option Individuelle accident </span>
                </div>

              </div>

              <div class="row p-1 " style="background-color: #D8DEE4 " *ngIf="valeurNeufGarantie">
                <div class="col-sm-10 " style="white-space: nowrap;">

                  <svg xmlns="http://www.w3.org/2000/svg" width="12.393" height="12.39" viewBox="0 0 12.393 12.39">
                    <path id="Union_22" data-name="Union 22"
                      d="M-10550.051,1539.493a.837.837,0,0,1-.247-.6v-4.509h-4.509a.838.838,0,0,1-.6-.246.839.839,0,0,1-.249-.6.846.846,0,0,1,.845-.845h4.509v-4.509a.845.845,0,0,1,.843-.842.838.838,0,0,1,.6.246.839.839,0,0,1,.249.6v4.509h4.507a.838.838,0,0,1,.6.246.842.842,0,0,1,.249.6.845.845,0,0,1-.845.842h-4.507v4.509a.845.845,0,0,1-.845.842A.835.835,0,0,1-10550.051,1539.493Z"
                      transform="translate(10555.651 -1527.35)" fill="#ff661c" />
                  </svg>
                  <span> Option Valeur à neuf</span>
                </div>

              </div>

              <div class="row p-1 " style="background-color: #D8DEE4 " *ngIf="excessGarantie">
                <div class="col-sm-10 " style="white-space: nowrap;">

                  <svg xmlns="http://www.w3.org/2000/svg" width="12.393" height="12.39" viewBox="0 0 12.393 12.39">
                    <path id="Union_22" data-name="Union 22"
                      d="M-10550.051,1539.493a.837.837,0,0,1-.247-.6v-4.509h-4.509a.838.838,0,0,1-.6-.246.839.839,0,0,1-.249-.6.846.846,0,0,1,.845-.845h4.509v-4.509a.845.845,0,0,1,.843-.842.838.838,0,0,1,.6.246.839.839,0,0,1,.249.6v4.509h4.507a.838.838,0,0,1,.6.246.842.842,0,0,1,.249.6.845.845,0,0,1-.845.842h-4.507v4.509a.845.845,0,0,1-.845.842A.835.835,0,0,1-10550.051,1539.493Z"
                      transform="translate(10555.651 -1527.35)" fill="#ff661c" />
                  </svg>
                  <span> Option Franchise 0% </span>
                </div>

              </div>
      
              <div class="row mt-2 text-center p-0" style="background-color: #D8DEE4">
                <p>Voir plus de détails et la liste complète des exclusions dans nos
                  <u onclick="window.open('assets/images/berlishCG.pdf', '_blank')">CG</u> et <u
                    onclick="window.open('assets/images/ipid.pdf', '_blank')">IPID</u>
                </p>
              </div>
            </div>

            <div class="col-xl-6 col-lg-12 col-md-12 pt-xl-4  pb-3 text-white text-center" id="devis">


              <h3 class="text-white  devisPrixBold"
                [ngClass]="{'mt-md-5': nboption===3 || nboption===2  ,'mt-md-4': nboption===1  || nboption===0  }">
                {{garantieMois}}€ /mois </h3>
              <label class="devisPrixRegular">{{garantieYear}}€ /an</label><br />
              <label class="devisPrixRegular"> + frais de dossier de {{fraisDossier}}€</label>
            </div>
          </div>
        </div>
      </div>

      <div class="row m-3 " *ngIf="dommage">
        <div class="col-xl-4 p-0  col-lg-12 col-md-12 justify-content-center text-center" id="imagefirst">

          <div class="col-lg-12 col-md-12 "
            [ngClass]="{'option3': nboption===3 ,'option2': nboption===2 ,'option1': nboption===1 ,'mt-5': nboption===0 }">
            <svg xmlns="http://www.w3.org/2000/svg" width="79.002" height="71.455" viewBox="0 0 79.002 71.455">
              <g id="Groupe_10015" data-name="Groupe 10015" transform="translate(-1171.323 -382.692)">
                <path id="Tracé_7343" data-name="Tracé 7343"
                  d="M1218.588,402.813l-6.669-6.67-5.336-2.669-4,4,9.339,9.339-5.336,5.336-9.339-9.339-4,4,2.667,5.336,6.67,6.67,9.339-1.334,21.343,21.345-1.334,9.337,6.67,6.67,5.337,2.669,4-4-9.338-9.337,5.337-5.336,9.338,9.337,4-4-2.668-5.336-6.67-6.67-9.338,1.333-21.344-21.343Zm0,0"
                  transform="translate(-14.49 -7.127)" fill="#2c4f6c" />
                <g id="Groupe_10013" data-name="Groupe 10013" transform="translate(1216.602 382.692)">
                  <path id="Tracé_7344" data-name="Tracé 7344"
                    d="M1340.312,405.519v-5.031l-4.285-.856a11.187,11.187,0,0,0-.784-1.884l2.426-3.639-3.557-3.557-3.638,2.426a11.2,11.2,0,0,0-1.883-.784l-.856-4.285H1322.7l-.856,4.285a11.2,11.2,0,0,0-1.884.784l-3.638-2.426-3.558,3.557,2.426,3.639a11.2,11.2,0,0,0-.784,1.884l-4.284.856v5.031l4.284.856a11.233,11.233,0,0,0,.784,1.884l-2.426,3.637,3.558,3.558,3.638-2.426a11.233,11.233,0,0,0,1.884.784l.856,4.284h5.031l.856-4.284a11.227,11.227,0,0,0,1.883-.784l3.638,2.426,3.557-3.558-2.426-3.637a11.223,11.223,0,0,0,.784-1.884Zm-15.093,2.515a5.031,5.031,0,1,1,5.031-5.03,5.03,5.03,0,0,1-5.031,5.03Zm0,0"
                    transform="translate(-1308.357 -386.141)" fill="#8ea0af" />
                  <path id="Tracé_7345" data-name="Tracé 7345"
                    d="M1336.258,404.609a9.433,9.433,0,1,0,9.433,9.433,9.433,9.433,0,0,0-9.433-9.433Zm0,15.093a5.66,5.66,0,1,1,5.66-5.66,5.66,5.66,0,0,1-5.66,5.66Zm0,0"
                    transform="translate(-1319.396 -397.18)" fill="#8ea0af" />
                  <path id="Tracé_7346" data-name="Tracé 7346"
                    d="M1341.395,425.976a6.8,6.8,0,1,0-6.8-6.8,6.807,6.807,0,0,0,6.8,6.8Zm0-10.061a3.262,3.262,0,1,1-3.261,3.263,3.267,3.267,0,0,1,3.261-3.263Zm0,0"
                    transform="translate(-1324.533 -402.315)" fill="#2c4f6c" />
                  <path id="Tracé_7347" data-name="Tracé 7347"
                    d="M1306.33,403.8l3.306.661c.026.066.054.132.081.2l-1.87,2.805a1.768,1.768,0,0,0,.221,2.232l3.557,3.557a1.768,1.768,0,0,0,2.232.222l2.806-1.871.2.081.661,3.306a1.769,1.769,0,0,0,1.734,1.422h5.031a1.769,1.769,0,0,0,1.734-1.422l.661-3.306.2-.081,2.807,1.87a1.767,1.767,0,0,0,2.231-.221l3.557-3.557a1.768,1.768,0,0,0,.221-2.232l-1.87-2.806c.028-.064.054-.13.081-.2l3.307-.661a1.769,1.769,0,0,0,1.422-1.734v-5.031a1.769,1.769,0,0,0-1.422-1.734l-3.307-.661c-.026-.065-.054-.131-.081-.2l1.87-2.805a1.768,1.768,0,0,0-.221-2.232l-3.557-3.557a1.768,1.768,0,0,0-2.231-.222l-2.807,1.871c-.064-.028-.13-.054-.2-.082l-.661-3.306a1.77,1.77,0,0,0-1.734-1.422h-5.031a1.769,1.769,0,0,0-1.734,1.422l-.661,3.306-.2.082-2.806-1.871a1.77,1.77,0,0,0-2.232.222l-3.557,3.557a1.768,1.768,0,0,0-.221,2.232l1.87,2.805c-.028.065-.055.131-.081.2l-3.306.661a1.769,1.769,0,0,0-1.422,1.734v5.031a1.769,1.769,0,0,0,1.422,1.734Zm2.116-5.315,2.862-.573a1.764,1.764,0,0,0,1.341-1.208,9.539,9.539,0,0,1,.661-1.586,1.77,1.77,0,0,0-.094-1.8l-1.622-2.432,1.508-1.507,2.432,1.622a1.77,1.77,0,0,0,1.8.094,9.5,9.5,0,0,1,1.587-.661,1.77,1.77,0,0,0,1.208-1.341l.572-2.862h2.131l.573,2.862a1.765,1.765,0,0,0,1.208,1.341,9.5,9.5,0,0,1,1.586.661,1.773,1.773,0,0,0,1.8-.094l2.433-1.622,1.507,1.507-1.621,2.432a1.771,1.771,0,0,0-.094,1.8,9.5,9.5,0,0,1,.661,1.586,1.766,1.766,0,0,0,1.341,1.208l2.862.573v2.131l-2.862.572a1.769,1.769,0,0,0-1.341,1.208,9.462,9.462,0,0,1-.661,1.587,1.769,1.769,0,0,0,.094,1.8l1.621,2.432-1.507,1.508-2.433-1.622a1.768,1.768,0,0,0-1.8-.094,9.56,9.56,0,0,1-1.586.661,1.765,1.765,0,0,0-1.208,1.341l-.573,2.863H1320.7l-.572-2.863a1.767,1.767,0,0,0-1.208-1.341,9.552,9.552,0,0,1-1.587-.661,1.769,1.769,0,0,0-1.8.094l-2.432,1.622-1.508-1.508,1.622-2.432a1.768,1.768,0,0,0,.094-1.8,9.546,9.546,0,0,1-.661-1.587,1.768,1.768,0,0,0-1.341-1.208l-2.862-.572Zm0,0"
                    transform="translate(-1304.908 -382.692)" fill="#2c4f6c" />
                </g>
                <path id="Tracé_7348" data-name="Tracé 7348"
                  d="M1255.406,439.928l-2.668-5.336c-.084-.169-7-7.129-7-7.129a1.767,1.767,0,0,0-1.5-.5l-8.463,1.209-20.093-20.094,1.209-8.462a1.771,1.771,0,0,0-.5-1.5s-6.961-6.916-7.13-7l-5.336-2.669a1.769,1.769,0,0,0-2.042.332l-4,4a1.769,1.769,0,0,0,0,2.5l8.087,8.088-2.835,2.834-8.087-8.087a1.769,1.769,0,0,0-2.5,0l-4,4a1.77,1.77,0,0,0-.331,2.042l2.667,5.336c.085.17,7,7.13,7,7.13a1.775,1.775,0,0,0,1.5.5l8.463-1.209,20.094,20.095-1.209,8.462a1.767,1.767,0,0,0,.5,1.5s6.96,6.917,7.13,7l5.336,2.669a1.783,1.783,0,0,0,2.041-.332l4-4a1.785,1.785,0,0,0,0-2.5l-8.087-8.087,2.834-2.835,8.088,8.087a1.785,1.785,0,0,0,2.5,0l4-4a1.769,1.769,0,0,0,.332-2.043Zm-5.584,2.293-8.087-8.088a1.77,1.77,0,0,0-2.5,0l-5.336,5.336a1.77,1.77,0,0,0,0,2.5l8.088,8.087-1.85,1.85-3.94-1.97-5.841-5.842,1.209-8.463a1.774,1.774,0,0,0-.5-1.5l-21.344-21.344a1.77,1.77,0,0,0-1.5-.5l-8.463,1.209-5.841-5.841-1.97-3.94,1.85-1.849,8.087,8.087a1.77,1.77,0,0,0,2.5,0l5.336-5.336a1.77,1.77,0,0,0,0-2.5l-8.087-8.087,1.849-1.849,3.94,1.968,5.841,5.843-1.209,8.462a1.77,1.77,0,0,0,.5,1.5L1233.9,431.3a1.771,1.771,0,0,0,1.5.5l8.463-1.209,5.842,5.841,1.97,3.94Zm0,0"
                  transform="translate(-11.039 -3.68)" fill="#2c4f6c" />
                <g id="Groupe_10014" data-name="Groupe 10014" transform="translate(1171.323 420.423)">
                  <path id="Tracé_7349" data-name="Tracé 7349"
                    d="M1206.726,516.837v-5.031l-4.284-.856a11.186,11.186,0,0,0-.784-1.884l2.426-3.639-3.558-3.557-3.637,2.426a11.189,11.189,0,0,0-1.884-.784l-.856-4.285h-5.031l-.856,4.285a11.165,11.165,0,0,0-1.884.784l-3.638-2.426-3.558,3.557,2.426,3.639a11.187,11.187,0,0,0-.784,1.884l-4.285.856v5.031l4.285.856a11.223,11.223,0,0,0,.784,1.884l-2.426,3.637,3.558,3.558,3.638-2.426a11.2,11.2,0,0,0,1.884.784l.856,4.284h5.031l.856-4.284a11.226,11.226,0,0,0,1.884-.784l3.637,2.426,3.558-3.558-2.426-3.637a11.222,11.222,0,0,0,.784-1.884Zm-15.093,2.515a5.031,5.031,0,1,1,5.031-5.03,5.03,5.03,0,0,1-5.031,5.03Zm0,0"
                    transform="translate(-1174.772 -497.459)" fill="#8ea0af" />
                  <path id="Tracé_7350" data-name="Tracé 7350"
                    d="M1202.673,515.927a9.433,9.433,0,1,0,9.433,9.433,9.433,9.433,0,0,0-9.433-9.433Zm0,15.093a5.66,5.66,0,1,1,5.66-5.66,5.66,5.66,0,0,1-5.66,5.66Zm0,0"
                    transform="translate(-1185.811 -508.498)" fill="#8ea0af" />
                  <path id="Tracé_7351" data-name="Tracé 7351"
                    d="M1207.81,537.294a6.8,6.8,0,1,0-6.8-6.8,6.807,6.807,0,0,0,6.8,6.8Zm0-10.061a3.262,3.262,0,1,1-3.262,3.263,3.267,3.267,0,0,1,3.262-3.263Zm0,0"
                    transform="translate(-1190.948 -513.633)" fill="#2c4f6c" />
                  <path id="Tracé_7352" data-name="Tracé 7352"
                    d="M1172.745,515.122l3.306.661q.04.1.082.2l-1.87,2.805a1.769,1.769,0,0,0,.221,2.232l3.557,3.557a1.768,1.768,0,0,0,2.231.222l2.807-1.871.2.081.661,3.306a1.769,1.769,0,0,0,1.734,1.422h5.031a1.769,1.769,0,0,0,1.734-1.422l.661-3.306.2-.081,2.807,1.87a1.768,1.768,0,0,0,2.231-.221l3.557-3.557a1.769,1.769,0,0,0,.221-2.232l-1.87-2.806c.028-.064.054-.13.081-.2l3.307-.661a1.769,1.769,0,0,0,1.422-1.734v-5.031a1.769,1.769,0,0,0-1.422-1.734l-3.307-.661c-.026-.065-.054-.131-.081-.2l1.87-2.805a1.768,1.768,0,0,0-.221-2.232l-3.557-3.557a1.767,1.767,0,0,0-2.231-.222l-2.807,1.871c-.064-.028-.13-.054-.2-.082l-.661-3.306a1.77,1.77,0,0,0-1.734-1.422h-5.031a1.769,1.769,0,0,0-1.734,1.422l-.661,3.306-.2.082-2.807-1.871a1.769,1.769,0,0,0-2.231.222l-3.557,3.557a1.768,1.768,0,0,0-.221,2.232l1.87,2.805c-.028.065-.055.131-.082.2l-3.306.661a1.769,1.769,0,0,0-1.422,1.734v5.031a1.769,1.769,0,0,0,1.422,1.734Zm2.116-5.315,2.862-.573a1.765,1.765,0,0,0,1.341-1.208,9.546,9.546,0,0,1,.661-1.586,1.77,1.77,0,0,0-.094-1.8l-1.622-2.432,1.508-1.507,2.432,1.622a1.77,1.77,0,0,0,1.8.094,9.47,9.47,0,0,1,1.587-.661,1.77,1.77,0,0,0,1.208-1.341l.572-2.862h2.131l.573,2.862a1.765,1.765,0,0,0,1.208,1.341,9.465,9.465,0,0,1,1.586.661,1.773,1.773,0,0,0,1.8-.094l2.433-1.622,1.507,1.507-1.622,2.432a1.77,1.77,0,0,0-.094,1.8,9.477,9.477,0,0,1,.661,1.586,1.767,1.767,0,0,0,1.342,1.208l2.862.573v2.131l-2.862.572a1.769,1.769,0,0,0-1.342,1.208,9.44,9.44,0,0,1-.661,1.587,1.769,1.769,0,0,0,.094,1.8l1.622,2.432-1.507,1.508-2.433-1.622a1.767,1.767,0,0,0-1.8-.094,9.551,9.551,0,0,1-1.586.661,1.765,1.765,0,0,0-1.208,1.341l-.573,2.863h-2.131l-.572-2.863a1.768,1.768,0,0,0-1.208-1.341,9.556,9.556,0,0,1-1.587-.661,1.768,1.768,0,0,0-1.8.094l-2.432,1.622-1.508-1.508,1.622-2.432a1.769,1.769,0,0,0,.094-1.8,9.567,9.567,0,0,1-.661-1.587,1.77,1.77,0,0,0-1.341-1.208l-2.862-.572Zm0,0"
                    transform="translate(-1171.323 -494.01)" fill="#2c4f6c" />
                </g>
              </g>
            </svg>



          </div>
          <div class="col-lg-12 col-md-12 mt-3">
            <p>Dommage</p>
          </div>
        </div>
        <div class="col-xl-8 col-lg-12 col-md-12">
          <div class="row">
            <div class="col-xl-6 col-lg-12 col-md-12" style="background-color: #D8DEE4">
              <div class="row p-1 ">
                <div class="col-sm-10">

                  <svg xmlns="http://www.w3.org/2000/svg" width="11.834" height="9.571" viewBox="0 0 11.834 9.571">
                    <path id="Union_18" data-name="Union 18"
                      d="M-4388.536,1404.571a2.4,2.4,0,0,1-2.465-2.326v-2.325a2.4,2.4,0,0,1,2.465-2.326h1.337l.943-1.8A1.5,1.5,0,0,1-4385,1395l.091,0a1.57,1.57,0,0,1,.458.068,1.422,1.422,0,0,1,1.039,1.35,1.363,1.363,0,0,1-.02.225l-.166.949h1.969a2.526,2.526,0,0,1,1.858.8,2.251,2.251,0,0,1,.607,1.522,2.258,2.258,0,0,1-.024.329l-.348,2.326a2.434,2.434,0,0,1-2.44,2Zm6.557-.931a1.459,1.459,0,0,0,1.461-1.2l.349-2.326a1.517,1.517,0,0,0,.015-.188,1.351,1.351,0,0,0-.363-.922,1.517,1.517,0,0,0-1.116-.48h-2.547a.507.507,0,0,1-.376-.164.452.452,0,0,1-.117-.3.4.4,0,0,1,.006-.076l.26-1.489a.43.43,0,0,0,.007-.079.462.462,0,0,0-.123-.312.541.541,0,0,0-.374-.16h-.038l-.04,0a.53.53,0,0,0-.4.253l-1.079,2.063a.466.466,0,0,1-.113.136v5.241Zm-6.557,0h.986v-5.117h-.986a1.439,1.439,0,0,0-1.479,1.4v2.325A1.439,1.439,0,0,0-4388.536,1403.64Z"
                      transform="translate(4391 -1395)" fill="#ff661c" />
                  </svg>
                  <span>Casse accidentelle</span>
                </div>

              </div>
              <div class="row p-1" style="background-color: #D8DEE4 ">
                <div class="col-sm-10">

                  <svg xmlns="http://www.w3.org/2000/svg" width="11.834" height="9.571" viewBox="0 0 11.834 9.571">
                    <path id="Union_18" data-name="Union 18"
                      d="M-4388.536,1404.571a2.4,2.4,0,0,1-2.465-2.326v-2.325a2.4,2.4,0,0,1,2.465-2.326h1.337l.943-1.8A1.5,1.5,0,0,1-4385,1395l.091,0a1.57,1.57,0,0,1,.458.068,1.422,1.422,0,0,1,1.039,1.35,1.363,1.363,0,0,1-.02.225l-.166.949h1.969a2.526,2.526,0,0,1,1.858.8,2.251,2.251,0,0,1,.607,1.522,2.258,2.258,0,0,1-.024.329l-.348,2.326a2.434,2.434,0,0,1-2.44,2Zm6.557-.931a1.459,1.459,0,0,0,1.461-1.2l.349-2.326a1.517,1.517,0,0,0,.015-.188,1.351,1.351,0,0,0-.363-.922,1.517,1.517,0,0,0-1.116-.48h-2.547a.507.507,0,0,1-.376-.164.452.452,0,0,1-.117-.3.4.4,0,0,1,.006-.076l.26-1.489a.43.43,0,0,0,.007-.079.462.462,0,0,0-.123-.312.541.541,0,0,0-.374-.16h-.038l-.04,0a.53.53,0,0,0-.4.253l-1.079,2.063a.466.466,0,0,1-.113.136v5.241Zm-6.557,0h.986v-5.117h-.986a1.439,1.439,0,0,0-1.479,1.4v2.325A1.439,1.439,0,0,0-4388.536,1403.64Z"
                      transform="translate(4391 -1395)" fill="#ff661c" />
                  </svg>
                  <span>Vandalisme</span>
                </div>

              </div>
              <div class="row p-1">
                <div class="col-sm-10">

                  <svg xmlns="http://www.w3.org/2000/svg" width="11.834" height="9.571" viewBox="0 0 11.834 9.571">
                    <path id="Union_18" data-name="Union 18"
                      d="M-4388.536,1404.571a2.4,2.4,0,0,1-2.465-2.326v-2.325a2.4,2.4,0,0,1,2.465-2.326h1.337l.943-1.8A1.5,1.5,0,0,1-4385,1395l.091,0a1.57,1.57,0,0,1,.458.068,1.422,1.422,0,0,1,1.039,1.35,1.363,1.363,0,0,1-.02.225l-.166.949h1.969a2.526,2.526,0,0,1,1.858.8,2.251,2.251,0,0,1,.607,1.522,2.258,2.258,0,0,1-.024.329l-.348,2.326a2.434,2.434,0,0,1-2.44,2Zm6.557-.931a1.459,1.459,0,0,0,1.461-1.2l.349-2.326a1.517,1.517,0,0,0,.015-.188,1.351,1.351,0,0,0-.363-.922,1.517,1.517,0,0,0-1.116-.48h-2.547a.507.507,0,0,1-.376-.164.452.452,0,0,1-.117-.3.4.4,0,0,1,.006-.076l.26-1.489a.43.43,0,0,0,.007-.079.462.462,0,0,0-.123-.312.541.541,0,0,0-.374-.16h-.038l-.04,0a.53.53,0,0,0-.4.253l-1.079,2.063a.466.466,0,0,1-.113.136v5.241Zm-6.557,0h.986v-5.117h-.986a1.439,1.439,0,0,0-1.479,1.4v2.325A1.439,1.439,0,0,0-4388.536,1403.64Z"
                      transform="translate(4391 -1395)" fill="#ff661c" />
                  </svg>
                  <span>Incendie</span>
                </div>

              </div>
              <div class="row p-1" style="background-color: #D8DEE4 ">
                <div class="col-sm-10">

                  <svg xmlns="http://www.w3.org/2000/svg" width="11.834" height="9.571" viewBox="0 0 11.834 9.571">
                    <path id="Union_18" data-name="Union 18"
                      d="M-4388.536,1404.571a2.4,2.4,0,0,1-2.465-2.326v-2.325a2.4,2.4,0,0,1,2.465-2.326h1.337l.943-1.8A1.5,1.5,0,0,1-4385,1395l.091,0a1.57,1.57,0,0,1,.458.068,1.422,1.422,0,0,1,1.039,1.35,1.363,1.363,0,0,1-.02.225l-.166.949h1.969a2.526,2.526,0,0,1,1.858.8,2.251,2.251,0,0,1,.607,1.522,2.258,2.258,0,0,1-.024.329l-.348,2.326a2.434,2.434,0,0,1-2.44,2Zm6.557-.931a1.459,1.459,0,0,0,1.461-1.2l.349-2.326a1.517,1.517,0,0,0,.015-.188,1.351,1.351,0,0,0-.363-.922,1.517,1.517,0,0,0-1.116-.48h-2.547a.507.507,0,0,1-.376-.164.452.452,0,0,1-.117-.3.4.4,0,0,1,.006-.076l.26-1.489a.43.43,0,0,0,.007-.079.462.462,0,0,0-.123-.312.541.541,0,0,0-.374-.16h-.038l-.04,0a.53.53,0,0,0-.4.253l-1.079,2.063a.466.466,0,0,1-.113.136v5.241Zm-6.557,0h.986v-5.117h-.986a1.439,1.439,0,0,0-1.479,1.4v2.325A1.439,1.439,0,0,0-4388.536,1403.64Z"
                      transform="translate(4391 -1395)" fill="#ff661c" />
                  </svg>
                  <span>Accessoires fixés</span>
                </div>

              </div>

              <div class="row p-1 " style="background-color: #D8DEE4 " *ngIf="accidentGarantie">
                <div class="col-sm-10 " style="white-space: nowrap;">
                  <svg xmlns="http://www.w3.org/2000/svg" width="12.393" height="12.39" viewBox="0 0 12.393 12.39">
                    <path id="Union_22" data-name="Union 22"
                      d="M-10550.051,1539.493a.837.837,0,0,1-.247-.6v-4.509h-4.509a.838.838,0,0,1-.6-.246.839.839,0,0,1-.249-.6.846.846,0,0,1,.845-.845h4.509v-4.509a.845.845,0,0,1,.843-.842.838.838,0,0,1,.6.246.839.839,0,0,1,.249.6v4.509h4.507a.838.838,0,0,1,.6.246.842.842,0,0,1,.249.6.845.845,0,0,1-.845.842h-4.507v4.509a.845.845,0,0,1-.845.842A.835.835,0,0,1-10550.051,1539.493Z"
                      transform="translate(10555.651 -1527.35)" fill="#ff661c" />
                  </svg>
                  <span> Option Individuelle accident </span>
                </div>

              </div>

              <div class="row p-1 " style="background-color: #D8DEE4 " *ngIf="valeurNeufGarantie">
                <div class="col-sm-10 " style="white-space: nowrap;">

                  <svg xmlns="http://www.w3.org/2000/svg" width="12.393" height="12.39" viewBox="0 0 12.393 12.39">
                    <path id="Union_22" data-name="Union 22"
                      d="M-10550.051,1539.493a.837.837,0,0,1-.247-.6v-4.509h-4.509a.838.838,0,0,1-.6-.246.839.839,0,0,1-.249-.6.846.846,0,0,1,.845-.845h4.509v-4.509a.845.845,0,0,1,.843-.842.838.838,0,0,1,.6.246.839.839,0,0,1,.249.6v4.509h4.507a.838.838,0,0,1,.6.246.842.842,0,0,1,.249.6.845.845,0,0,1-.845.842h-4.507v4.509a.845.845,0,0,1-.845.842A.835.835,0,0,1-10550.051,1539.493Z"
                      transform="translate(10555.651 -1527.35)" fill="#ff661c" />
                  </svg>
                  <span> Option Valeur à neuf</span>
                </div>

              </div>

              <div class="row p-1 " style="background-color: #D8DEE4 " *ngIf="excessGarantie">
                <div class="col-sm-10 " style="white-space: nowrap;">
                  <svg xmlns="http://www.w3.org/2000/svg" width="12.393" height="12.39" viewBox="0 0 12.393 12.39">
                    <path id="Union_22" data-name="Union 22"
                      d="M-10550.051,1539.493a.837.837,0,0,1-.247-.6v-4.509h-4.509a.838.838,0,0,1-.6-.246.839.839,0,0,1-.249-.6.846.846,0,0,1,.845-.845h4.509v-4.509a.845.845,0,0,1,.843-.842.838.838,0,0,1,.6.246.839.839,0,0,1,.249.6v4.509h4.507a.838.838,0,0,1,.6.246.842.842,0,0,1,.249.6.845.845,0,0,1-.845.842h-4.507v4.509a.845.845,0,0,1-.845.842A.835.835,0,0,1-10550.051,1539.493Z"
                      transform="translate(10555.651 -1527.35)" fill="#ff661c" />
                  </svg>
                  <span> Option Franchise 0% </span>
                </div>

              </div>

              <div class="row mt-2 text-center p-0">
                <p>Voir plus de détails et la liste complète des exclusions dans nos <u
                    onclick="window.open('assets/images/berlishCG.pdf', '_blank')">CG</u> et <u
                    onclick="window.open('assets/images/ipid.pdf', '_blank')">IPID</u></p>
              </div>
            </div>
            <div class="col-xl-6 col-lg-12 col-md-12  pt-xl-4 pt-md-1 pb-3 text-white text-center" id="devis">
              <h3 class="text-white devisPrixBold  "
                [ngClass]="{'mt-md-5': nboption===3 || nboption===2  ,'mt-md-4': nboption===1  || nboption===0  }">
                {{garantieMois}}€ /mois </h3>
              <label class="devisPrixRegular">{{garantieYear}}€ /an </label><br />
              <label class="devisPrixRegular"> + frais de dossier de {{fraisDossier}}€</label>
            </div>
          </div>
        </div>
      </div>




      <div class="row m-3" *ngIf="dommageAndTheft">
        <div class="col-xl-4 p-0 col-lg-12  col-md-12  justify-content-center text-center " id="imagefirst">

          <div class="col-lg-12 col-md-12 "
            [ngClass]="{'option3': nboption===3 ,'option2': nboption===2 ,'option1': nboption===1 ,'mt-5': nboption===0 }">


            <svg xmlns="http://www.w3.org/2000/svg" width="178.002" height="71.455" viewBox="0 0 178.002 71.455">
              <g id="Groupe_10019" data-name="Groupe 10019" transform="translate(-701 -665.546)">
                <g id="Groupe_10016" data-name="Groupe 10016" transform="translate(-144.743 298.607)">
                  <g id="Groupe_10008" data-name="Groupe 10008" transform="translate(860.138 367.063)">
                    <path id="Tracé_7325" data-name="Tracé 7325"
                      d="M942.032,495.493H894.3V465.116a8.679,8.679,0,0,1,8.68-8.678h30.376a8.679,8.679,0,0,1,8.68,8.678v30.377Zm0,0"
                      transform="translate(-892.264 -426.197)" fill="#2c4f6c" />
                    <path id="Tracé_7326" data-name="Tracé 7326"
                      d="M960.691,486.425a4.339,4.339,0,1,0-6.749,3.61l-1.93,13.749h8.678l-1.93-13.749a4.336,4.336,0,0,0,1.93-3.61Zm0,0"
                      transform="translate(-930.45 -443.167)" fill="#db6b5e" />
                    <path id="Tracé_7327" data-name="Tracé 7327"
                      d="M931.41,395.465V377.777A10.725,10.725,0,0,0,920.7,367.063H907.678a10.725,10.725,0,0,0-10.713,10.714v17.688a10.731,10.731,0,0,0-8.68,10.518v30.376a2.034,2.034,0,0,0,2.034,2.034h47.734a2.033,2.033,0,0,0,2.034-2.034V405.982a10.729,10.729,0,0,0-8.679-10.517Zm-23.732-24.334H920.7a6.653,6.653,0,0,1,6.644,6.646V395.27H901.034V377.777a6.652,6.652,0,0,1,6.644-6.646Zm28.343,63.194H892.354V405.982A6.652,6.652,0,0,1,899,399.338h30.377a6.653,6.653,0,0,1,6.645,6.644Zm0,0"
                      transform="translate(-888.286 -367.063)" fill="#2c4f6c" />
                    <path id="Tracé_7328" data-name="Tracé 7328"
                      d="M952.374,476.074a6.374,6.374,0,0,0-4.58,10.807l-1.774,12.642a2.054,2.054,0,0,0,2.015,2.317h8.678a2.054,2.054,0,0,0,2.014-2.317l-1.774-12.642a6.374,6.374,0,0,0-4.58-10.807Zm.4,10.264,1.6,11.433h-4l1.6-11.433a2.037,2.037,0,0,0-.883-1.973,2.3,2.3,0,1,1,2.558,0,2.032,2.032,0,0,0-.884,1.973Zm0,0"
                      transform="translate(-926.473 -439.189)" />
                  </g>
                  <g id="Groupe_10010" data-name="Groupe 10010" transform="translate(845.743 403.949)">
                    <rect id="Rectangle_4988" data-name="Rectangle 4988" width="80.592" height="26.264" rx="13.132"
                      transform="translate(0 0)" fill="#8ea0af" />
                    <g id="Groupe_10009" data-name="Groupe 10009" transform="translate(13.655 8.217)">
                      <path id="Tracé_7329" data-name="Tracé 7329"
                        d="M891.266,500.361l1.22,3.755h3.947l-3.193,2.32,1.22,3.754-3.194-2.32-3.193,2.32,1.219-3.754-3.193-2.32h3.947Zm0,0"
                        transform="translate(-886.099 -500.361)" fill="#f0f2f5" />
                      <path id="Tracé_7330" data-name="Tracé 7330"
                        d="M933.575,500.361l1.22,3.755h3.948l-3.193,2.32,1.22,3.754-3.194-2.32-3.193,2.32,1.219-3.754-3.193-2.32h3.948Zm0,0"
                        transform="translate(-914.092 -500.361)" fill="#f0f2f5" />
                      <path id="Tracé_7331" data-name="Tracé 7331"
                        d="M975.885,500.361l1.22,3.755h3.948l-3.193,2.32,1.22,3.754-3.194-2.32-3.193,2.32,1.219-3.754-3.193-2.32h3.948Zm0,0"
                        transform="translate(-942.086 -500.361)" fill="#f0f2f5" />
                      <path id="Tracé_7332" data-name="Tracé 7332"
                        d="M1018.2,500.361l1.22,3.755h3.948l-3.193,2.32,1.22,3.754-3.194-2.32L1015,510.19l1.219-3.754-3.193-2.32h3.948Zm0,0"
                        transform="translate(-970.08 -500.361)" fill="#f0f2f5" />
                    </g>
                  </g>
                </g>
                <g id="Groupe_10018" data-name="Groupe 10018" transform="translate(785 701)">
                  <path id="Tracé_7312" data-name="Tracé 7312"
                    d="M1184.613-237.281H1180.3v-2.975h4.313v-4.462h3.074v4.462H1192v2.975h-4.313v4.462h-3.074Z"
                    transform="translate(-1180.3 244.718)" fill="#ff661c" />
                </g>
                <g id="Groupe_10017" data-name="Groupe 10017" transform="translate(-371.323 282.854)">
                  <path id="Tracé_7343" data-name="Tracé 7343"
                    d="M1218.588,402.813l-6.669-6.67-5.336-2.669-4,4,9.339,9.339-5.336,5.336-9.339-9.339-4,4,2.667,5.336,6.67,6.67,9.339-1.334,21.343,21.345-1.334,9.337,6.67,6.67,5.337,2.669,4-4-9.338-9.337,5.337-5.336,9.338,9.337,4-4-2.668-5.336-6.67-6.67-9.338,1.333-21.344-21.343Zm0,0"
                    transform="translate(-14.49 -7.127)" fill="#2c4f6c" />
                  <g id="Groupe_10013" data-name="Groupe 10013" transform="translate(1216.602 382.692)">
                    <path id="Tracé_7344" data-name="Tracé 7344"
                      d="M1340.312,405.519v-5.031l-4.285-.856a11.187,11.187,0,0,0-.784-1.884l2.426-3.639-3.557-3.557-3.638,2.426a11.2,11.2,0,0,0-1.883-.784l-.856-4.285H1322.7l-.856,4.285a11.2,11.2,0,0,0-1.884.784l-3.638-2.426-3.558,3.557,2.426,3.639a11.2,11.2,0,0,0-.784,1.884l-4.284.856v5.031l4.284.856a11.233,11.233,0,0,0,.784,1.884l-2.426,3.637,3.558,3.558,3.638-2.426a11.233,11.233,0,0,0,1.884.784l.856,4.284h5.031l.856-4.284a11.227,11.227,0,0,0,1.883-.784l3.638,2.426,3.557-3.558-2.426-3.637a11.223,11.223,0,0,0,.784-1.884Zm-15.093,2.515a5.031,5.031,0,1,1,5.031-5.03,5.03,5.03,0,0,1-5.031,5.03Zm0,0"
                      transform="translate(-1308.357 -386.141)" fill="#8ea0af" />
                    <path id="Tracé_7345" data-name="Tracé 7345"
                      d="M1336.258,404.609a9.433,9.433,0,1,0,9.433,9.433,9.433,9.433,0,0,0-9.433-9.433Zm0,15.093a5.66,5.66,0,1,1,5.66-5.66,5.66,5.66,0,0,1-5.66,5.66Zm0,0"
                      transform="translate(-1319.396 -397.18)" fill="#8ea0af" />
                    <path id="Tracé_7346" data-name="Tracé 7346"
                      d="M1341.395,425.976a6.8,6.8,0,1,0-6.8-6.8,6.807,6.807,0,0,0,6.8,6.8Zm0-10.061a3.262,3.262,0,1,1-3.261,3.263,3.267,3.267,0,0,1,3.261-3.263Zm0,0"
                      transform="translate(-1324.533 -402.315)" fill="#2c4f6c" />
                    <path id="Tracé_7347" data-name="Tracé 7347"
                      d="M1306.33,403.8l3.306.661c.026.066.054.132.081.2l-1.87,2.805a1.768,1.768,0,0,0,.221,2.232l3.557,3.557a1.768,1.768,0,0,0,2.232.222l2.806-1.871.2.081.661,3.306a1.769,1.769,0,0,0,1.734,1.422h5.031a1.769,1.769,0,0,0,1.734-1.422l.661-3.306.2-.081,2.807,1.87a1.767,1.767,0,0,0,2.231-.221l3.557-3.557a1.768,1.768,0,0,0,.221-2.232l-1.87-2.806c.028-.064.054-.13.081-.2l3.307-.661a1.769,1.769,0,0,0,1.422-1.734v-5.031a1.769,1.769,0,0,0-1.422-1.734l-3.307-.661c-.026-.065-.054-.131-.081-.2l1.87-2.805a1.768,1.768,0,0,0-.221-2.232l-3.557-3.557a1.768,1.768,0,0,0-2.231-.222l-2.807,1.871c-.064-.028-.13-.054-.2-.082l-.661-3.306a1.77,1.77,0,0,0-1.734-1.422h-5.031a1.769,1.769,0,0,0-1.734,1.422l-.661,3.306-.2.082-2.806-1.871a1.77,1.77,0,0,0-2.232.222l-3.557,3.557a1.768,1.768,0,0,0-.221,2.232l1.87,2.805c-.028.065-.055.131-.081.2l-3.306.661a1.769,1.769,0,0,0-1.422,1.734v5.031a1.769,1.769,0,0,0,1.422,1.734Zm2.116-5.315,2.862-.573a1.764,1.764,0,0,0,1.341-1.208,9.539,9.539,0,0,1,.661-1.586,1.77,1.77,0,0,0-.094-1.8l-1.622-2.432,1.508-1.507,2.432,1.622a1.77,1.77,0,0,0,1.8.094,9.5,9.5,0,0,1,1.587-.661,1.77,1.77,0,0,0,1.208-1.341l.572-2.862h2.131l.573,2.862a1.765,1.765,0,0,0,1.208,1.341,9.5,9.5,0,0,1,1.586.661,1.773,1.773,0,0,0,1.8-.094l2.433-1.622,1.507,1.507-1.621,2.432a1.771,1.771,0,0,0-.094,1.8,9.5,9.5,0,0,1,.661,1.586,1.766,1.766,0,0,0,1.341,1.208l2.862.573v2.131l-2.862.572a1.769,1.769,0,0,0-1.341,1.208,9.462,9.462,0,0,1-.661,1.587,1.769,1.769,0,0,0,.094,1.8l1.621,2.432-1.507,1.508-2.433-1.622a1.768,1.768,0,0,0-1.8-.094,9.56,9.56,0,0,1-1.586.661,1.765,1.765,0,0,0-1.208,1.341l-.573,2.863H1320.7l-.572-2.863a1.767,1.767,0,0,0-1.208-1.341,9.552,9.552,0,0,1-1.587-.661,1.769,1.769,0,0,0-1.8.094l-2.432,1.622-1.508-1.508,1.622-2.432a1.768,1.768,0,0,0,.094-1.8,9.546,9.546,0,0,1-.661-1.587,1.768,1.768,0,0,0-1.341-1.208l-2.862-.572Zm0,0"
                      transform="translate(-1304.908 -382.692)" fill="#2c4f6c" />
                  </g>
                  <path id="Tracé_7348" data-name="Tracé 7348"
                    d="M1255.406,439.928l-2.668-5.336c-.084-.169-7-7.129-7-7.129a1.767,1.767,0,0,0-1.5-.5l-8.463,1.209-20.093-20.094,1.209-8.462a1.771,1.771,0,0,0-.5-1.5s-6.961-6.916-7.13-7l-5.336-2.669a1.769,1.769,0,0,0-2.042.332l-4,4a1.769,1.769,0,0,0,0,2.5l8.087,8.088-2.835,2.834-8.087-8.087a1.769,1.769,0,0,0-2.5,0l-4,4a1.77,1.77,0,0,0-.331,2.042l2.667,5.336c.085.17,7,7.13,7,7.13a1.775,1.775,0,0,0,1.5.5l8.463-1.209,20.094,20.095-1.209,8.462a1.767,1.767,0,0,0,.5,1.5s6.96,6.917,7.13,7l5.336,2.669a1.783,1.783,0,0,0,2.041-.332l4-4a1.785,1.785,0,0,0,0-2.5l-8.087-8.087,2.834-2.835,8.088,8.087a1.785,1.785,0,0,0,2.5,0l4-4a1.769,1.769,0,0,0,.332-2.043Zm-5.584,2.293-8.087-8.088a1.77,1.77,0,0,0-2.5,0l-5.336,5.336a1.77,1.77,0,0,0,0,2.5l8.088,8.087-1.85,1.85-3.94-1.97-5.841-5.842,1.209-8.463a1.774,1.774,0,0,0-.5-1.5l-21.344-21.344a1.77,1.77,0,0,0-1.5-.5l-8.463,1.209-5.841-5.841-1.97-3.94,1.85-1.849,8.087,8.087a1.77,1.77,0,0,0,2.5,0l5.336-5.336a1.77,1.77,0,0,0,0-2.5l-8.087-8.087,1.849-1.849,3.94,1.968,5.841,5.843-1.209,8.462a1.77,1.77,0,0,0,.5,1.5L1233.9,431.3a1.771,1.771,0,0,0,1.5.5l8.463-1.209,5.842,5.841,1.97,3.94Zm0,0"
                    transform="translate(-11.039 -3.68)" fill="#2c4f6c" />
                  <g id="Groupe_10014" data-name="Groupe 10014" transform="translate(1171.323 420.423)">
                    <path id="Tracé_7349" data-name="Tracé 7349"
                      d="M1206.726,516.837v-5.031l-4.284-.856a11.186,11.186,0,0,0-.784-1.884l2.426-3.639-3.558-3.557-3.637,2.426a11.189,11.189,0,0,0-1.884-.784l-.856-4.285h-5.031l-.856,4.285a11.165,11.165,0,0,0-1.884.784l-3.638-2.426-3.558,3.557,2.426,3.639a11.187,11.187,0,0,0-.784,1.884l-4.285.856v5.031l4.285.856a11.223,11.223,0,0,0,.784,1.884l-2.426,3.637,3.558,3.558,3.638-2.426a11.2,11.2,0,0,0,1.884.784l.856,4.284h5.031l.856-4.284a11.226,11.226,0,0,0,1.884-.784l3.637,2.426,3.558-3.558-2.426-3.637a11.222,11.222,0,0,0,.784-1.884Zm-15.093,2.515a5.031,5.031,0,1,1,5.031-5.03,5.03,5.03,0,0,1-5.031,5.03Zm0,0"
                      transform="translate(-1174.772 -497.459)" fill="#8ea0af" />
                    <path id="Tracé_7350" data-name="Tracé 7350"
                      d="M1202.673,515.927a9.433,9.433,0,1,0,9.433,9.433,9.433,9.433,0,0,0-9.433-9.433Zm0,15.093a5.66,5.66,0,1,1,5.66-5.66,5.66,5.66,0,0,1-5.66,5.66Zm0,0"
                      transform="translate(-1185.811 -508.498)" fill="#8ea0af" />
                    <path id="Tracé_7351" data-name="Tracé 7351"
                      d="M1207.81,537.294a6.8,6.8,0,1,0-6.8-6.8,6.807,6.807,0,0,0,6.8,6.8Zm0-10.061a3.262,3.262,0,1,1-3.262,3.263,3.267,3.267,0,0,1,3.262-3.263Zm0,0"
                      transform="translate(-1190.948 -513.633)" fill="#2c4f6c" />
                    <path id="Tracé_7352" data-name="Tracé 7352"
                      d="M1172.745,515.122l3.306.661q.04.1.082.2l-1.87,2.805a1.769,1.769,0,0,0,.221,2.232l3.557,3.557a1.768,1.768,0,0,0,2.231.222l2.807-1.871.2.081.661,3.306a1.769,1.769,0,0,0,1.734,1.422h5.031a1.769,1.769,0,0,0,1.734-1.422l.661-3.306.2-.081,2.807,1.87a1.768,1.768,0,0,0,2.231-.221l3.557-3.557a1.769,1.769,0,0,0,.221-2.232l-1.87-2.806c.028-.064.054-.13.081-.2l3.307-.661a1.769,1.769,0,0,0,1.422-1.734v-5.031a1.769,1.769,0,0,0-1.422-1.734l-3.307-.661c-.026-.065-.054-.131-.081-.2l1.87-2.805a1.768,1.768,0,0,0-.221-2.232l-3.557-3.557a1.767,1.767,0,0,0-2.231-.222l-2.807,1.871c-.064-.028-.13-.054-.2-.082l-.661-3.306a1.77,1.77,0,0,0-1.734-1.422h-5.031a1.769,1.769,0,0,0-1.734,1.422l-.661,3.306-.2.082-2.807-1.871a1.769,1.769,0,0,0-2.231.222l-3.557,3.557a1.768,1.768,0,0,0-.221,2.232l1.87,2.805c-.028.065-.055.131-.082.2l-3.306.661a1.769,1.769,0,0,0-1.422,1.734v5.031a1.769,1.769,0,0,0,1.422,1.734Zm2.116-5.315,2.862-.573a1.765,1.765,0,0,0,1.341-1.208,9.546,9.546,0,0,1,.661-1.586,1.77,1.77,0,0,0-.094-1.8l-1.622-2.432,1.508-1.507,2.432,1.622a1.77,1.77,0,0,0,1.8.094,9.47,9.47,0,0,1,1.587-.661,1.77,1.77,0,0,0,1.208-1.341l.572-2.862h2.131l.573,2.862a1.765,1.765,0,0,0,1.208,1.341,9.465,9.465,0,0,1,1.586.661,1.773,1.773,0,0,0,1.8-.094l2.433-1.622,1.507,1.507-1.622,2.432a1.77,1.77,0,0,0-.094,1.8,9.477,9.477,0,0,1,.661,1.586,1.767,1.767,0,0,0,1.342,1.208l2.862.573v2.131l-2.862.572a1.769,1.769,0,0,0-1.342,1.208,9.44,9.44,0,0,1-.661,1.587,1.769,1.769,0,0,0,.094,1.8l1.622,2.432-1.507,1.508-2.433-1.622a1.767,1.767,0,0,0-1.8-.094,9.551,9.551,0,0,1-1.586.661,1.765,1.765,0,0,0-1.208,1.341l-.573,2.863h-2.131l-.572-2.863a1.768,1.768,0,0,0-1.208-1.341,9.556,9.556,0,0,1-1.587-.661,1.768,1.768,0,0,0-1.8.094l-2.432,1.622-1.508-1.508,1.622-2.432a1.769,1.769,0,0,0,.094-1.8,9.567,9.567,0,0,1-.661-1.587,1.77,1.77,0,0,0-1.341-1.208l-2.862-.572Zm0,0"
                      transform="translate(-1171.323 -494.01)" fill="#2c4f6c" />
                  </g>
                </g>
              </g>
            </svg>
          </div>
          <div class="col-lg-12 col-md-12 mt-3">
            <p>Dommage & Vol</p>
          </div>
        </div>
        <div class="col-xl-8 col-lg-12 col-md-12">
          <div class="row">
            <div class="col-xl-6 col-lg-12 col-md-12" style="background-color: #D8DEE4">
              <div class="row p-1 ">
                <div class="col-sm-10 " style="white-space: nowrap;">

                  <svg xmlns="http://www.w3.org/2000/svg" width="11.834" height="9.571" viewBox="0 0 11.834 9.571">
                    <path id="Union_18" data-name="Union 18"
                      d="M-4388.536,1404.571a2.4,2.4,0,0,1-2.465-2.326v-2.325a2.4,2.4,0,0,1,2.465-2.326h1.337l.943-1.8A1.5,1.5,0,0,1-4385,1395l.091,0a1.57,1.57,0,0,1,.458.068,1.422,1.422,0,0,1,1.039,1.35,1.363,1.363,0,0,1-.02.225l-.166.949h1.969a2.526,2.526,0,0,1,1.858.8,2.251,2.251,0,0,1,.607,1.522,2.258,2.258,0,0,1-.024.329l-.348,2.326a2.434,2.434,0,0,1-2.44,2Zm6.557-.931a1.459,1.459,0,0,0,1.461-1.2l.349-2.326a1.517,1.517,0,0,0,.015-.188,1.351,1.351,0,0,0-.363-.922,1.517,1.517,0,0,0-1.116-.48h-2.547a.507.507,0,0,1-.376-.164.452.452,0,0,1-.117-.3.4.4,0,0,1,.006-.076l.26-1.489a.43.43,0,0,0,.007-.079.462.462,0,0,0-.123-.312.541.541,0,0,0-.374-.16h-.038l-.04,0a.53.53,0,0,0-.4.253l-1.079,2.063a.466.466,0,0,1-.113.136v5.241Zm-6.557,0h.986v-5.117h-.986a1.439,1.439,0,0,0-1.479,1.4v2.325A1.439,1.439,0,0,0-4388.536,1403.64Z"
                      transform="translate(4391 -1395)" fill="#ff661c" />
                  </svg>
                  <span>Casse accidentelle/Incendie</span>
                </div>

              </div>

              <div class="row p-1 " style="background-color: #D8DEE4 ">
                <div class="col-sm-10 " style="white-space: nowrap;">

                  <svg xmlns="http://www.w3.org/2000/svg" width="11.834" height="9.571" viewBox="0 0 11.834 9.571">
                    <path id="Union_18" data-name="Union 18"
                      d="M-4388.536,1404.571a2.4,2.4,0,0,1-2.465-2.326v-2.325a2.4,2.4,0,0,1,2.465-2.326h1.337l.943-1.8A1.5,1.5,0,0,1-4385,1395l.091,0a1.57,1.57,0,0,1,.458.068,1.422,1.422,0,0,1,1.039,1.35,1.363,1.363,0,0,1-.02.225l-.166.949h1.969a2.526,2.526,0,0,1,1.858.8,2.251,2.251,0,0,1,.607,1.522,2.258,2.258,0,0,1-.024.329l-.348,2.326a2.434,2.434,0,0,1-2.44,2Zm6.557-.931a1.459,1.459,0,0,0,1.461-1.2l.349-2.326a1.517,1.517,0,0,0,.015-.188,1.351,1.351,0,0,0-.363-.922,1.517,1.517,0,0,0-1.116-.48h-2.547a.507.507,0,0,1-.376-.164.452.452,0,0,1-.117-.3.4.4,0,0,1,.006-.076l.26-1.489a.43.43,0,0,0,.007-.079.462.462,0,0,0-.123-.312.541.541,0,0,0-.374-.16h-.038l-.04,0a.53.53,0,0,0-.4.253l-1.079,2.063a.466.466,0,0,1-.113.136v5.241Zm-6.557,0h.986v-5.117h-.986a1.439,1.439,0,0,0-1.479,1.4v2.325A1.439,1.439,0,0,0-4388.536,1403.64Z"
                      transform="translate(4391 -1395)" fill="#ff661c" />
                  </svg>
                  <span>Jusqu’à 8 000€ de garanties</span>
                </div>

              </div>

              <div class="row p-1 ">
                <div class="col-sm-10 " style="white-space: nowrap;">

                  <svg xmlns="http://www.w3.org/2000/svg" width="11.834" height="9.571" viewBox="0 0 11.834 9.571">
                    <path id="Union_18" data-name="Union 18"
                      d="M-4388.536,1404.571a2.4,2.4,0,0,1-2.465-2.326v-2.325a2.4,2.4,0,0,1,2.465-2.326h1.337l.943-1.8A1.5,1.5,0,0,1-4385,1395l.091,0a1.57,1.57,0,0,1,.458.068,1.422,1.422,0,0,1,1.039,1.35,1.363,1.363,0,0,1-.02.225l-.166.949h1.969a2.526,2.526,0,0,1,1.858.8,2.251,2.251,0,0,1,.607,1.522,2.258,2.258,0,0,1-.024.329l-.348,2.326a2.434,2.434,0,0,1-2.44,2Zm6.557-.931a1.459,1.459,0,0,0,1.461-1.2l.349-2.326a1.517,1.517,0,0,0,.015-.188,1.351,1.351,0,0,0-.363-.922,1.517,1.517,0,0,0-1.116-.48h-2.547a.507.507,0,0,1-.376-.164.452.452,0,0,1-.117-.3.4.4,0,0,1,.006-.076l.26-1.489a.43.43,0,0,0,.007-.079.462.462,0,0,0-.123-.312.541.541,0,0,0-.374-.16h-.038l-.04,0a.53.53,0,0,0-.4.253l-1.079,2.063a.466.466,0,0,1-.113.136v5.241Zm-6.557,0h.986v-5.117h-.986a1.439,1.439,0,0,0-1.479,1.4v2.325A1.439,1.439,0,0,0-4388.536,1403.64Z"
                      transform="translate(4391 -1395)" fill="#ff661c" />
                  </svg>
                  <span>Remise en état ou Remplacement du vélo</span>
                </div>

              </div>
              <div class="row p-1 " style="background-color: #D8DEE4 ">
                <div class="col-sm-10 " style="white-space: nowrap;">

                  <svg xmlns="http://www.w3.org/2000/svg" width="11.834" height="9.571" viewBox="0 0 11.834 9.571">
                    <path id="Union_18" data-name="Union 18"
                      d="M-4388.536,1404.571a2.4,2.4,0,0,1-2.465-2.326v-2.325a2.4,2.4,0,0,1,2.465-2.326h1.337l.943-1.8A1.5,1.5,0,0,1-4385,1395l.091,0a1.57,1.57,0,0,1,.458.068,1.422,1.422,0,0,1,1.039,1.35,1.363,1.363,0,0,1-.02.225l-.166.949h1.969a2.526,2.526,0,0,1,1.858.8,2.251,2.251,0,0,1,.607,1.522,2.258,2.258,0,0,1-.024.329l-.348,2.326a2.434,2.434,0,0,1-2.44,2Zm6.557-.931a1.459,1.459,0,0,0,1.461-1.2l.349-2.326a1.517,1.517,0,0,0,.015-.188,1.351,1.351,0,0,0-.363-.922,1.517,1.517,0,0,0-1.116-.48h-2.547a.507.507,0,0,1-.376-.164.452.452,0,0,1-.117-.3.4.4,0,0,1,.006-.076l.26-1.489a.43.43,0,0,0,.007-.079.462.462,0,0,0-.123-.312.541.541,0,0,0-.374-.16h-.038l-.04,0a.53.53,0,0,0-.4.253l-1.079,2.063a.466.466,0,0,1-.113.136v5.241Zm-6.557,0h.986v-5.117h-.986a1.439,1.439,0,0,0-1.479,1.4v2.325A1.439,1.439,0,0,0-4388.536,1403.64Z"
                      transform="translate(4391 -1395)" fill="#ff661c" />
                  </svg>
                  <span>Effraction & agression</span>
                </div>

              </div>

              <div class="row p-1 " style="background-color: #D8DEE4 " *ngIf="accidentGarantie">
                <div class="col-sm-10 " style="white-space: nowrap;">

                  <svg xmlns="http://www.w3.org/2000/svg" width="12.393" height="12.39" viewBox="0 0 12.393 12.39">
                    <path id="Union_22" data-name="Union 22"
                      d="M-10550.051,1539.493a.837.837,0,0,1-.247-.6v-4.509h-4.509a.838.838,0,0,1-.6-.246.839.839,0,0,1-.249-.6.846.846,0,0,1,.845-.845h4.509v-4.509a.845.845,0,0,1,.843-.842.838.838,0,0,1,.6.246.839.839,0,0,1,.249.6v4.509h4.507a.838.838,0,0,1,.6.246.842.842,0,0,1,.249.6.845.845,0,0,1-.845.842h-4.507v4.509a.845.845,0,0,1-.845.842A.835.835,0,0,1-10550.051,1539.493Z"
                      transform="translate(10555.651 -1527.35)" fill="#ff661c" />
                  </svg>
                  <span> Option Individuelle accident </span>
                </div>

              </div>

              <div class="row p-1 " style="background-color: #D8DEE4 " *ngIf="valeurNeufGarantie">
                <div class="col-sm-10 " style="white-space: nowrap;">

                  <svg xmlns="http://www.w3.org/2000/svg" width="12.393" height="12.39" viewBox="0 0 12.393 12.39">
                    <path id="Union_22" data-name="Union 22"
                      d="M-10550.051,1539.493a.837.837,0,0,1-.247-.6v-4.509h-4.509a.838.838,0,0,1-.6-.246.839.839,0,0,1-.249-.6.846.846,0,0,1,.845-.845h4.509v-4.509a.845.845,0,0,1,.843-.842.838.838,0,0,1,.6.246.839.839,0,0,1,.249.6v4.509h4.507a.838.838,0,0,1,.6.246.842.842,0,0,1,.249.6.845.845,0,0,1-.845.842h-4.507v4.509a.845.845,0,0,1-.845.842A.835.835,0,0,1-10550.051,1539.493Z"
                      transform="translate(10555.651 -1527.35)" fill="#ff661c" />
                  </svg>
                  <span> Option Valeur à neuf</span>
                </div>

              </div>

              <div class="row p-1 " style="background-color: #D8DEE4 " *ngIf="excessGarantie">
                <div class="col-sm-10 " style="white-space: nowrap;">
                  <svg xmlns="http://www.w3.org/2000/svg" width="12.393" height="12.39" viewBox="0 0 12.393 12.39">
                    <path id="Union_22" data-name="Union 22"
                      d="M-10550.051,1539.493a.837.837,0,0,1-.247-.6v-4.509h-4.509a.838.838,0,0,1-.6-.246.839.839,0,0,1-.249-.6.846.846,0,0,1,.845-.845h4.509v-4.509a.845.845,0,0,1,.843-.842.838.838,0,0,1,.6.246.839.839,0,0,1,.249.6v4.509h4.507a.838.838,0,0,1,.6.246.842.842,0,0,1,.249.6.845.845,0,0,1-.845.842h-4.507v4.509a.845.845,0,0,1-.845.842A.835.835,0,0,1-10550.051,1539.493Z"
                      transform="translate(10555.651 -1527.35)" fill="#ff661c" />
                  </svg>
                  <span> Option Franchise 0% </span>
                </div>

              </div>

        
              <div class="row mt-2 text-center p-0 ">
                <p>Voir plus de détails et la liste complète des exclusions dans nos <u
                    onclick="window.open('assets/images/berlishCG.pdf', '_blank')">CG</u> et <u
                    onclick="window.open('assets/images/ipid.pdf', '_blank')">IPID</u></p>
              </div>
            </div>

            <div class="col-xl-6 col-lg-12 col-md-12  pt-xl-4 pb-3 text-white text-center" id="devis">

              <h3 class="text-white  devisPrixBold "
                [ngClass]="{'mt-md-5': nboption===3 || nboption===2  ,'mt-md-4': nboption===1  || nboption===0  }">
                {{garantieMois}}€ /mois </h3>
              <label class="devisPrixRegular">{{garantieYear}}€ /an </label><br />
              <label class="devisPrixRegular">+ frais de dossier de {{fraisDossier}}€</label>
            </div>
          </div>
        </div>
      </div>




      <div class="row m-3"  *ngIf="isAssistance">
        <div class="col-xl-4 p-0 col-lg-12  col-md-12  justify-content-center text-center " id="imagefirst">

          <div class="col-lg-12 col-md-12  mt-3">

            <svg xmlns="http://www.w3.org/2000/svg" width="63.488" height="63.488" viewBox="0 0 63.488 63.488">
              <g id="Groupe_10067" data-name="Groupe 10067" transform="translate(-525.903 -371.787)">
                <path id="Tracé_7382" data-name="Tracé 7382" d="M624.244,586.213H611.632v-5.821h12.612a2.91,2.91,0,1,1,0,5.821Zm0,0" transform="translate(-62.717 -152.609)" fill="#8ea0af"/>
                <path id="Tracé_7383" data-name="Tracé 7383" d="M599.432,468.018h-6.665c3.338-3.083,8.12-8.476,8.12-14.066a7.276,7.276,0,1,0-14.552,0,1.455,1.455,0,0,0,2.911,0,4.365,4.365,0,0,1,8.731,0c0,6.924-9.972,14.271-10.072,14.345a1.455,1.455,0,0,0,.856,2.632h10.671a1.455,1.455,0,0,0,0-2.91Zm0,0" transform="translate(-44.21 -54.786)" fill="#ff661c"/>
                <path id="Tracé_7384" data-name="Tracé 7384" d="M662.81,464.137h-3.4V448.13a1.455,1.455,0,0,0-2.757-.651l-8.731,17.462a1.455,1.455,0,0,0,1.3,2.106H656.5v2.425a1.455,1.455,0,1,0,2.91,0v-2.425h3.4a1.455,1.455,0,0,0,0-2.91Zm-6.305,0h-4.921l4.921-9.842Zm0,0" transform="translate(-89.157 -54.786)" fill="#ff661c"/>
                <path id="Tracé_7385" data-name="Tracé 7385" d="M586.448,400.027a5.538,5.538,0,0,0-5.519-4.958h-.27V383.158a11.384,11.384,0,0,0-11.372-11.371H546.005a11.384,11.384,0,0,0-11.371,11.371v11.911h-.27a5.538,5.538,0,0,0-5.518,4.958,8.462,8.462,0,0,0,0,12.828,5.532,5.532,0,0,0,5.518,4.958h.27v9a5.557,5.557,0,0,0,5.55,5.551h7.061v1.24a1.673,1.673,0,0,0,1.671,1.671h12.611a4.581,4.581,0,0,0,0-9.162H548.915a1.672,1.672,0,0,0-1.67,1.671v1.24h-7.061a2.213,2.213,0,0,1-2.21-2.211V383.158a8.04,8.04,0,0,1,8.031-8.031h23.283a8.04,8.04,0,0,1,8.031,8.031v32.984a1.673,1.673,0,0,0,1.67,1.671h1.94a5.538,5.538,0,0,0,5.519-4.96,8.459,8.459,0,0,0,0-12.825Zm-35.862,31.906v-2.48h10.941a1.24,1.24,0,1,1,0,2.48Z" fill="#2c4f6c"/>
              </g>
            </svg>
          </div>
          <div class="col-lg-12 col-md-12 mt-3">
            <p>Assistance</p>
          </div>
        </div>
        <div class="col-xl-8 col-lg-12 col-md-12">
          <div class="row">
            <div class="col-xl-6 col-lg-12 col-md-12" style="background-color: #D8DEE4">
              <div class="row p-1 ">
                <div class="col-sm-10 " style="white-space: nowrap;">

                  <svg xmlns="http://www.w3.org/2000/svg" width="11.834" height="9.571" viewBox="0 0 11.834 9.571">
                    <path id="Union_18" data-name="Union 18"
                      d="M-4388.536,1404.571a2.4,2.4,0,0,1-2.465-2.326v-2.325a2.4,2.4,0,0,1,2.465-2.326h1.337l.943-1.8A1.5,1.5,0,0,1-4385,1395l.091,0a1.57,1.57,0,0,1,.458.068,1.422,1.422,0,0,1,1.039,1.35,1.363,1.363,0,0,1-.02.225l-.166.949h1.969a2.526,2.526,0,0,1,1.858.8,2.251,2.251,0,0,1,.607,1.522,2.258,2.258,0,0,1-.024.329l-.348,2.326a2.434,2.434,0,0,1-2.44,2Zm6.557-.931a1.459,1.459,0,0,0,1.461-1.2l.349-2.326a1.517,1.517,0,0,0,.015-.188,1.351,1.351,0,0,0-.363-.922,1.517,1.517,0,0,0-1.116-.48h-2.547a.507.507,0,0,1-.376-.164.452.452,0,0,1-.117-.3.4.4,0,0,1,.006-.076l.26-1.489a.43.43,0,0,0,.007-.079.462.462,0,0,0-.123-.312.541.541,0,0,0-.374-.16h-.038l-.04,0a.53.53,0,0,0-.4.253l-1.079,2.063a.466.466,0,0,1-.113.136v5.241Zm-6.557,0h.986v-5.117h-.986a1.439,1.439,0,0,0-1.479,1.4v2.325A1.439,1.439,0,0,0-4388.536,1403.64Z"
                      transform="translate(4391 -1395)" fill="#ff661c" />
                  </svg>
                  <span>Rapatriemment au domicile </span>
                </div>

              </div>

              <div class="row p-1 " style="background-color: #D8DEE4 ">
                <div class="col-sm-10 " style="white-space: nowrap;">

                  <svg xmlns="http://www.w3.org/2000/svg" width="11.834" height="9.571" viewBox="0 0 11.834 9.571">
                    <path id="Union_18" data-name="Union 18"
                      d="M-4388.536,1404.571a2.4,2.4,0,0,1-2.465-2.326v-2.325a2.4,2.4,0,0,1,2.465-2.326h1.337l.943-1.8A1.5,1.5,0,0,1-4385,1395l.091,0a1.57,1.57,0,0,1,.458.068,1.422,1.422,0,0,1,1.039,1.35,1.363,1.363,0,0,1-.02.225l-.166.949h1.969a2.526,2.526,0,0,1,1.858.8,2.251,2.251,0,0,1,.607,1.522,2.258,2.258,0,0,1-.024.329l-.348,2.326a2.434,2.434,0,0,1-2.44,2Zm6.557-.931a1.459,1.459,0,0,0,1.461-1.2l.349-2.326a1.517,1.517,0,0,0,.015-.188,1.351,1.351,0,0,0-.363-.922,1.517,1.517,0,0,0-1.116-.48h-2.547a.507.507,0,0,1-.376-.164.452.452,0,0,1-.117-.3.4.4,0,0,1,.006-.076l.26-1.489a.43.43,0,0,0,.007-.079.462.462,0,0,0-.123-.312.541.541,0,0,0-.374-.16h-.038l-.04,0a.53.53,0,0,0-.4.253l-1.079,2.063a.466.466,0,0,1-.113.136v5.241Zm-6.557,0h.986v-5.117h-.986a1.439,1.439,0,0,0-1.479,1.4v2.325A1.439,1.439,0,0,0-4388.536,1403.64Z"
                      transform="translate(4391 -1395)" fill="#ff661c" />
                  </svg>
                  <span>Dépannage/ Remorquage </span>
                </div>

              </div>

              <div class="row p-1 ">
                <div class="col-sm-10 " style="white-space: nowrap;">

                  <svg xmlns="http://www.w3.org/2000/svg" width="11.834" height="9.571" viewBox="0 0 11.834 9.571">
                    <path id="Union_18" data-name="Union 18"
                      d="M-4388.536,1404.571a2.4,2.4,0,0,1-2.465-2.326v-2.325a2.4,2.4,0,0,1,2.465-2.326h1.337l.943-1.8A1.5,1.5,0,0,1-4385,1395l.091,0a1.57,1.57,0,0,1,.458.068,1.422,1.422,0,0,1,1.039,1.35,1.363,1.363,0,0,1-.02.225l-.166.949h1.969a2.526,2.526,0,0,1,1.858.8,2.251,2.251,0,0,1,.607,1.522,2.258,2.258,0,0,1-.024.329l-.348,2.326a2.434,2.434,0,0,1-2.44,2Zm6.557-.931a1.459,1.459,0,0,0,1.461-1.2l.349-2.326a1.517,1.517,0,0,0,.015-.188,1.351,1.351,0,0,0-.363-.922,1.517,1.517,0,0,0-1.116-.48h-2.547a.507.507,0,0,1-.376-.164.452.452,0,0,1-.117-.3.4.4,0,0,1,.006-.076l.26-1.489a.43.43,0,0,0,.007-.079.462.462,0,0,0-.123-.312.541.541,0,0,0-.374-.16h-.038l-.04,0a.53.53,0,0,0-.4.253l-1.079,2.063a.466.466,0,0,1-.113.136v5.241Zm-6.557,0h.986v-5.117h-.986a1.439,1.439,0,0,0-1.479,1.4v2.325A1.439,1.439,0,0,0-4388.536,1403.64Z"
                      transform="translate(4391 -1395)" fill="#ff661c" />
                  </svg>
                  <span>Récupération d’un double des clés</span>
                </div>

              </div>
              <div class="row mt-2 text-center p-0 ">
                <p>Voir plus de détails et la liste complète des exclusions dans nos <u
                    (click)="goToNI()">CG</u> et <u
                    (click)="goToIPID()">IPID</u></p>
              </div>
            </div>
            <div class="col-xl-6 col-lg-12 col-md-12  pt-xl-5 pb-3 text-white text-center" id="devis">
              <h3 class="text-white  devisPrixBold ">
                2,17 € /mois </h3>
              <label class="devisPrixRegular">26 € /an </label><br/>
            </div>
          </div>
        </div>
      </div>


      <div class="row m-3">
        <div class="row mt-md-2  mb-md-3  titre">Votre assureur</div>

        <div class=" p-md-3" style="background-color: #f7f7f7; border-radius: 15px;">
          <div class="row mt-md-2 ">
            <div class="col-2 col-md-2 col-sm-1 text-right">
              <svg xmlns="http://www.w3.org/2000/svg" width="11.834" height="9.571" viewBox="0 0 11.834 9.571">
                <path id="Union_18" data-name="Union 18"
                  d="M-4388.536,1404.571a2.4,2.4,0,0,1-2.465-2.326v-2.325a2.4,2.4,0,0,1,2.465-2.326h1.337l.943-1.8A1.5,1.5,0,0,1-4385,1395l.091,0a1.57,1.57,0,0,1,.458.068,1.422,1.422,0,0,1,1.039,1.35,1.363,1.363,0,0,1-.02.225l-.166.949h1.969a2.526,2.526,0,0,1,1.858.8,2.251,2.251,0,0,1,.607,1.522,2.258,2.258,0,0,1-.024.329l-.348,2.326a2.434,2.434,0,0,1-2.44,2Zm6.557-.931a1.459,1.459,0,0,0,1.461-1.2l.349-2.326a1.517,1.517,0,0,0,.015-.188,1.351,1.351,0,0,0-.363-.922,1.517,1.517,0,0,0-1.116-.48h-2.547a.507.507,0,0,1-.376-.164.452.452,0,0,1-.117-.3.4.4,0,0,1,.006-.076l.26-1.489a.43.43,0,0,0,.007-.079.462.462,0,0,0-.123-.312.541.541,0,0,0-.374-.16h-.038l-.04,0a.53.53,0,0,0-.4.253l-1.079,2.063a.466.466,0,0,1-.113.136v5.241Zm-6.557,0h.986v-5.117h-.986a1.439,1.439,0,0,0-1.479,1.4v2.325A1.439,1.439,0,0,0-4388.536,1403.64Z"
                  transform="translate(4391 -1395)" fill="#ff661c" />
              </svg>
            </div>
            <div class=" col-5 col-md-5 col-sm-5  text-left-sm  labelValue">Assureur</div>

            <div class=" col-5 col-md-5  col-sm-5 text-left-sm  labelKey "> WAKAM </div>
          </div>

          <div class="row mt-md-2">
            <div class="col-2 col-md-2 col-sm-1 text-right">

              <svg xmlns="http://www.w3.org/2000/svg" width="11.834" height="9.571" viewBox="0 0 11.834 9.571">
                <path id="Union_18" data-name="Union 18"
                  d="M-4388.536,1404.571a2.4,2.4,0,0,1-2.465-2.326v-2.325a2.4,2.4,0,0,1,2.465-2.326h1.337l.943-1.8A1.5,1.5,0,0,1-4385,1395l.091,0a1.57,1.57,0,0,1,.458.068,1.422,1.422,0,0,1,1.039,1.35,1.363,1.363,0,0,1-.02.225l-.166.949h1.969a2.526,2.526,0,0,1,1.858.8,2.251,2.251,0,0,1,.607,1.522,2.258,2.258,0,0,1-.024.329l-.348,2.326a2.434,2.434,0,0,1-2.44,2Zm6.557-.931a1.459,1.459,0,0,0,1.461-1.2l.349-2.326a1.517,1.517,0,0,0,.015-.188,1.351,1.351,0,0,0-.363-.922,1.517,1.517,0,0,0-1.116-.48h-2.547a.507.507,0,0,1-.376-.164.452.452,0,0,1-.117-.3.4.4,0,0,1,.006-.076l.26-1.489a.43.43,0,0,0,.007-.079.462.462,0,0,0-.123-.312.541.541,0,0,0-.374-.16h-.038l-.04,0a.53.53,0,0,0-.4.253l-1.079,2.063a.466.466,0,0,1-.113.136v5.241Zm-6.557,0h.986v-5.117h-.986a1.439,1.439,0,0,0-1.479,1.4v2.325A1.439,1.439,0,0,0-4388.536,1403.64Z"
                  transform="translate(4391 -1395)" fill="#ff661c" />
              </svg>
            </div>
            <div class="col-5 col-md-5  col-sm-5  text-left-sm  labelValue ">Date d'effet de contrat </div>

            <div class="col-5 col-md-5 col-sm-5  text-left-sm  labelKey ">{{dateEffet  | date: 'dd/MM/yyyy' }}</div>
          </div>

          <div class="row mt-md-2">
            <div class="col-2 col-md-2 col-sm-1 text-right">

              <svg xmlns="http://www.w3.org/2000/svg" width="11.834" height="9.571" viewBox="0 0 11.834 9.571">
                <path id="Union_18" data-name="Union 18"
                  d="M-4388.536,1404.571a2.4,2.4,0,0,1-2.465-2.326v-2.325a2.4,2.4,0,0,1,2.465-2.326h1.337l.943-1.8A1.5,1.5,0,0,1-4385,1395l.091,0a1.57,1.57,0,0,1,.458.068,1.422,1.422,0,0,1,1.039,1.35,1.363,1.363,0,0,1-.02.225l-.166.949h1.969a2.526,2.526,0,0,1,1.858.8,2.251,2.251,0,0,1,.607,1.522,2.258,2.258,0,0,1-.024.329l-.348,2.326a2.434,2.434,0,0,1-2.44,2Zm6.557-.931a1.459,1.459,0,0,0,1.461-1.2l.349-2.326a1.517,1.517,0,0,0,.015-.188,1.351,1.351,0,0,0-.363-.922,1.517,1.517,0,0,0-1.116-.48h-2.547a.507.507,0,0,1-.376-.164.452.452,0,0,1-.117-.3.4.4,0,0,1,.006-.076l.26-1.489a.43.43,0,0,0,.007-.079.462.462,0,0,0-.123-.312.541.541,0,0,0-.374-.16h-.038l-.04,0a.53.53,0,0,0-.4.253l-1.079,2.063a.466.466,0,0,1-.113.136v5.241Zm-6.557,0h.986v-5.117h-.986a1.439,1.439,0,0,0-1.479,1.4v2.325A1.439,1.439,0,0,0-4388.536,1403.64Z"
                  transform="translate(4391 -1395)" fill="#ff661c" />
              </svg>
            </div>
            <div class="col-5 col-md-5 col-sm-5  text-left-sm  labelValue">Durée de vie de contrat </div>

            <div class="col-5 col-md-5 col-sm-5  text-left-sm  labelKey ">1 an renouvelable </div>
          </div>




        </div>
      </div>

      <div class="row m-3">
        <div class="row mt-md-2  mb-md-3  titre">Le souscripteur</div>

        <div class=" p-md-3" style="background-color: #f7f7f7; border-radius: 15px;">
          <div class="row mt-md-2 ">
            <div class="col-2 col-md-2 col-sm-1 text-right">
              <svg xmlns="http://www.w3.org/2000/svg" width="11.834" height="9.571" viewBox="0 0 11.834 9.571">
                <path id="Union_18" data-name="Union 18"
                  d="M-4388.536,1404.571a2.4,2.4,0,0,1-2.465-2.326v-2.325a2.4,2.4,0,0,1,2.465-2.326h1.337l.943-1.8A1.5,1.5,0,0,1-4385,1395l.091,0a1.57,1.57,0,0,1,.458.068,1.422,1.422,0,0,1,1.039,1.35,1.363,1.363,0,0,1-.02.225l-.166.949h1.969a2.526,2.526,0,0,1,1.858.8,2.251,2.251,0,0,1,.607,1.522,2.258,2.258,0,0,1-.024.329l-.348,2.326a2.434,2.434,0,0,1-2.44,2Zm6.557-.931a1.459,1.459,0,0,0,1.461-1.2l.349-2.326a1.517,1.517,0,0,0,.015-.188,1.351,1.351,0,0,0-.363-.922,1.517,1.517,0,0,0-1.116-.48h-2.547a.507.507,0,0,1-.376-.164.452.452,0,0,1-.117-.3.4.4,0,0,1,.006-.076l.26-1.489a.43.43,0,0,0,.007-.079.462.462,0,0,0-.123-.312.541.541,0,0,0-.374-.16h-.038l-.04,0a.53.53,0,0,0-.4.253l-1.079,2.063a.466.466,0,0,1-.113.136v5.241Zm-6.557,0h.986v-5.117h-.986a1.439,1.439,0,0,0-1.479,1.4v2.325A1.439,1.439,0,0,0-4388.536,1403.64Z"
                  transform="translate(4391 -1395)" fill="#ff661c" />
              </svg>

            </div>
            <div class="col-5 col-md-5 col-sm-5  text-left-sm   labelValue">Nom & Prénom</div>

            <div class="col-5 col-md-5 col-sm-5  text-left-sm   labelKey "> {{insuranceinfoData.client.firstName}}
              {{insuranceinfoData.client.lastName}} </div>
          </div>

          <div class="row mt-md-2">
            <div class="col-2 col-md-2 col-sm-1 text-right">

              <svg xmlns="http://www.w3.org/2000/svg" width="11.834" height="9.571" viewBox="0 0 11.834 9.571">
                <path id="Union_18" data-name="Union 18"
                  d="M-4388.536,1404.571a2.4,2.4,0,0,1-2.465-2.326v-2.325a2.4,2.4,0,0,1,2.465-2.326h1.337l.943-1.8A1.5,1.5,0,0,1-4385,1395l.091,0a1.57,1.57,0,0,1,.458.068,1.422,1.422,0,0,1,1.039,1.35,1.363,1.363,0,0,1-.02.225l-.166.949h1.969a2.526,2.526,0,0,1,1.858.8,2.251,2.251,0,0,1,.607,1.522,2.258,2.258,0,0,1-.024.329l-.348,2.326a2.434,2.434,0,0,1-2.44,2Zm6.557-.931a1.459,1.459,0,0,0,1.461-1.2l.349-2.326a1.517,1.517,0,0,0,.015-.188,1.351,1.351,0,0,0-.363-.922,1.517,1.517,0,0,0-1.116-.48h-2.547a.507.507,0,0,1-.376-.164.452.452,0,0,1-.117-.3.4.4,0,0,1,.006-.076l.26-1.489a.43.43,0,0,0,.007-.079.462.462,0,0,0-.123-.312.541.541,0,0,0-.374-.16h-.038l-.04,0a.53.53,0,0,0-.4.253l-1.079,2.063a.466.466,0,0,1-.113.136v5.241Zm-6.557,0h.986v-5.117h-.986a1.439,1.439,0,0,0-1.479,1.4v2.325A1.439,1.439,0,0,0-4388.536,1403.64Z"
                  transform="translate(4391 -1395)" fill="#ff661c" />
              </svg>
            </div>
            <div class=" col-5 col-md-5 col-sm-5  text-left-sm   labelValue ">Mobile</div>

            <div class="col-5 col-md-5 col-sm-5  text-left-sm   labelKey "> {{insuranceinfoData.client.mobile }} </div>
          </div>

          <div class="row mt-md-2">
            <div class="col-2 col-md-2 col-sm-1 text-right">

              <svg xmlns="http://www.w3.org/2000/svg" width="11.834" height="9.571" viewBox="0 0 11.834 9.571">
                <path id="Union_18" data-name="Union 18"
                  d="M-4388.536,1404.571a2.4,2.4,0,0,1-2.465-2.326v-2.325a2.4,2.4,0,0,1,2.465-2.326h1.337l.943-1.8A1.5,1.5,0,0,1-4385,1395l.091,0a1.57,1.57,0,0,1,.458.068,1.422,1.422,0,0,1,1.039,1.35,1.363,1.363,0,0,1-.02.225l-.166.949h1.969a2.526,2.526,0,0,1,1.858.8,2.251,2.251,0,0,1,.607,1.522,2.258,2.258,0,0,1-.024.329l-.348,2.326a2.434,2.434,0,0,1-2.44,2Zm6.557-.931a1.459,1.459,0,0,0,1.461-1.2l.349-2.326a1.517,1.517,0,0,0,.015-.188,1.351,1.351,0,0,0-.363-.922,1.517,1.517,0,0,0-1.116-.48h-2.547a.507.507,0,0,1-.376-.164.452.452,0,0,1-.117-.3.4.4,0,0,1,.006-.076l.26-1.489a.43.43,0,0,0,.007-.079.462.462,0,0,0-.123-.312.541.541,0,0,0-.374-.16h-.038l-.04,0a.53.53,0,0,0-.4.253l-1.079,2.063a.466.466,0,0,1-.113.136v5.241Zm-6.557,0h.986v-5.117h-.986a1.439,1.439,0,0,0-1.479,1.4v2.325A1.439,1.439,0,0,0-4388.536,1403.64Z"
                  transform="translate(4391 -1395)" fill="#ff661c" />
              </svg>
            </div>
            <div class="col-5 col-md-5 col-sm-5  text-left-sm   labelValue">Adresse E-mail</div>

            <div class="col-5 col-md-5 col-sm-5  text-left-sm   labelKey "> {{insuranceinfoData.client.email }} </div>
          </div>

          <div class="row mt-md-2">
            <div class="col-2 col-md-2 col-sm-1 text-right">

              <svg xmlns="http://www.w3.org/2000/svg" width="11.834" height="9.571" viewBox="0 0 11.834 9.571">
                <path id="Union_18" data-name="Union 18"
                  d="M-4388.536,1404.571a2.4,2.4,0,0,1-2.465-2.326v-2.325a2.4,2.4,0,0,1,2.465-2.326h1.337l.943-1.8A1.5,1.5,0,0,1-4385,1395l.091,0a1.57,1.57,0,0,1,.458.068,1.422,1.422,0,0,1,1.039,1.35,1.363,1.363,0,0,1-.02.225l-.166.949h1.969a2.526,2.526,0,0,1,1.858.8,2.251,2.251,0,0,1,.607,1.522,2.258,2.258,0,0,1-.024.329l-.348,2.326a2.434,2.434,0,0,1-2.44,2Zm6.557-.931a1.459,1.459,0,0,0,1.461-1.2l.349-2.326a1.517,1.517,0,0,0,.015-.188,1.351,1.351,0,0,0-.363-.922,1.517,1.517,0,0,0-1.116-.48h-2.547a.507.507,0,0,1-.376-.164.452.452,0,0,1-.117-.3.4.4,0,0,1,.006-.076l.26-1.489a.43.43,0,0,0,.007-.079.462.462,0,0,0-.123-.312.541.541,0,0,0-.374-.16h-.038l-.04,0a.53.53,0,0,0-.4.253l-1.079,2.063a.466.466,0,0,1-.113.136v5.241Zm-6.557,0h.986v-5.117h-.986a1.439,1.439,0,0,0-1.479,1.4v2.325A1.439,1.439,0,0,0-4388.536,1403.64Z"
                  transform="translate(4391 -1395)" fill="#ff661c" />
              </svg>
            </div>
            <div class="col-5 col-md-5 col-sm-5  text-left-sm   labelValue">Adresse</div>

            <div class="col-5 col-md-5 col-sm-5  text-left-sm   labelKey ">
              {{insuranceinfoData.client.address.streetNumber}}, {{insuranceinfoData.client.address.streetName}} ,
              {{insuranceinfoData.client.address.postalCode}} , {{insuranceinfoData.client.address.city}}
              {{insuranceinfoData.client.address.country}} </div>
          </div>
          <div *ngIf="insuranceinfoData.client.type=='Professionnal'">
            <div class="row mt-md-2">
              <div class="col-2 col-md-2 col-sm-1 text-right">

                <svg xmlns="http://www.w3.org/2000/svg" width="11.834" height="9.571" viewBox="0 0 11.834 9.571">
                  <path id="Union_18" data-name="Union 18"
                    d="M-4388.536,1404.571a2.4,2.4,0,0,1-2.465-2.326v-2.325a2.4,2.4,0,0,1,2.465-2.326h1.337l.943-1.8A1.5,1.5,0,0,1-4385,1395l.091,0a1.57,1.57,0,0,1,.458.068,1.422,1.422,0,0,1,1.039,1.35,1.363,1.363,0,0,1-.02.225l-.166.949h1.969a2.526,2.526,0,0,1,1.858.8,2.251,2.251,0,0,1,.607,1.522,2.258,2.258,0,0,1-.024.329l-.348,2.326a2.434,2.434,0,0,1-2.44,2Zm6.557-.931a1.459,1.459,0,0,0,1.461-1.2l.349-2.326a1.517,1.517,0,0,0,.015-.188,1.351,1.351,0,0,0-.363-.922,1.517,1.517,0,0,0-1.116-.48h-2.547a.507.507,0,0,1-.376-.164.452.452,0,0,1-.117-.3.4.4,0,0,1,.006-.076l.26-1.489a.43.43,0,0,0,.007-.079.462.462,0,0,0-.123-.312.541.541,0,0,0-.374-.16h-.038l-.04,0a.53.53,0,0,0-.4.253l-1.079,2.063a.466.466,0,0,1-.113.136v5.241Zm-6.557,0h.986v-5.117h-.986a1.439,1.439,0,0,0-1.479,1.4v2.325A1.439,1.439,0,0,0-4388.536,1403.64Z"
                    transform="translate(4391 -1395)" fill="#ff661c" />
                </svg>
              </div>
              <div class="col-5 col-md-5 col-sm-5  text-left-sm   labelValue">Raison Social Rs</div>

              <div class="col-5 col-md-5 col-sm-5  text-left-sm   labelKey   ">{{insuranceinfoData.client.company.RS}}
              </div>
            </div>

            <div class="row mt-md-2">
              <div class="col-2 col-md-2 col-sm-1 text-right">

                <svg xmlns="http://www.w3.org/2000/svg" width="11.834" height="9.571" viewBox="0 0 11.834 9.571">
                  <path id="Union_18" data-name="Union 18"
                    d="M-4388.536,1404.571a2.4,2.4,0,0,1-2.465-2.326v-2.325a2.4,2.4,0,0,1,2.465-2.326h1.337l.943-1.8A1.5,1.5,0,0,1-4385,1395l.091,0a1.57,1.57,0,0,1,.458.068,1.422,1.422,0,0,1,1.039,1.35,1.363,1.363,0,0,1-.02.225l-.166.949h1.969a2.526,2.526,0,0,1,1.858.8,2.251,2.251,0,0,1,.607,1.522,2.258,2.258,0,0,1-.024.329l-.348,2.326a2.434,2.434,0,0,1-2.44,2Zm6.557-.931a1.459,1.459,0,0,0,1.461-1.2l.349-2.326a1.517,1.517,0,0,0,.015-.188,1.351,1.351,0,0,0-.363-.922,1.517,1.517,0,0,0-1.116-.48h-2.547a.507.507,0,0,1-.376-.164.452.452,0,0,1-.117-.3.4.4,0,0,1,.006-.076l.26-1.489a.43.43,0,0,0,.007-.079.462.462,0,0,0-.123-.312.541.541,0,0,0-.374-.16h-.038l-.04,0a.53.53,0,0,0-.4.253l-1.079,2.063a.466.466,0,0,1-.113.136v5.241Zm-6.557,0h.986v-5.117h-.986a1.439,1.439,0,0,0-1.479,1.4v2.325A1.439,1.439,0,0,0-4388.536,1403.64Z"
                    transform="translate(4391 -1395)" fill="#ff661c" />
                </svg>
              </div>
              <div class="col-5 col-md-5 col-sm-5  text-left-sm   labelValue">Siren</div>

              <div class="col-5 col-md-5 col-sm-5  text-left-sm   labelKey ">{{insuranceinfoData.client.company.siren}}
              </div>
            </div>

            <div class="row mt-md-2">
              <div class="col-2 col-md-2 col-sm-1 text-right">

                <svg xmlns="http://www.w3.org/2000/svg" width="11.834" height="9.571" viewBox="0 0 11.834 9.571">
                  <path id="Union_18" data-name="Union 18"
                    d="M-4388.536,1404.571a2.4,2.4,0,0,1-2.465-2.326v-2.325a2.4,2.4,0,0,1,2.465-2.326h1.337l.943-1.8A1.5,1.5,0,0,1-4385,1395l.091,0a1.57,1.57,0,0,1,.458.068,1.422,1.422,0,0,1,1.039,1.35,1.363,1.363,0,0,1-.02.225l-.166.949h1.969a2.526,2.526,0,0,1,1.858.8,2.251,2.251,0,0,1,.607,1.522,2.258,2.258,0,0,1-.024.329l-.348,2.326a2.434,2.434,0,0,1-2.44,2Zm6.557-.931a1.459,1.459,0,0,0,1.461-1.2l.349-2.326a1.517,1.517,0,0,0,.015-.188,1.351,1.351,0,0,0-.363-.922,1.517,1.517,0,0,0-1.116-.48h-2.547a.507.507,0,0,1-.376-.164.452.452,0,0,1-.117-.3.4.4,0,0,1,.006-.076l.26-1.489a.43.43,0,0,0,.007-.079.462.462,0,0,0-.123-.312.541.541,0,0,0-.374-.16h-.038l-.04,0a.53.53,0,0,0-.4.253l-1.079,2.063a.466.466,0,0,1-.113.136v5.241Zm-6.557,0h.986v-5.117h-.986a1.439,1.439,0,0,0-1.479,1.4v2.325A1.439,1.439,0,0,0-4388.536,1403.64Z"
                    transform="translate(4391 -1395)" fill="#ff661c" />
                </svg>
              </div>
              <div class="col-5 col-md-5 col-sm-5  text-left-sm   labelValue">Adresse entreprise </div>

              <div class="col-5 col-md-5 col-sm-5  text-left-sm   labelKey ">
                {{streetNumberCompangnie}} {{streetNameCompangnie}} {{cityCompangnie}} {{postalCodeCompangnie}}
                {{countryCompangnie}} </div>
            </div>
          </div>

        </div>
      </div>

      <div class="row m-3">
        <div class="row mt-md-2 mb-md-3   titre">Le vélo</div>

        <div class=" p-md-3" style="background-color: #f7f7f7; border-radius: 15px;">
          <div class="row mt-md-2">
            <div class="col-2 col-md-2 col-sm-1 text-right">

              <svg xmlns="http://www.w3.org/2000/svg" width="11.834" height="9.571" viewBox="0 0 11.834 9.571">
                <path id="Union_18" data-name="Union 18"
                  d="M-4388.536,1404.571a2.4,2.4,0,0,1-2.465-2.326v-2.325a2.4,2.4,0,0,1,2.465-2.326h1.337l.943-1.8A1.5,1.5,0,0,1-4385,1395l.091,0a1.57,1.57,0,0,1,.458.068,1.422,1.422,0,0,1,1.039,1.35,1.363,1.363,0,0,1-.02.225l-.166.949h1.969a2.526,2.526,0,0,1,1.858.8,2.251,2.251,0,0,1,.607,1.522,2.258,2.258,0,0,1-.024.329l-.348,2.326a2.434,2.434,0,0,1-2.44,2Zm6.557-.931a1.459,1.459,0,0,0,1.461-1.2l.349-2.326a1.517,1.517,0,0,0,.015-.188,1.351,1.351,0,0,0-.363-.922,1.517,1.517,0,0,0-1.116-.48h-2.547a.507.507,0,0,1-.376-.164.452.452,0,0,1-.117-.3.4.4,0,0,1,.006-.076l.26-1.489a.43.43,0,0,0,.007-.079.462.462,0,0,0-.123-.312.541.541,0,0,0-.374-.16h-.038l-.04,0a.53.53,0,0,0-.4.253l-1.079,2.063a.466.466,0,0,1-.113.136v5.241Zm-6.557,0h.986v-5.117h-.986a1.439,1.439,0,0,0-1.479,1.4v2.325A1.439,1.439,0,0,0-4388.536,1403.64Z"
                  transform="translate(4391 -1395)" fill="#ff661c" />
              </svg>
            </div>
            <div class="col-5 col-md-5 col-sm-5  text-left-sm  labelValue">Type</div>

            <div class="col-5 col-md-5 col-sm-5  text-left-sm   labelKey "> {{insuranceinfoData.bike.type}}</div>
          </div>

          <div class="row mt-md-2">
            <div class="col-2 col-md-2 col-sm-1 text-right">

              <svg xmlns="http://www.w3.org/2000/svg" width="11.834" height="9.571" viewBox="0 0 11.834 9.571">
                <path id="Union_18" data-name="Union 18"
                  d="M-4388.536,1404.571a2.4,2.4,0,0,1-2.465-2.326v-2.325a2.4,2.4,0,0,1,2.465-2.326h1.337l.943-1.8A1.5,1.5,0,0,1-4385,1395l.091,0a1.57,1.57,0,0,1,.458.068,1.422,1.422,0,0,1,1.039,1.35,1.363,1.363,0,0,1-.02.225l-.166.949h1.969a2.526,2.526,0,0,1,1.858.8,2.251,2.251,0,0,1,.607,1.522,2.258,2.258,0,0,1-.024.329l-.348,2.326a2.434,2.434,0,0,1-2.44,2Zm6.557-.931a1.459,1.459,0,0,0,1.461-1.2l.349-2.326a1.517,1.517,0,0,0,.015-.188,1.351,1.351,0,0,0-.363-.922,1.517,1.517,0,0,0-1.116-.48h-2.547a.507.507,0,0,1-.376-.164.452.452,0,0,1-.117-.3.4.4,0,0,1,.006-.076l.26-1.489a.43.43,0,0,0,.007-.079.462.462,0,0,0-.123-.312.541.541,0,0,0-.374-.16h-.038l-.04,0a.53.53,0,0,0-.4.253l-1.079,2.063a.466.466,0,0,1-.113.136v5.241Zm-6.557,0h.986v-5.117h-.986a1.439,1.439,0,0,0-1.479,1.4v2.325A1.439,1.439,0,0,0-4388.536,1403.64Z"
                  transform="translate(4391 -1395)" fill="#ff661c" />
              </svg>
            </div>
            <div class="col-5 col-md-5 col-sm-5  text-left-sm   labelValue ">Marque</div>

            <div class="col-5 col-md-5 col-sm-5  text-left-sm   labelKey "> {{insuranceinfoData.bike.brand}} </div>
          </div>

          <div class="row mt-md-2">
            <div class="col-2 col-md-2 col-sm-1 text-right">

              <svg xmlns="http://www.w3.org/2000/svg" width="11.834" height="9.571" viewBox="0 0 11.834 9.571">
                <path id="Union_18" data-name="Union 18"
                  d="M-4388.536,1404.571a2.4,2.4,0,0,1-2.465-2.326v-2.325a2.4,2.4,0,0,1,2.465-2.326h1.337l.943-1.8A1.5,1.5,0,0,1-4385,1395l.091,0a1.57,1.57,0,0,1,.458.068,1.422,1.422,0,0,1,1.039,1.35,1.363,1.363,0,0,1-.02.225l-.166.949h1.969a2.526,2.526,0,0,1,1.858.8,2.251,2.251,0,0,1,.607,1.522,2.258,2.258,0,0,1-.024.329l-.348,2.326a2.434,2.434,0,0,1-2.44,2Zm6.557-.931a1.459,1.459,0,0,0,1.461-1.2l.349-2.326a1.517,1.517,0,0,0,.015-.188,1.351,1.351,0,0,0-.363-.922,1.517,1.517,0,0,0-1.116-.48h-2.547a.507.507,0,0,1-.376-.164.452.452,0,0,1-.117-.3.4.4,0,0,1,.006-.076l.26-1.489a.43.43,0,0,0,.007-.079.462.462,0,0,0-.123-.312.541.541,0,0,0-.374-.16h-.038l-.04,0a.53.53,0,0,0-.4.253l-1.079,2.063a.466.466,0,0,1-.113.136v5.241Zm-6.557,0h.986v-5.117h-.986a1.439,1.439,0,0,0-1.479,1.4v2.325A1.439,1.439,0,0,0-4388.536,1403.64Z"
                  transform="translate(4391 -1395)" fill="#ff661c" />
              </svg>
            </div>
            <div class="col-5 col-md-5  col-sm-5  text-left-sm  labelValue ">Modéle</div>

            <div class="col-5 col-md-5 col-sm-5  text-left-sm   labelKey"> {{insuranceinfoData.bike.model}} </div>
          </div>

          <div class="row mt-md-2">
            <div class="col-2 col-md-2 col-sm-1 text-right">

              <svg xmlns="http://www.w3.org/2000/svg" width="11.834" height="9.571" viewBox="0 0 11.834 9.571">
                <path id="Union_18" data-name="Union 18"
                  d="M-4388.536,1404.571a2.4,2.4,0,0,1-2.465-2.326v-2.325a2.4,2.4,0,0,1,2.465-2.326h1.337l.943-1.8A1.5,1.5,0,0,1-4385,1395l.091,0a1.57,1.57,0,0,1,.458.068,1.422,1.422,0,0,1,1.039,1.35,1.363,1.363,0,0,1-.02.225l-.166.949h1.969a2.526,2.526,0,0,1,1.858.8,2.251,2.251,0,0,1,.607,1.522,2.258,2.258,0,0,1-.024.329l-.348,2.326a2.434,2.434,0,0,1-2.44,2Zm6.557-.931a1.459,1.459,0,0,0,1.461-1.2l.349-2.326a1.517,1.517,0,0,0,.015-.188,1.351,1.351,0,0,0-.363-.922,1.517,1.517,0,0,0-1.116-.48h-2.547a.507.507,0,0,1-.376-.164.452.452,0,0,1-.117-.3.4.4,0,0,1,.006-.076l.26-1.489a.43.43,0,0,0,.007-.079.462.462,0,0,0-.123-.312.541.541,0,0,0-.374-.16h-.038l-.04,0a.53.53,0,0,0-.4.253l-1.079,2.063a.466.466,0,0,1-.113.136v5.241Zm-6.557,0h.986v-5.117h-.986a1.439,1.439,0,0,0-1.479,1.4v2.325A1.439,1.439,0,0,0-4388.536,1403.64Z"
                  transform="translate(4391 -1395)" fill="#ff661c" />
              </svg>
            </div>
            <div class="col-5 col-md-5  col-sm-5  text-left-sm  labelValue ">Prix d'achat du vélo</div>

            <div class="col-5 col-md-5 col-sm-5  text-left-sm   labelKey"> {{insuranceinfoData.bike.purchasePrice}}
            </div>
          </div>
        </div>
      </div>

      <div>

        <div class="row m-3">
          <div class="row mt-md-2  mb-md-3  titre">Signature & Payement</div>
        </div>

        <div class="row  m-3 " style="background-color: #f7f7f7 ; border-radius: 15px; ">
          <div class="row mt-4    mx-2 labelMontant">
            Montants comptants à régler à la souscription par prélèvement :<br/>
            {{firstAmount+ fraisAssistance | number : '.2-2' }} € pour la période de {{firstDateAmount}} au {{firstLastDate}} période correspondant à:
             <ul>
           
               <li>Une mensualité Assurance {{typeContrat}} dont la taxe Attentat de 5,90 € non remboursables.</li>
               <li *ngIf="isAssistance">Une mensualité Assistance</li>
               <li>15 € de frais de dossier non remboursables</li>
             </ul> 


          </div>

          <div class="row  labelIban "  style="background-color: #DDE5EF" [formGroup]="formIban">
       
             <div class="">
             
              <div class="row mt-2 mb-2">
                <div  class ="col-md-6 col-sm-12"> 
                  <mat-form-field class="w-100">
                    <mat-label> Saisissez Nom & Prénom ou RS du titulaire de compte  </mat-label>
                    <input 
                 
                     matInput type="text"
                      name="nomPrenomRs"
                      required formControlName="nomPrenomRs"
                      [(ngModel)]="nomPrenomRs"    
                      placeholder="Saisissez nom et prénom ou RS titulaire de compte" />
                      <mat-error  *ngIf=" formIban.get('nomPrenomRs')?.touched || formIban.get('nomPrenomRs')?.hasError('required') ">
                        Nom & Prénom ou RS du titulaire de compte invalide
                       </mat-error> 
                   </mat-form-field> 
                 </div>
  
                 <div  class ="col-md-6 col-sm-12" > 
                  <mat-form-field class="w-100">
                    <mat-label>Saisissez votre IBAN </mat-label>
                    <input pattern="^FR\d{12}[0-9A-Z]{11}\d{2}$" 
                    v-model.trim="input" 
                    v-on:keyDown="resetIsValid"
                     matInput type="text"
                      name="iban"
                      required formControlName="iban"
                      [(ngModel)]="iban"  ibanValidator   autocomplete="off" 
                      placeholder="Iban" />
                      <mat-error  *ngIf=" formIban.get('iban')?.touched || formIban.get('iban')?.hasError('required') ">
                        IBAN invalide
                       </mat-error> 
                   </mat-form-field> 
                </div>
              </div>
             
              <div class="row mt-2 mb-4">
                <div class ="col-md-12 col-sm-12  form-check">
                  <input class="form-check-input" formControlName="IBANconsent" name='IBANconsent'
                    (change)="isActiveIBANconsent($event)" type="checkbox" value=" " id="invalidCheckIBAN"
                    aria-describedby="invalidCheckIBANFeedback" required>
                  <label class="labelcheck mt-1 px-1" for="invalidCheckIBAN">
                    En cochant cette case vous autorisez Berilish à envoyer des instructions à votre banque pour débiter
                    votre compte du montant des échéances de vos garanties d'assurances. Transaction sécurisée par Stripe .
                  </label>
                </div>
              </div>
            </div> 
      
          </div>

          <div>
            <div class="row mt-1 mb-2 p-3 justify-content-between">
              <div class="col-xl-3  col-lg-6 col-6   col-xs-2 text-center">
                <img src="../../../../assets/svg/ssl.svg" class="imagePay" alt="ssls" />
              </div>
              <div class="col-xl-3 mt-1  col-lg-6  col-6   col-xs-2 text-center">
                <img src="../../../../assets/svg/lidas.svg" class="imagePay" alt="lidas" />
              </div>
              <div class="col-xl-3 col-lg-6  col-6  col-xs-2 mt-3 text-center">
                <img src="../../../../assets/svg/sepa.svg" class="imageSepa" alt="sepa" />
              </div>
              <div class="col-xl-3  col-lg-6 col-6  col-xs-2  mt-1  mt-md-2  text-center">
                <img src="../../../../assets/svg/rgpd.svg" class="imagePay" alt="sepa" />
              </div>
            </div>
          </div>


        </div>
      </div>




    </div>


    <div>

      <div class="card-footer border-0">
        <div class="row mt-4 justify-content-around " *ngIf="!loaderGloabal">
          <app-buttonretour class="w-auto m-1" (changepreviouspage)="Previouspage($event)" [label]="retour">
          </app-buttonretour>

          <app-button class="w-auto m-1" (changepagesuivante)="Nextpage()" [label]="Valider" [disabled]="loaderGloabal"></app-button>





        </div>

        <div class="row mt-md-3 justify-content-center ">
          <ng-container *ngIf="loaderGloabal">
            <div class="loading-container">
              <div class="spinner"></div>
              <div class="spinner-center"></div>
              <h6 class="titre" *ngIf="loaderTransaction"> Transaction en cours ... </h6>
              <h6 class="titre" *ngIf="loaderVerification"> Vérification du paiement ... </h6>
              <h6 class="titre" *ngIf="loaderGenration"> Génération du contrat ...</h6>
              <h6 class="titre" *ngIf="loaderSubscription"> Finalisation ...</h6>
            </div>
          </ng-container>



        </div>

      </div>
    </div>
  </div>
</div>