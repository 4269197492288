
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ApicreationService } from 'src/app/core/services/apicreationservice.service';
import { Apisuccessresponse } from 'src/app/shared/models/apisuccessresponse';
import { Bike } from 'src/app/shared/models/bike';
import { CheckBoxType } from 'src/app/shared/models/checkboxtype';
import { Esign_body } from 'src/app/shared/models/esign_body';
import { Insuranceinfo } from 'src/app/shared/models/insuranceinfo';
import { Subscribe_body } from 'src/app/shared/models/subscribe_body';
import { Formula, Warranty } from 'src/app/shared/models/warranty';
import swal from 'sweetalert2';


@Component({
  selector: 'app-garantiesvelo',
  templateUrl: './garantiesvelo.component.html',
  styleUrls: ['./garantiesvelo.component.css']
})
export class GarantiesveloComponent implements OnInit {
  @Input()TotalAmount :number = 0 ;
  @Output() insuranceinfo : Insuranceinfo ;
  @Input() pagedata:number = 0;
  @Output() ondatachanged = new EventEmitter<any>();
  @Output()changepagenumber : EventEmitter<number> = new EventEmitter();
  DommageYear :number = 0 ;
  DommageMois :number = 0 ;
  VolMois :number = 0 ;
  VolYear :number = 0 ;
  DommageVolMois :number = 0 ;
  DommageVolYear :number = 0 ;
  Totalcouverture :number = 0 ;
  TotalAmountApresDon :number = 0 ;
  TotalMoisApresDon:number;
  TotalMois:number;
  subscribe_body:Subscribe_body;
  storeRef : string ;
  disabledNvOption :boolean = true ;
  bike :Bike = new Bike() ; 
  url_sign : string;
  disabledoption:boolean =true ;
  disabledformula:boolean =true ; 
  Esign_body : Esign_body = new Esign_body();
  esign: string;
  checkdonat:boolean =false ; 
  Souscrire :string ="Souscrire";
  public  payementref :string;
  retour:string = "Retour";
  @Input() loader_garantie:boolean = true; 
  PAOption:boolean=false;
  NVOption:boolean=false;
  DomVolOption:boolean=false;
  VolOption:boolean=false;
  DommageOption:boolean=false;
  data : any ; 
  formula= Formula ;
  warranty : Warranty = new Warranty();
  Option :any;
  donationprix :number ;
   check_box_type = CheckBoxType;
 
  currentlychecked: CheckBoxType;
  constructor( public sanitizer: DomSanitizer,private apicreationService : ApicreationService,) { 
      this.bike =JSON.parse(localStorage.getItem('Bike')!);
      this.apicreationService.listenRate().subscribe((m: any) => {
        this.disabledoption = false ; 
        this.apicreationService.rate=[]
        if ( m=== undefined)
        this.Previouspage(-1);
        else
        {
          
          m.forEach((data:any) => {
            this.apicreationService.rate.push(data);
          });
          this.getRateData(m)
        }
        
      })
    }

  ngOnInit(): void {
    if ( this.apicreationService.checkedoption !== undefined && this.apicreationService.checkedoption !== 3)
    this.currentlychecked=this.apicreationService.checkedoption ;

    this.apicreationService.rateaccident= []; 
    this.apicreationService.ratevalurneuf= []; 
    if( this.apicreationService.rate.length > 0)
    {
      this.getRateData (this.apicreationService.rate)
      this.disabledoption = false ; 
    }

   

  }



  Previouspage(valueemitted :any){
    localStorage.setItem('warranty', JSON.stringify(this.warranty));
    this.changepagenumber.emit(valueemitted)
  }
 
  selectCheckBox(targetType: CheckBoxType) {
     
    if(this.currentlychecked === targetType) {
      this.currentlychecked = CheckBoxType.NONE;
      return;
    }

    this.currentlychecked = targetType;
  }



 
  Nextpage(valueemitted :any){
    this.apicreationService.ratedata=[]; 
    if ( !this.disabledoption)
    {
     if( this.currentlychecked === 3 || this.currentlychecked === undefined)
    
     this.alertWarning('Vous devez choisir une option')
     
     else 
      { 
     
        if ( this.currentlychecked === 2 )
        { 
          
          this.data.forEach((rate : Apisuccessresponse) => {
            
            if (rate.warranty.Formula ===  "Damage and Theft" as Formula )
             this.apicreationService.ratedata.push(rate) ; 
          });
        }
        else if ( this.currentlychecked === 1 )
        { 
          this.data.forEach((rate : Apisuccessresponse) => {
            if (rate.warranty.Formula ===  "Theft" as Formula )
             this.apicreationService.ratedata.push(rate) ; 
          });
           
        }
        else if ( this.currentlychecked === 0 )
        { 
          this.data.forEach((rate : Apisuccessresponse) => {
            if (rate.warranty.Formula === "Damage" as Formula )
             this.apicreationService.ratedata.push(rate) ; 
          });
           
        }
       
        this.apicreationService.checkedoption =  this.currentlychecked ;        
        this.changepagenumber.emit(valueemitted);
      }
    }  
  }



  alertWarning(error :any) {
    swal.fire({
      title:'',
      icon: 'warning',
      html: error,
      showConfirmButton: true,
      showCloseButton: true,
      confirmButtonText: 'Fermer',
      confirmButtonColor: '#e75e5e',
    })
  }
  public getRateData(data: any):void {
    this.loader_garantie = true;
    this.data = data ;   

    this.data.forEach((rate : Apisuccessresponse) => {
      if ( rate.warranty.Formula ==="Damage and Theft" as Formula && rate.warranty.Excess === "10%" &&rate.warranty.NVOption==="No" && rate.warranty.PAOption==="No"  )
      {
            let ratedv =rate.rate.TotalAmountAllTaxesIncluded;
            this.DommageVolYear =  Number(ratedv.toFixed(2));
            let num = ratedv/12; 
            this.DommageVolMois = Number(num.toFixed(2));
      }
      if ( rate.warranty.Formula === "Damage" as Formula && rate.warranty.Excess === "10%" && rate.warranty.NVOption==="No" && rate.warranty.PAOption==="No"  )
      {
            let rated =rate.rate.TotalAmountAllTaxesIncluded;
            this.DommageYear =  Number(rated.toFixed(2));
            let num = rated/12; 
            this.DommageMois = Number(num.toFixed(2));
      }
      if ( rate.warranty.Formula === "Theft" as Formula && rate.warranty.Excess === "10%" && rate.warranty.NVOption==="No" && rate.warranty.PAOption==="No"  )
      {
            let ratev =rate.rate.TotalAmountAllTaxesIncluded;
            this.VolYear =  Number(ratev.toFixed(2));
            let num = ratev/12; 
            this.VolMois = Number(num.toFixed(2));
      }
    });
    
   
    this.loader_garantie = false;
  }

  hideloader() {
    document.getElementById('loadingBar')!
      .style.display = 'none';
  }

  showloader() {
    document.getElementById('loadingBar')!
      .style.display = 'block';
  }

 
}
