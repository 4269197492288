<div mat-dialog-content>

  <span *ngIf="!data.pieceIdentite">Voulez-vous passer à votre espace Bysur sans nous transmettre  <b>la pièce d'identité </b>?</span>
  <span *ngIf="!data.Attestation">Voulez-vous passer à votre espace Bysur sans nous transmettre  <b>l'attestation de marquage</b> ?</span>
<span *ngIf="data.pieceIdentite && data.Attestation">Passez à mon Espace Client!</span>
 
</div>
<mat-dialog-actions align="end" >
    <button class="mr-3" mat-button mat-dialog-close *ngIf="appear" style="background-color: gray; color: white; margin-right: 10px;">NON</button>
<!--     <a href="" id="close-window">
      <button onclick="self.close ()"  mat-button  color="warn" *ngIf="!appear" style="background-color: #f44336; color: white;" >Fermer</button>
      </a> -->
    <a [href]="url">
    <button class="ml-3"mat-button [mat-dialog-close]="true" cdkFocusInitial style="background-color: green;color: white; margin-left: 20px;">OUI</button>

    </a>

  </mat-dialog-actions>
