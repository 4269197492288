import { Component, ElementRef, EventEmitter, HostListener, Input, ViewChild } from "@angular/core";
import { DomSanitizer, SafeUrl } from "@angular/platform-browser";
import { NgbActiveModal, NgbModal, NgbModalOptions } from "@ng-bootstrap/ng-bootstrap"
import { ApicreationService } from "src/app/core/services/apicreationservice.service";

@Component({
  selector: 'ngbd-modalpay-content',
  styleUrls: ['./modelpay.component.css'],
  templateUrl: './modelpay.component.html',
 
})
export class NgbdModalPayContent {
  @Input() public  srcurl: SafeUrl ;
  public event: EventEmitter<any> = new EventEmitter();
  public loader_Paiment: boolean = false; 
  public loading: boolean = false;
  passToSub: boolean = false;

  constructor( public activeModal:
    NgbActiveModal,private apicreationService : 
    ApicreationService,private modalService: 
    NgbModal, public sanitizer:
    DomSanitizer) {
    this.apicreationService.listenpasstosubscribe().subscribe(m => 
      {
        this.passToSub = m;
        
      }
  )
  
   }
   passauSub(){
    this.event.emit();
  }

}


