
<div class="modal-content" id="modelcontent">
    <div class="modal-header d-block p-0">
        <div class="row p-md-1 align">
            <div class="col mx-2 mt-2" id="sign">
                <h2>Signature électronique</h2>
            </div>
            <div class="col textalign">
                <img src="assets/svg/logoberilish.png" alt=" ">

            </div>

        </div>

    </div>


  
    <div class="modal-body p-0">
        <ng-container *ngIf="loader_garantie" class="w-300 "> 
            <div class="loading-container">
           <div class="spinner"></div>
           <div class="spinner-center"></div>
         </div>
       </ng-container> 
        <iframe #iframe  *ngIf="!loader_garantie"  id="iframe" class="w-100" style="height: 64vh" [src]="srcurl"></iframe> 
    </div> 
  <!--   <div class="modal-footer">
        <button class="btn btn-primary" data-bs-toggle="modal" (click)="passauSub()" data-bs-dismiss="modal"  [disabled]="!passToSub" >Passer au payement</button>
    </div> -->
</div>