import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  @Output()changepagenumber : EventEmitter<number> = new EventEmitter();

  year=new Date().getFullYear()
  constructor(private router: Router ) { }
  ngOnInit(): void {
   
  }

  goToForm() {
    window.open('form/formulaire', "_blank");
  }

  goViePrive() {
    window.open('form/vie-privee', "_blank");
  }

}
