<div class="footer">
    <div id="ID2021-neopolisdev" class="pt-md-3">
        <span class="droit">{{year}} © BERILISH Tous Droits Réservés ®  / </span>
        <u class="mention" (click)="goToForm()">CGU</u>
        <span> - </span> <u class="mention" (click)="goViePrive()" >Vie Privée</u>
    </div>
</div>


<!-- Button trigger modal -->
