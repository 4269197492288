import { NativeDateAdapter,MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';


export class CustomDateAdapter extends NativeDateAdapter {
   format(date: Date, displayFormat: Object): string {
     let result=date.toDateString();
     const day = date.getDate();
     const month = date.getMonth() + 1;
     const year = date.getFullYear();
     //see that displayformat get all the values indicate in MY_FORMATS.display
     switch (displayFormat)
     {
       case 'DD/MM/YYYY':
         // Return the format as per your requirement
         result= `${this._to2digit(day)}/${this._to2digit(month)}/${year}`;
         break;
        default:
       case 'MMM YYYY':
         // Return the format as per your requirement
         result= `${this._to2digit(month)}/${year}`;
         break;
     }
     return result;
   }
   private _to2digit(n: number) {
    return ('00' + n).slice(-2);
    } 
   parse(value:string):any
   {
     let parts=value.split('/');
     if (parts.length==3)
      return new Date(+parts[2],(+parts[1])-1,+parts[0])

   }
   
  
}

export const MY_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};
