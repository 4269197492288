import { Inject, Injectable, InjectionToken, Optional } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import swal from 'sweetalert2';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { DomSanitizer } from '@angular/platform-browser';
import { CustomHttpUrlEncodingCodec } from '../encoder';
import { environment } from 'src/environments/environment';

export const BASE_PATH = new InjectionToken<string>('basePath');

@Injectable()
export class GeneriqueService {
  defaultHeaders :any;
  protected basePath = environment.PathAdresses;
  protected geoprod_url = environment.urlgeoprod;
  protected idSession = environment.idSession ; 

  constructor(protected httpClient: HttpClient,@Optional()@Inject(BASE_PATH) basePath: string ,private modalService: NgbModal, public sanitizer: DomSanitizer ) { }

      public PostApi( observe: any = 'body', reportProgress: boolean = false , url : string): Observable<any> {
        let headers = this.defaultHeaders;

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('post',`${this.basePath}${url}`,
          {
            headers: headers,
            body: observe,
            reportProgress: reportProgress
          }
        );
      }
      public PutApi( observe: any = 'body', reportProgress: boolean = false , url : string): Observable<any> {
        let headers = this.defaultHeaders;

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('put',`${this.basePath}${url}`,
          {
            headers: headers,
            body: observe,
            reportProgress: reportProgress
          }
        );
      }
      public GetApi( observe: any = 'body', reportProgress: boolean = false , url : string ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Content-Type header


        return this.httpClient.request<any>('get',`${this.basePath}${url}`,
          {
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
          }
        );
      }

      public uploadDoc( Data: FormData, observe: any = 'body', reportProgress: boolean = false): Observable<any> {


         let headers =  new HttpHeaders({
           'idSession': this.idSession,
          });
        return this.httpClient.request<any>('post', this.geoprod_url+`upload_document_affaire?`,
          {
            headers: headers,
            observe: observe,
            reportProgress: reportProgress,
            body: Data
          }
        );
      }
      alertWarning(error :any) {
        let message ="Une erreur est servenue";
        if ( error === null || error === '')
        message = "Vous devez remplir les champs obligatoires";
        else if( error!.code === 400 || error.status === 400 )
        message ="Les paramètres transmis ne permettent pas de continuer. Vérifiez les modalités transmises.";
        else if( error!.code === 401|| error.status === 401 )
        message ="Erreur d'authentification!";
        else if( error!.status === 422|| error.code === 422)
        message = "Veuillez vérifier les champs que vous avez mis ! ";
        else if(error.code ===404|| error.error?.code ===404 || error.code ===500 ||error.code ===503 || error?.error?.status ===500 || error?.error?.code === 500 ||error?.error?.code === 503 ||error.status === 503 )
        message= "Service non disponible" ;
        else if(error.name=="TimeoutError")
        message="Veuillez réessayer ultérieurement ! "
        swal.fire({
          title:'',
          icon: 'warning',
          html: message,
          showConfirmButton: true,
          showCloseButton: true,
          confirmButtonText: 'Fermer',
          confirmButtonColor: '#e75e5e',
        })
      }

      alertSuccess(data:any){
        swal.fire({
          title: 'Opération Réussie!',
          text: data,
          icon: 'success',

          }).then((result)=>{
            if(result.isConfirmed){

              window.location.reload();
            }
            if(result.isDismissed){

            }
            if(result.isDenied){


              location.reload()
            }
        })
      }











}
