import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Inject, Injectable, InjectionToken, OnDestroy, OnInit, Optional } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CustomHttpUrlEncodingCodec }from '../encoder';
import { GeneriqueService } from '../Generiqservice/generiqueservice.service';
export const BASE_PATH = new InjectionToken<string>('basePath');
import { map, catchError } from 'rxjs/operators';
import { Apierrorresponse } from 'src/app/shared/models/apierrorresponse';

@Injectable()
export class QuotationService {
  constructor(public generiqueService :GeneriqueService ) { }


  /**
   *Web services that give you the rate for the policy
   *
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
   public citieszipcode( parametre : string  ,parametrecode:string, observe: any = 'body', reportProgress: boolean = false  ) {
     return this.generiqueService.GetApi(observe, reportProgress, `/correctCityZipCode?${parametre}=${parametrecode}`);
   
  }
   
   /**
   * Get insurance rate
   *
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
    public Getinsurancerate( observe: any = 'body', reportProgress: boolean = false ) {

      return this.generiqueService.PostApi(observe,reportProgress, '/getRate')
      
     
    }


   
  

}

