import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.css']
})
export class ButtonComponent implements OnInit {
  @Input() label!: string;
  @Input() disabled: boolean = false;
  @Output() onClick = new EventEmitter<any>();
  @Input() functionCall!: string;

  // @Input() test:number = 0;
  @Output() changepagesuivante: EventEmitter<number> = new EventEmitter();
  constructor() {
  }

  ngOnInit(): void {

  }
  public Passpagesuivante() {
    this.changepagesuivante.emit(+1);
  }
}
