
<div class="image_outer  mt-0 " id="changepage" *ngIf="pagedata > 1">

    <div class="justify-content-center" id="clickpage">
        <div class="row">
            <div class="col-12 p-0 headerstyle">
                <app-header  ></app-header>
            </div>
        </div>
        <div class="row w-100   marginpage mx-0 ">
            
            <div class=" d-grid p-3 pt-5  justify-content-center text-center">

                <label class="titre">Obtenez votre assurance vélo en 2 minutes</label>
   <!--              <p class="pt-2 titreLabel">Aucun document requis</p> -->
           
            </div>
            <div class="col-md-12 pad">
                <div class="card  mt-5 marginapp offset-xl-3 col-xl-6  offset-lg-2 col-lg-8  offset-md-1 col-md-9 "  style="border-radius: 10px; border: none;" >
                    <div class="card-body p-0  mt-4">
                        <app-fiche-client (changepagenumber)="changepage($event) " class="w-100 h-100 " *ngIf="pagedata===2 "></app-fiche-client>
                        <app-fiche-velo (changepagenumber)="changepage($event) " class="w-100 h-100 " *ngIf="pagedata===3 "></app-fiche-velo>
                        <app-garantiesvelo (changepagenumber)="changepage($event) " class="w-100 h-100 " *ngIf="pagedata===4 " [loader_garantie]="loader_garantie "></app-garantiesvelo>
                        <app-optionaccident (changepagenumber)="changepage($event) " class="w-100 h-100 " *ngIf="pagedata===5 "></app-optionaccident>
                        <app-optionvn (changepagenumber)="changepage($event) " class="w-100 h-100 " *ngIf="pagedata===6"></app-optionvn>
                        <app-franchise (changepagenumber)="changepage($event) " class="w-100 h-100 " *ngIf="pagedata===7"></app-franchise>
                        <app-assistance (changepagenumber)="changepage($event) " class="w-100 h-100 " *ngIf="pagedata===8"></app-assistance>
                        <app-ibanpage (changepagenumber)="changepage($event) " class="w-100 h-100 " *ngIf="pagedata===9"></app-ibanpage>
                        <app-successpage (changepagenumber)="changepage($event)" class="w-100 h-100 " *ngIf="pagedata===10" [name]="name" [email]="email" [reference]="reference" [civilite]="civilite"></app-successpage>
                       <app-formulaire class="w-100 h-100 " *ngIf="pagedata===13" ></app-formulaire>
                       <app-vie-privee class="w-100 h-100 " *ngIf="pagedata===14" ></app-vie-privee>
                    </div>
                </div>
            </div>
        </div>

    </div>

</div>
<div  id="pagefirst" *ngIf="pagedata < 2">
    <div class="row">
            <div class="col p-0 headerstyle">
                <app-header  ></app-header>
            </div>
        </div>
    <div class="image_outer"> 

    <div class="  d-flex justify-content-center" id="clickpageFirest">

        <div class="row w-100  margin">
            <div class="d-grid p-3 pt-5 text-white justify-content-center text-center">

                <label class="pt-2 titre">Obtenez votre assurance vélo en 2 minutes</label>
<!--                 <p class="pt-2 titreLabel  ">Aucun document requis</p> -->
            </div>
            <div class="col-md-12">
                <app-scanpage (changepagenumber)="changepage($event)" class="w-100 h-100 " *ngIf="pagedata===1 "></app-scanpage>

            </div>

        </div>
    </div>
    </div>

</div>
<div class="row ">
    <div class="col p-0 footerstyle">
        <app-footer (changepagenumber)="changepage($event)" ></app-footer>
    </div>
</div> 