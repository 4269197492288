
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ApicreationService } from 'src/app/core/services/apicreationservice.service';
import { Apisuccessresponse } from 'src/app/shared/models/apisuccessresponse';
import { Bike } from 'src/app/shared/models/bike';
import { Epay_body } from 'src/app/shared/models/pay-body';
import { Insuranceinfo } from 'src/app/shared/models/insuranceinfo';
import { Subscribe_body } from 'src/app/shared/models/subscribe_body';


@Component({
  selector: 'app-optionaccident',
  templateUrl: './optionaccident.component.html',
  styleUrls: ['./optionaccident.component.css']
})
export class OptionaccidentComponent implements OnInit {
  @Input()TotalAmount :number = 0 ;
  @Output() insuranceinfo : Insuranceinfo ;
  @Input() pagedata:number = 0;
  @Output() ondatachanged = new EventEmitter<any>();
  @Output()changepagenumber : EventEmitter<any> = new EventEmitter();
  data : Apisuccessresponse[];
  subscribe_body:Subscribe_body;
  storeRef : string ;
  IndAccident:number ; 
  choixAccident :string ; 
  disabledNvOption :boolean = true ;
  bike :Bike = new Bike() ; 
  url_sign : string;
  retour :string ="Retour";
  public  payementref :string;
  disabledformula:boolean =true ; 
  Esign_body : Insuranceinfo = new Insuranceinfo();
  Epay_body :Epay_body= new Epay_body();

  Oui :string ="Oui";
  esign: string;
  checkdonat:boolean =false ; 
  Non :string ="Non";

  constructor(public sanitizer: DomSanitizer,private apicreationService : ApicreationService,) { 
   
    }

  ngOnInit(): void {
    this.apicreationService.ratefranchise=[];
    this.apicreationService.ratevalurneuf=[];
    if( this.apicreationService.rateaccident.length>0)
    {
    this.data = this.apicreationService.rateaccident;
    this.apicreationService.ratedata =  this.data;
    }
    else 
    this.data = this.apicreationService.ratedata;
   let valeursansoption :number ;
   this.apicreationService.rateaccident = this.data;
    this.data.forEach((rate : Apisuccessresponse) => {
      if (rate.warranty.Excess === "10%" && rate.warranty.PAOption === 'No' &&  rate.warranty.NVOption === 'No')
      {
        let rateAccid =rate.rate.TotalAmountAllTaxesIncluded ;
        let num = rateAccid/12; 
       valeursansoption = num;
      }
    });
    this.data.forEach((rate : Apisuccessresponse) => {
      if (rate.warranty.Excess === "10%" && rate.warranty.PAOption === 'Yes' && rate.warranty.NVOption === 'No')
      {
        let rateAccid =rate.rate.TotalAmountAllTaxesIncluded ;
        let num = rateAccid/12; 
        let value = num;
        this.IndAccident= Number((value- valeursansoption).toFixed(2)); 
      }
    });
  
   
  }
  Nextpage(event :string){
    this.choixAccident = event ; 
    this.data = this.apicreationService?.ratedata;
    let length = this.apicreationService?.ratedata?.length
    this.apicreationService.ratedata = []; 

      this.data.forEach((rate : Apisuccessresponse) => {
        if (rate.warranty.PAOption === this.choixAccident)
         this.apicreationService.ratedata.push(rate) ; 
        });

    if ( length === 4)
      this.changepagenumber.emit(2);
    else 
    {
      this.changepagenumber.emit(1);
    }
  }

 



       
  Previouspage(valueemitted :any){
    this.changepagenumber.emit(valueemitted)

  }








 



  
   

}
