<div class="modal-content" id="modelcontent">
    <div class="modal-header d-block p-0">
        <div class="row p-md-2 align">
            <div class="col mx-2 mt-2" id="sign">
                <h2>Paiement</h2>
            </div>
            <div class="col textalign">
                <img src="assets/svg/logoberilish.png" alt=" ">

            </div>

        </div>
    </div>
    <div class="modal-body p-0">
        <iframe class="w-100" style="height: 52vh" [src]="srcurl"></iframe>
    </div>
    <div class="modal-footer">
        <button class="btn btn-primary" data-bs-toggle="modal" (click)="passauSub()" data-bs-dismiss="modal" [disabled]="!passToSub">Passer à la souscription</button>
    </div>
</div>