import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'multipart/form-data'  }) 
};
@Injectable({
  providedIn: 'root'
})
export class InsertionServiceService {

  // protected URL_BERILISH ="http://localhost:5000/"
  protected URL_BERILISH = environment.URL_BERILISH ;
  constructor( private httpClient : HttpClient) { }

  public addContrats(file: FormData,insert:string,apikey:string): Observable<any> {
   
    const headers= new HttpHeaders()
      .set('apikey', apikey);
    console.log(apikey);
    if (insert == "prixwakam")
      return this.httpClient.post<any>(`${this.URL_BERILISH}flotte/get_wakam_prices`,file,{headers:headers});
    else if (insert == "direct")
      return this.httpClient.post<any>(`${this.URL_BERILISH}flotte/insert_contracts`,file,{headers:headers});
    else
      return this.httpClient.post<any>(`${this.URL_BERILISH}flotte/add_contracts`,file,{headers:headers});
  }

  public add_avenant(file: FormData,apikey:string): Observable<any> {
   
    const headers= new HttpHeaders()
      .set('apikey', apikey);
    console.log(apikey);
    
    return this.httpClient.post<any>(`${this.URL_BERILISH}flotte/create_avenant`,file,{headers:headers});
  }

  public addEcheanciers(file: FormData): Observable<any> {
    console.log(file);
    return this.httpClient.post<any>(`${this.URL_BERILISH}flotte/insert_echeancier`,file); 

  }


  public resilitation_choice(file: FormData,choix:string,apikey:string,email:string,justificatif:boolean): Observable<any> {

    let value_choice: string = "";
   
    const headers= new HttpHeaders()
      .set('apikey', apikey)
      .set('Access-Control-Allow-Origin',"*");
    if (choix == "ticket_choix") {
      value_choice = "create_ticket" ; 
    }
    if (choix == "resilisation_choix") {
      value_choice = "update_status" ; 
    }
    return this.httpClient.post<any>(`${this.URL_BERILISH}flotte/resiliation?choice=${value_choice}&mail=${email}&one_file=${justificatif}`,file,{headers:headers});
  }

  public paiement(apikey:string,lastNamePay: string,fistNamePay: string,mailPay: string,ibanPay: string,customerIdPay: string,amountPay:Number,secret: string): Observable<any> {   
    const headers= new HttpHeaders()
      .set('apikey', apikey)
      .set('secret', secret)
      .set('Access-Control-Allow-Origin',"*");

    const pay_body = {
      "amount" : amountPay,
      "client": {
      "firstName": fistNamePay, 
      "lastName" : lastNamePay,
      "email": mailPay,
      "IBAN" : ibanPay
      },
      "customer_id": customerIdPay
    }
    
    return this.httpClient.post<any>(`${this.URL_BERILISH}pay_Stripe`,pay_body,{headers:headers});
  }
}



