<div class="bodyDiv " style="background-color: black;">
  <div class="cover ">

    <div class="card card1 text-center mx-auto">
      <div class="card-body">
        <img class="header-logo" src="/assets/images/logo header.png" alt="bysur Logo">
      </div>
    </div>

    <div class="card card2 text-center mx-auto mt-3">
      <div class="card-body text-center mx-auto  ">
        <div class="item3 grid-item">
          <div class="row ">
            <div class="body-email">
              <h2 class="mt-3 titleContrat">Interface des Flottes </h2>
              <div style="display: flex;flex-direction: column;align-items: center;justify-content: center;">
                <label>Choix du mode</label>
                <div class="select_drop">
                  <select name="choixmenu" id="choix-menu" style="width: 300px;height: 30px;" [(ngModel)]="choixmenu">
                    <option value="insertion">Insertion</option>
                    <option value="resiliation">Résiliation</option>
                    <option value="avenant">Avenant</option>
                    <option value="pay">Paiement</option>
                  </select>
                </div>
              </div>
              <div *ngIf="choixmenu == 'insertion'">
                Insertion Contrats <mat-slide-toggle class="example-margin" color="primary" (change)="onChange($event)">
                  Insertion Echeanciers
                </mat-slide-toggle>
                <hr size="3" width="100%" color="black">
                <div *ngIf="!checked">
                  <fieldset>
                    <div style="display: flex; flex-direction: column;justify-content: center;align-items: center;">
                      <label>ApiKey :</label>
                      <input type="text" id="key" name="key" placeholder="APIKEY" [(ngModel)]="apikey" style="width: 200px;" >
                    </div>

                    <hr size="6" width="100%" color="black">

                    <div>
                      <input type="radio" id="wakam" name="insert" value="prixwakam" [(ngModel)]="insert">
                      <label style="color: black;">Ajout Prix Wakam : </label> <br>
                      <label> Cette option permet de déterminer les prix de wakam et télécharger l'excel rempli</label>
                    </div>
                    <div *ngIf="insert == 'prixwakam'"
                      style="display: flex; flex-direction: column;justify-content: center;align-items: center;">
                      <hr size="4" width="40%" color="black">
                      <label>Email :</label>
                      <input type="text" id="email" name="email" placeholder="EMAIL" [(ngModel)]="email"
                        style="width: 200px;">

                      <label style="width: 300px;display: block;">*Ce champs vous permettera de revcevoir l'excel rempli
                        avec les prix wakam par mail</label>
                    </div>

                    <hr size="5" width="100%" color="black">
                    <div>

                      <input type="radio" id="insertwakam" name="insert" value="insertwakam" [(ngModel)]="insert">
                      <label style="color: black;">Insertion Contrats Manuellement : </label> <br>
                      <label> Cette option permet d'insérer les contrats , on doit utiliser le fichier excel qui a été
                        télécharger précédemment au niveau de l'option "Ajout Prix Wakam"</label>
                    </div>

                    <hr size="5" width="100%" color="black">

                    <div>
                      <input type="radio" id="direct" name="insert" value="direct" [(ngModel)]="insert">
                      <label style="color: black;">Insertion Contrats Automatiquement :</label> <br>
                      <label> Cette option permet d'insérer les contrats automatiquement avec les prix wakam extractés
                        automatiquement et non avec les prix convenus client précisés par le vendeur</label>
                    </div>


                  </fieldset>

                </div>

                <div *ngIf="checked">
                  <ul>
                    <li>Téléchargez l'excel</li>
                    <li>Mettez à jour les informations necessaires</li>
                    <li>Chargez ce fichier excel rempli pour le renouvellemnt</li>
                  </ul>
                  <div style="display: flex;flex-direction: column;justify-content: center;align-items: center;">
                  <hr size="3" width="80%" color="black">
                  <label style="color: rgb(192, 3, 3);">NB : la colonne "Date de prise d'effet" doit porter la date de début du nouvel échéancier à insérer</label>
                  <hr size="3" width="80%" color="black">
                </div>
                </div>
                <div>

                  <button (click)="onClickDownloadPdf()" class="ButtonTelechargement" mat-raised-button
                    style="background-color: rgba(0, 0, 0, 0.87); color: white; width: 250px; justify-content: center;">
                    <svg class="mx-2 iconup1" xmlns="http://www.w3.org/2000/svg" width="30" height="35"
                      viewBox="0 0 30 29.996">
                      <path
                        d="M16.707 7.404c-.189-.188-.448-.283-.707-.283s-.518.095-.707.283l-2.293 2.293v-6.697c0-.552-.448-1-1-1s-1 .448-1 1v6.697l-2.293-2.293c-.189-.188-.44-.293-.707-.293s-.518.105-.707.293c-.39.39-.39 1.024 0 1.414l4.707 4.682 4.709-4.684c.388-.387.388-1.022-.002-1.412zM20.987 16c0-.105-.004-.211-.039-.316l-2-6c-.136-.409-.517-.684-.948-.684h-.219c-.094.188-.21.368-.367.525l-1.482 1.475h1.348l1.667 5h-13.893l1.667-5h1.348l-1.483-1.475c-.157-.157-.274-.337-.367-.525h-.219c-.431 0-.812.275-.948.684l-2 6c-.035.105-.039.211-.039.316-.013 0-.013 5-.013 5 0 .553.447 1 1 1h16c.553 0 1-.447 1-1 0 0 0-5-.013-5z"
                        fill="#ffffff" />
                    </svg>
                    Télécharger
                    Fichier
                    Excel</button>
                  <button class="ButtonTelechargement"
                    style="background-color: rgba(0, 0, 0, 0.87); color: white; width: 250px; justify-content: center;">
                    <input type="file" id="idCerti" (change)="getTransmission($event)" accept=".xlsx" hidden />
                    <label for="idCerti">
                      <svg class="mx-2 iconup1" xmlns="http://www.w3.org/2000/svg" width="20" height="29.996"
                        viewBox="0 0 30 29.996">
                        <path id="upload-solid"
                          d="M17.344,22.571H12.656a1.4,1.4,0,0,1-1.406-1.406V11.321H6.111a1.17,1.17,0,0,1-.826-2L14.2.4a1.135,1.135,0,0,1,1.6,0l8.918,8.918a1.17,1.17,0,0,1-.826,2H18.75v9.844A1.4,1.4,0,0,1,17.344,22.571ZM30,22.1v6.563a1.4,1.4,0,0,1-1.406,1.406H1.406A1.4,1.4,0,0,1,0,28.664V22.1A1.4,1.4,0,0,1,1.406,20.7H9.375v.469a3.283,3.283,0,0,0,3.281,3.281h4.688a3.283,3.283,0,0,0,3.281-3.281V20.7h7.969A1.4,1.4,0,0,1,30,22.1Zm-7.266,5.156a1.172,1.172,0,1,0-1.172,1.172A1.175,1.175,0,0,0,22.734,27.258Zm3.75,0a1.172,1.172,0,1,0-1.172,1.172A1.175,1.175,0,0,0,26.484,27.258Z"
                          transform="translate(0 -0.075)" fill="#ffffff" />
                      </svg>
                      Charger contrats
                    </label>
                  </button>
                </div>
                <span *ngIf="reponse && insert != 'prixwakam'" style="color: green;white-space: nowrap; ">
                  <img class="mx-1 img-icon" src="/assets/svg/check-o-svgrepo-com.svg">
                  Insertion des contrats avec succés
                </span>
                <span *ngIf="reponse && insert == 'prixwakam'" style="color: green;white-space: nowrap;">
                  <img class="mx-1 img-icon" src="/assets/svg/check-o-svgrepo-com.svg">
                  Envoi du fichier avec succés
                </span>
                <span *ngIf="erreur" style="color: red;white-space: nowrap;">
                  <!--<img class="mx-1 img-icon" src="/assets/svg/echec.svg">-->
                  Échec de l'insertion des contrats
                </span>

              </div>


              <div *ngIf="choixmenu == 'resiliation'">
                Creation Tickets<mat-slide-toggle class="example-margin" color="primary" (change)="onChange($event)">
                  Résiliation des Contrats
                </mat-slide-toggle>
                <hr size="3" width="100%" color="black">
                <div *ngIf="!checked">
                  <fieldset>
                    <div style="display: flex; flex-direction: column;justify-content: center;align-items: center;">
                      <label>ApiKey :</label>
                      <input type="text" id="key" name="key" placeholder="APIKEY" [(ngModel)]="apikey" style="width: 200px;" >
                    </div>

                    
                    <hr size="6" width="100%" color="black">
                    <div style="display: flex;flex-direction: row;justify-content: space-evenly;align-items: center;">
                      <div>
                        <input type="radio" name="onedoc" [(ngModel)]="justificatif" [value]="true"> Utiliser un seul justificatif
                      </div>
                      <div>
                        <input type="radio" name="alldocs" [(ngModel)]="justificatif" [value]="false"> Utiliser plusieurs justificatifs
                      </div>
                    </div>

                    <div *ngIf="justificatif == true" style="display: flex; flex-direction: column;justify-content: center;align-items: center;">
                      <button class="ButtonTelechargement"
                    style="background-color: rgba(0, 0, 0, 0.87); color: white; width: 100%; justify-content: center;">
                    <input type="file" id="filldocs" (change)="fill_Documents($event)" multiple="multiple" hidden />
                    <label for="filldocs">
                      <svg class="mx-2 iconup1" xmlns="http://www.w3.org/2000/svg" width="20" height="29.996"
                        viewBox="0 0 30 29.996">
                        <path id="upload-solid"
                          d="M17.344,22.571H12.656a1.4,1.4,0,0,1-1.406-1.406V11.321H6.111a1.17,1.17,0,0,1-.826-2L14.2.4a1.135,1.135,0,0,1,1.6,0l8.918,8.918a1.17,1.17,0,0,1-.826,2H18.75v9.844A1.4,1.4,0,0,1,17.344,22.571ZM30,22.1v6.563a1.4,1.4,0,0,1-1.406,1.406H1.406A1.4,1.4,0,0,1,0,28.664V22.1A1.4,1.4,0,0,1,1.406,20.7H9.375v.469a3.283,3.283,0,0,0,3.281,3.281h4.688a3.283,3.283,0,0,0,3.281-3.281V20.7h7.969A1.4,1.4,0,0,1,30,22.1Zm-7.266,5.156a1.172,1.172,0,1,0-1.172,1.172A1.175,1.175,0,0,0,22.734,27.258Zm3.75,0a1.172,1.172,0,1,0-1.172,1.172A1.175,1.175,0,0,0,26.484,27.258Z"
                          transform="translate(0 -0.075)" fill="#ffffff" />
                      </svg>
                      Charger les justificatifs
                    </label>
                  </button>
                  <label>Le nombre des justificatifs chargés : {{files_justificatifs_count}} justificatifs</label>
                  </div>

                    <div *ngIf="justificatif == false" style="display: flex; flex-direction: column;justify-content: center;align-items: center;">
                      <button class="ButtonTelechargement"
                    style="background-color: rgba(0, 0, 0, 0.87); color: white; width: 100%; justify-content: center;">
                    <input type="file" id="filldocs" (change)="fill_Documents($event)" multiple="multiple" hidden />
                    <label for="filldocs">
                      <svg class="mx-2 iconup1" xmlns="http://www.w3.org/2000/svg" width="20" height="29.996"
                        viewBox="0 0 30 29.996">
                        <path id="upload-solid"
                          d="M17.344,22.571H12.656a1.4,1.4,0,0,1-1.406-1.406V11.321H6.111a1.17,1.17,0,0,1-.826-2L14.2.4a1.135,1.135,0,0,1,1.6,0l8.918,8.918a1.17,1.17,0,0,1-.826,2H18.75v9.844A1.4,1.4,0,0,1,17.344,22.571ZM30,22.1v6.563a1.4,1.4,0,0,1-1.406,1.406H1.406A1.4,1.4,0,0,1,0,28.664V22.1A1.4,1.4,0,0,1,1.406,20.7H9.375v.469a3.283,3.283,0,0,0,3.281,3.281h4.688a3.283,3.283,0,0,0,3.281-3.281V20.7h7.969A1.4,1.4,0,0,1,30,22.1Zm-7.266,5.156a1.172,1.172,0,1,0-1.172,1.172A1.175,1.175,0,0,0,22.734,27.258Zm3.75,0a1.172,1.172,0,1,0-1.172,1.172A1.175,1.175,0,0,0,26.484,27.258Z"
                          transform="translate(0 -0.075)" fill="#ffffff" />
                      </svg>
                      Charger les justificatifs
                    </label>
                  </button>
                  
                      <label>Le nombre des justificatifs chargés : {{files_justificatifs_count}} justificatifs</label>
                      <label style="font-weight: bold;width: 500px;display: block;">* Les justificatifs de résiliation doivent ètre nommés par le numéro du contrat </label>
                      
                  </div>

                    <hr size="6" width="100%" color="black">


                    <div style="display: flex; flex-direction: column;justify-content: center;align-items: center;">
                      <label>Email :</label>
                      <input type="text" id="email" name="email" placeholder="EMAIL" [(ngModel)]="email"
                        style="width: 200px;">

                      <label style="width: 300px;display: block;">*Ce champs vous permettera de revcevoir l'excel rempli
                        avec numero des tickets</label>
                    </div>
                    

                    <hr size="5" width="100%" color="black">

                  </fieldset>
                  <div style="display: flex; flex-direction: column;justify-content: center;align-items: center;" >
                    <label style="font-weight: bold;width: 300px;display: block;">Voilà les causes de résiliation possibles :
                      </label>
                    <table style="padding: 10px;border: 1px solid rgb(48, 48, 48);border-collapse: collapse;width: 100%;">
                      <tr style="padding: 10px;border: 1px solid rgb(48, 48, 48);border-collapse: collapse;">
                        <th>Cause du résiliation</th>
                      </tr>
                      <tr>
                        <td>Droit de renonciation</td>
                      </tr>
                      <tr>
                        <td>Résiliation à échéance</td>
                      </tr>
                      <tr>
                        <td>Changement de domicile</td>
                      </tr>
                      <tr>
                        <td>Changement de situation</td>
                      </tr>
                      <tr>
                        <td>Changement de régime matrimonial</td>
                      </tr>
                      <tr>
                        <td>Changement de profession</td>
                      </tr>
                      <tr>
                        <td>Retraite professionnelle</td>
                      </tr>
                      <tr>
                        <td>Cessation définitive activité</td>
                      </tr>
                      <tr>
                        <td>Autre</td>
                      </tr>
                    </table>
                    <label style="font-weight: bold;width: 800px;display: block;">* En cas ou la cause est différente de ces possibilités ci dessus ,
                    vous pouvez tout simplement faire le choix "Autre" et insérer la cause désirée au niveau de la colone "Motif de resiliation commentaire" dans l'excel</label>
                
                  </div>
                </div>

                <div *ngIf="checked">
                  <fieldset>
                    <div style="display: flex; flex-direction: column;justify-content: center;align-items: center;">
                      <label>ApiKey :</label>
                      <input type="text" id="key" name="key" placeholder="APIKEY" [(ngModel)]="apikey" style="width: 200px;" >
                    </div>

                    <hr size="6" width="100%" color="black">

                  </fieldset>
                </div>
                <div>

                  <button (click)="onClickDownloadPdfRes()" class="ButtonTelechargement" mat-raised-button
                    style="background-color: rgba(0, 0, 0, 0.87); color: white; width: 250px; justify-content: center;">
                    <svg class="mx-2 iconup1" xmlns="http://www.w3.org/2000/svg" width="30" height="35"
                      viewBox="0 0 30 29.996">
                      <path
                        d="M16.707 7.404c-.189-.188-.448-.283-.707-.283s-.518.095-.707.283l-2.293 2.293v-6.697c0-.552-.448-1-1-1s-1 .448-1 1v6.697l-2.293-2.293c-.189-.188-.44-.293-.707-.293s-.518.105-.707.293c-.39.39-.39 1.024 0 1.414l4.707 4.682 4.709-4.684c.388-.387.388-1.022-.002-1.412zM20.987 16c0-.105-.004-.211-.039-.316l-2-6c-.136-.409-.517-.684-.948-.684h-.219c-.094.188-.21.368-.367.525l-1.482 1.475h1.348l1.667 5h-13.893l1.667-5h1.348l-1.483-1.475c-.157-.157-.274-.337-.367-.525h-.219c-.431 0-.812.275-.948.684l-2 6c-.035.105-.039.211-.039.316-.013 0-.013 5-.013 5 0 .553.447 1 1 1h16c.553 0 1-.447 1-1 0 0 0-5-.013-5z"
                        fill="#ffffff" />
                    </svg>
                    Télécharger
                    Fichier
                    Excel</button>
                  <button class="ButtonTelechargement"
                    style="background-color: rgba(0, 0, 0, 0.87); color: white; width: 250px; justify-content: center;">
                    <input type="file" id="idCerti" (change)="getTransmissionRes($event)" accept=".xlsx" hidden />
                    <label for="idCerti">
                      <svg class="mx-2 iconup1" xmlns="http://www.w3.org/2000/svg" width="20" height="29.996"
                        viewBox="0 0 30 29.996">
                        <path id="upload-solid"
                          d="M17.344,22.571H12.656a1.4,1.4,0,0,1-1.406-1.406V11.321H6.111a1.17,1.17,0,0,1-.826-2L14.2.4a1.135,1.135,0,0,1,1.6,0l8.918,8.918a1.17,1.17,0,0,1-.826,2H18.75v9.844A1.4,1.4,0,0,1,17.344,22.571ZM30,22.1v6.563a1.4,1.4,0,0,1-1.406,1.406H1.406A1.4,1.4,0,0,1,0,28.664V22.1A1.4,1.4,0,0,1,1.406,20.7H9.375v.469a3.283,3.283,0,0,0,3.281,3.281h4.688a3.283,3.283,0,0,0,3.281-3.281V20.7h7.969A1.4,1.4,0,0,1,30,22.1Zm-7.266,5.156a1.172,1.172,0,1,0-1.172,1.172A1.175,1.175,0,0,0,22.734,27.258Zm3.75,0a1.172,1.172,0,1,0-1.172,1.172A1.175,1.175,0,0,0,26.484,27.258Z"
                          transform="translate(0 -0.075)" fill="#ffffff" />
                      </svg>
                      Charger le Fichier Excel
                    </label>
                  </button>
                </div>
                <span *ngIf="reponse && insert != 'prixwakam'" style="color: green;white-space: nowrap; ">
                  <img class="mx-1 img-icon" src="/assets/svg/check-o-svgrepo-com.svg">
                  Insertion des contrats avec succés
                </span>
                <span *ngIf="reponse && insert == 'prixwakam'" style="color: green;white-space: nowrap;">
                  <img class="mx-1 img-icon" src="/assets/svg/check-o-svgrepo-com.svg">
                  Envoi du fichier avec succés
                </span>
                <span *ngIf="erreur" style="color: red;white-space: nowrap;">
                  <!--<img class="mx-1 img-icon" src="/assets/svg/echec.svg">-->
                  Échec de l'action
                </span>

              </div>

              <div *ngIf="choixmenu == 'avenant'">
                <hr size="3" width="100%" color="black">
               
                  <fieldset>
                    <div style="display: flex; flex-direction: column;justify-content: center;align-items: center;">
                      <label>ApiKey :</label>
                      <input type="text" id="key" name="key" placeholder="APIKEY" [(ngModel)]="apikey" style="width: 200px;" >
                    </div>
                    

                    <hr size="5" width="100%" color="black">

                  </fieldset>
               
                

                <div>

                  <button (click)="onClickDownloadPdfAv()" class="ButtonTelechargement" mat-raised-button
                    style="background-color: rgba(0, 0, 0, 0.87); color: white; width: 250px; justify-content: center;">
                    <svg class="mx-2 iconup1" xmlns="http://www.w3.org/2000/svg" width="30" height="35"
                      viewBox="0 0 30 29.996">
                      <path
                        d="M16.707 7.404c-.189-.188-.448-.283-.707-.283s-.518.095-.707.283l-2.293 2.293v-6.697c0-.552-.448-1-1-1s-1 .448-1 1v6.697l-2.293-2.293c-.189-.188-.44-.293-.707-.293s-.518.105-.707.293c-.39.39-.39 1.024 0 1.414l4.707 4.682 4.709-4.684c.388-.387.388-1.022-.002-1.412zM20.987 16c0-.105-.004-.211-.039-.316l-2-6c-.136-.409-.517-.684-.948-.684h-.219c-.094.188-.21.368-.367.525l-1.482 1.475h1.348l1.667 5h-13.893l1.667-5h1.348l-1.483-1.475c-.157-.157-.274-.337-.367-.525h-.219c-.431 0-.812.275-.948.684l-2 6c-.035.105-.039.211-.039.316-.013 0-.013 5-.013 5 0 .553.447 1 1 1h16c.553 0 1-.447 1-1 0 0 0-5-.013-5z"
                        fill="#ffffff" />
                    </svg>
                    Télécharger
                    Fichier
                    Excel</button>
                  <button class="ButtonTelechargement"
                    style="background-color: rgba(0, 0, 0, 0.87); color: white; width: 250px; justify-content: center;">
                    <input type="file" id="idCerti" (change)="getTransmissionAv($event)" accept=".xlsx" hidden />
                    <label for="idCerti">
                      <svg class="mx-2 iconup1" xmlns="http://www.w3.org/2000/svg" width="20" height="29.996"
                        viewBox="0 0 30 29.996">
                        <path id="upload-solid"
                          d="M17.344,22.571H12.656a1.4,1.4,0,0,1-1.406-1.406V11.321H6.111a1.17,1.17,0,0,1-.826-2L14.2.4a1.135,1.135,0,0,1,1.6,0l8.918,8.918a1.17,1.17,0,0,1-.826,2H18.75v9.844A1.4,1.4,0,0,1,17.344,22.571ZM30,22.1v6.563a1.4,1.4,0,0,1-1.406,1.406H1.406A1.4,1.4,0,0,1,0,28.664V22.1A1.4,1.4,0,0,1,1.406,20.7H9.375v.469a3.283,3.283,0,0,0,3.281,3.281h4.688a3.283,3.283,0,0,0,3.281-3.281V20.7h7.969A1.4,1.4,0,0,1,30,22.1Zm-7.266,5.156a1.172,1.172,0,1,0-1.172,1.172A1.175,1.175,0,0,0,22.734,27.258Zm3.75,0a1.172,1.172,0,1,0-1.172,1.172A1.175,1.175,0,0,0,26.484,27.258Z"
                          transform="translate(0 -0.075)" fill="#ffffff" />
                      </svg>
                      Charger le Fichier Excel
                    </label>
                  </button>
                </div>
                <span *ngIf="reponse && insert != 'prixwakam'" style="color: green;white-space: nowrap; ">
                  <img class="mx-1 img-icon" src="/assets/svg/check-o-svgrepo-com.svg">
                  Insertion des contrats avec succés
                </span>
                <span *ngIf="reponse && insert == 'prixwakam'" style="color: green;white-space: nowrap;">
                  <img class="mx-1 img-icon" src="/assets/svg/check-o-svgrepo-com.svg">
                  Envoi du fichier avec succés
                </span>
                <span *ngIf="erreur" style="color: red;white-space: nowrap;">
                  <!--<img class="mx-1 img-icon" src="/assets/svg/echec.svg">-->
                  Échec de l'action
                </span>

              </div>

              <div *ngIf="choixmenu == 'pay'">
                <hr size="3" width="100%" color="black">
               
                  <fieldset>
                    <div style="display: flex; flex-direction: column;justify-content: center;align-items: center;">
                      <label>ApiKey :</label>
                      <input type="text" id="key" name="key" placeholder="APIKEY" [(ngModel)]="apikey" style="width: 200px;" required>
                    </div>

                    <div style="display: flex; flex-direction: column;justify-content: center;align-items: center;">
                      <label>Secret :</label>
                      <input type="text" id="secret" name="secret" placeholder="SECRETKEY" [(ngModel)]="secret" style="width: 200px;" required>
                    </div>
                    

                    <hr size="5" width="100%" color="black">
                    <div style="display: flex; flex-direction: column;justify-content: center">
                      <div style="display: flex; flex-direction: column;justify-content: center;">
                        <div style="display: flex; flex-direction: row;justify-content: center;align-items: center;">
                      <label style="font-weight: bolder;font-size: large;">Information Client </label>
                      <i class="fa fa-info-circle" aria-hidden="true" title="Les informations de Client doivent ètres précises à partir de Stripe !" style="font-size:24px;color:rgba(17, 16, 16, 0.788);padding-left: 2%;"></i>
                      </div>
                      <div style="display: flex; flex-direction: column;justify-content: center;align-items: center;">
                        <hr size="3" width="80%" color="black">
                      </div>
                      </div>
                      <div style="display: flex;flex-direction: row;justify-content: space-between;align-items: center;">

                      <div style="display: flex;flex-direction: row;justify-content: space-between;align-items: center;">
                        <input type="text" id="lastname" name="lastname" placeholder="Nom" [(ngModel)]="lastNamePay" style="width: 200px;" required>
                      </div>
                      
                      <input type="text" id="fistname" name="fistname" placeholder="Prénom" [(ngModel)]="fistNamePay" style="width: 200px;" required>
                      <input type="text" id="email" name="email" placeholder="Email" [(ngModel)]="mailPay" style="width: 200px;" required>
                      </div>
                      <div style="display: flex; flex-direction: column;justify-content: center;align-items: center;">
                      <hr size="3" width="80%" color="black">
                      </div>
                      <div style="display: flex;flex-direction: row;justify-content: space-between;align-items: center;">
                      <input type="text" id="customerid" name="customerid" placeholder="Client Stripe ID" [(ngModel)]="customerIdPay" style="width: 200px;" required>
                      <input type="text" id="iban" name="iban" placeholder="IBAN" [(ngModel)]="ibanPay" style="width: 200px;" required>
                    </div>
                      
                    </div>

                    <hr size="5" width="100%" color="black">
                    <div style="display: flex; flex-direction: column;justify-content: center;align-items: center;">
                      <label style="font-weight: bolder;font-size: large;" >Montant à payer</label>
                      <input type="number" id="amount" name="amount" placeholder="Montant" [(ngModel)]="amountPay" style="width: 200px;" required>
                    </div>



                  </fieldset>
                  <hr size="5" width="100%" color="black">

                  <button class="ButtonPay" (click)="paiment_stripe()">
                    <label style="font-size: x-large;font-weight: bolder;">
                      Payer
                      <span>&#8364;</span>
                    </label>
                  </button>
               
                
                <span *ngIf="reponse" style="color: green;white-space: nowrap; ">
                  <img class="mx-1 img-icon" src="/assets/svg/check-o-svgrepo-com.svg">
                  Action avec succés
                </span>

                <span *ngIf="erreur" style="color: red;white-space: nowrap;">
                  <!--<img class="mx-1 img-icon" src="/assets/svg/echec.svg">-->
                  Échec de l'action
                </span>

              </div>
            </div>
          </div>


          <div *ngIf="choixmenu != 'pay'">
            <h4 style="color: red;margin-top:70px">*Il ne faut jamais changer les paramètres du fichier excel Téléchargé !
            </h4>
            <h4 style="color: red;">*Il faut bien vérifier les données de fichier à charger !</h4>
          </div>

        </div>

      </div>


    </div>
    <div *ngIf="loading">
      <div class="loader-background" style="display: flex;flex-direction: column;align-items: center;justify-content: center;">
        <div class="bike-riding">
          <div class="clouds"></div>
          <div class="shadow"></div>
          <div class="road"></div>
        <div class="cyclist" style="transform: rotate(-0.1turn);">
          <div class="bike">
            <div class="leftTyre">
              <div class="spokes"></div>
            </div>
            <div class="rightTyre">
              <div class="spokes"></div>
            </div>
            <div class="wheel"></div>
            <div class="pedals"></div>
            <div class="chain"></div>
          </div>
          <div class="girl">
            <div class="top"></div>
            <div class="rightArm"></div>
            <div class="leftArm"></div>
            <div class="head"></div>
            <div class="hair"></div>
            <div class="strap"></div>
          <div class="trousers">
              <div class="leftLeg">
                <div class="leftcalf"></div>
              </div>
              <div class="rightLeg">
                <div class="calf"></div>
              </div>
            </div>
            </div> 
        </div>
        </div>
      </div>
    </div>
  </div>

</div>