import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ApicreationService } from 'src/app/core/services/apicreationservice.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-assistance',
  templateUrl: './assistance.component.html',
  styleUrls: ['./assistance.component.css']
})
export class AssistanceComponent implements OnInit {
  @Output()changepagenumber : EventEmitter<number> = new EventEmitter();
  Non :string ="Non";
  retour :string ="Retour";
  Oui :string ="Oui";
 
  protected pathFiles = environment.Pathfiles;
  protected nameFilesNi = environment.namefilesNi;
  protected nameFilesIpidAssistance=environment.namefilesIpidAssistance
  constructor(public sanitizer: DomSanitizer,private apicreationService : ApicreationService,) { }

  ngOnInit(): void {
  
    this.apicreationService.insuranceinfo.isAssistance=false; 
  }


  Previouspage(valueemitted :any){
        this.changepagenumber.emit(valueemitted);
    
  }



  Nextpage(event :boolean){
    this.apicreationService.insuranceinfo.isAssistance=event;
    this.changepagenumber.emit(1 )
  }

  goToIPID() {
    window.open(`${this.pathFiles}${this.nameFilesIpidAssistance}`, "_blank");
  }

  goToNI() {
    window.open(`${this.pathFiles}${this.nameFilesNi}`, "_blank");
  } 
}
