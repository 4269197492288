import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { ApicreationService } from 'src/app/core/services/apicreationservice.service';
import { GeneriqueService } from 'src/app/core/services/Generiqservice/generiqueservice.service';
import { QuotationService } from 'src/app/core/services/quotation/quotationservice.service';
import { Apisuccessresponse } from 'src/app/shared/models/apisuccessresponse';
import { Bike } from 'src/app/shared/models/bike';
import { Client } from 'src/app/shared/models/client';
import { Insuranceinfo } from 'src/app/shared/models/insuranceinfo';
import { References } from 'src/app/shared/models/references';
import { ChangeDetectorRef } from '@angular/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { HostListener } from "@angular/core";
import { FormGroup } from '@angular/forms';
import {STEPPER_GLOBAL_OPTIONS} from '@angular/cdk/stepper';
import { Subscribe_body } from 'src/app/shared/models/subscribe_body';
import { Stripe_body } from 'src/app/shared/models/pay-stripe-body';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-clickvelo',
  templateUrl: './clickvelo.component.html',
  styleUrls: ['./clickvelo.component.css'],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: {displayDefaultIndicatorType: false},
    },
  ],
})
export class ClickveloComponent implements OnInit {
  isLinear = false;
  isEditable = true;
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  pagedata: number = 1;
  mobile:boolean=false ; 
  TotalAmount: number;
  loader_garantie: boolean = false;
  public insuranceinfo: Insuranceinfo = new Insuranceinfo();
  state = 'shown';
  windowwidth : number  ; 
  valuechangeEmitted: boolean | undefined;
  client: Client = new Client();
  stripeBody : Stripe_body = new Stripe_body();
  Esign_body : Insuranceinfo = new Insuranceinfo();
  bike: Bike = new Bike();
  storeRef: string = '';
  name: string = '';
  email: string = '';
  reference: string = '';
  useStripe: boolean = environment.useStripe;
  civilite: string = '';
  subscribe_body:Subscribe_body;

  disabledformula:boolean ;
  constructor(private cdRef:ChangeDetectorRef,private generiqueService: GeneriqueService,
    private apicreationService: ApicreationService, private activatedRoute: ActivatedRoute, private quotationService: QuotationService,) {
      
    this.apicreationService.listenInfoBike().subscribe((m: any) => {
      this.doSomethingbike(m);
    })
    this.apicreationService.listenInfoClient().subscribe((m: any) => {
      this.doSomethingclient(m);
    })
    
    this.apicreationService.listenSubscribe().subscribe((m: any) => {
      this.name = this.insuranceinfo.client.lastName +' ' +this.insuranceinfo.client.firstName;
      this.email = this.insuranceinfo.client.email;
      this.reference = m.insurance.references.policyRef;
      this.civilite =this.insuranceinfo.client.title;
    })
   
   
  }
  @HostListener('window:resize', ['$event'])
  onResize(event:any) {
    this.windowwidth =window.screen.width ;
     if ( this.windowwidth < 900 )
     {
       this.mobile=true ; 
     }
  }
  ngAfterViewChecked()
  {
   
    this.cdRef.detectChanges();
  }
  ngOnInit(): void {

    this.pagedata=1
    this.windowwidth =window.screen.width ;
     if ( this.windowwidth < 900 )
     {
       this.mobile=true ; 
     }
    

  }
  
  changepage(valueemitted: any) {
    if ( valueemitted === 13)
    this.pagedata = 13 ; 
    else if ( valueemitted === 14)
    this.pagedata = 14 ; 
    else if ( valueemitted === 9)
    this.pagedata = 9 ; 
    else if ( valueemitted === 8)
    this.pagedata = 8 ; 
    else 
    this.pagedata = this.pagedata + valueemitted;
     if ( valueemitted.event !== undefined )
     {
      //  if ( valueemitted.number = 2 )
      //  {
      //    this.openiframe() ; 

      //  }
     }
    
  }
  public doSomethingclient(data: any): void {
    this.insuranceinfo.client = data
  }

  public doSomethingbike(data: any): void {
   

    this.activatedRoute.paramMap.subscribe((params: ParamMap)=> {
      
      this.storeRef = params.get('storeRef')!;
    
    });
   
      this.insuranceinfo.CommercialLatitude = "0%";
      this.insuranceinfo.CommissionRate = "";
      this.insuranceinfo.references = new References();
      this.insuranceinfo.references.storeRef = " ";
      this.insuranceinfo.references.eSignRef = '';
      this.insuranceinfo.references.paymentRef = '';
      this.insuranceinfo.references.opportunityRef = '';
      this.insuranceinfo.references.policyRef = '';
      this.insuranceinfo.references.quoteRef = '';
      this.loader_garantie = true;
      this.insuranceinfo.bike = data ; 
      this.apicreationService.insuranceinfo = this.insuranceinfo ;
      this.quotationService.Getinsurancerate(this.insuranceinfo)
        .subscribe(
          (data: Apisuccessresponse) => {
            this.apicreationService.pushInforate( data) 
            this.loader_garantie = false;
          },
          (error: any) => {
            this.apicreationService.pushInforate( undefined) 
            this.loader_garantie = false;
            if ( error.code ===524 ||error.error.code ===524  )
            this.generiqueService.alertWarning("Un délai d'attente s'est produit, veuillez réessayer");
             else if ( error.code ===404|| error.error.code ===404 || error.code ===500 || error.error.code ===500 || error.error.code ===503)
             this.generiqueService.alertWarning("erreur serveur!");
             else
            this.generiqueService.alertWarning(error);
          
            return;
          }


        );
    


  }


  hideloader() {
    document.getElementById('loadingBar')!
      .style.display = 'none';
  }

  showloader() {
    document.getElementById('loadingBar')!
      .style.display = 'block';
  }

}


