


import { Component, ElementRef, EventEmitter, HostListener, Input, ViewChild } from "@angular/core";
import { DomSanitizer, SafeUrl } from "@angular/platform-browser";
import { NgbActiveModal, NgbModal, NgbModalOptions } from "@ng-bootstrap/ng-bootstrap"
import { ApicreationService } from "src/app/core/services/apicreationservice.service";


@Component({
  selector: 'ngbd-modal-content',
  styleUrls: ['./model.component.css'],
  templateUrl: './model.component.html',
 
})
export class NgbdModalContent {
  @Input() public  srcurl: SafeUrl ;
  @Input() public  loader_garantie: Boolean ;
  public event: EventEmitter<any> = new EventEmitter();
  public loader_Paiment: boolean = false; 
  public loading: boolean = false;
  passToSub: boolean = false;

  constructor( public activeModal: NgbActiveModal,private apicreationService : ApicreationService,private modalService: NgbModal, public sanitizer: DomSanitizer) {
    this.apicreationService.listenpasstosubscribe().subscribe(m => 
      {
        this.passToSub = m;
      }
  )
  
   }
   passauSub(){
    this.event.emit();
  }

}
 