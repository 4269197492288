
<div class="container-fluid">
  <div class="card">
    <div class="card-header">
      <div class="container">
        <div class="row rowmobile">
          <div class="col-md-12 centertext">
            <h3 class="m-0">Vie Privée</h3>
          </div>
    
        </div>
      </div>
    </div>
    <div class="card-body">
      <div class="container">
        <div class="row"> 
        <div class="col-8"> 

        <label> Protection des données à caractère personnel
        </label><br /><br />
        <span>
            D’une manière générale, il vous est possible de visiter le site de Berilish sans communiquer aucune information personnelle vous concernant.
            En toute hypothèse, vous êtes en aucune manière obligé de transmettre ces informations à Berilish.
            Néanmoins, en cas de refus, il se peut que vous ne puissiez pas bénéficier de certaines informations ou services que vous avez demandés.
            A ce titre, Berilish peut être amené dans certains cas à vous demander de renseigner votre nom, prénom, adresse mail, numéro de téléphone (ci-après « Les données traitées »).
            En fournissant ces informations, vous acceptez expressément qu’elles soient traitées par la société Berilish et ces différentes entités filiales et succursales, 
            aux fins indiquées ci-dessous ainsi qu’aux fins rappelées à la fin de chaque formulaire.
            Conformément au Règlement Général sur la Protection des Données du 25 mai 2018 RGPD adopté par le Parlement européen 
            le 14 avril 2016, et à la Loi Informatique et Libertés du 6 janvier 1978 modifiée, Berilish vous informe des points suivants :
       </span><br /><br />



       <label> Identité et coordonnées du responsable du traitement :

    </label><br /><br />
    <span>
        Les responsables du traitement:
        <ul>
          <li>  la société Berilish, ayant son siège social a 54 AV DE LA MOTTE PICQUET - 75015 PARIS – France. Et ces différentes entités filiales et succursales.
            Tél : + 33 (0)4 83 36 80 32 ; Fax : + 33 (0)4 83 36 80 34.</li>
          <li>        Wakam Société Anonyme au capital de 4 452 016 Euros, immatriculée au RCS de Paris sous le numéro 562 117 085, ayant son siège social 120-122 rue Réaumur – 75002 Paris. <a  target="_blank" href=" https://www.wakam.com/politique-de-confidentialite/">

            ( VOIR LA POLITIQUE DE CONFIDENTIALITE DE WAKAM )
          </a></li>

      </ul>
       


   </span><br /><br />

  </div>

  <div class="col-4" style="border-radius:15px;  border:1px  solid #5d778d;  "   >
    <label > Demande de suppression de données personnelles
     </label> 
    <form>
      <div class="form-group mt-2">
        <label  class="labelTitre" for="exampleInputEmail1">Nom</label>
        <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="nom">
    
      </div>
         <div  class="form-group mt-2">
        <label  class="labelTitre"  for="exampleInputEmail1">Prenom</label>
        <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Prenom">
    
      </div>

      <div class="form-group mt-2">
        <label   class="labelTitre" for="exampleInputEmail1">Année de naissance</label>
        <input type="date" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Année de naissance">
    
      </div>

      <div class="form-group mt-2">
        <label  class="labelTitre"  for="exampleInputEmail1">Email </label>
        <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="email">
      </div>
      <div class="form-group mt-2">
        <label   class="labelTitre" for="exampleInputPassword1">Telephone</label>
        <input type="text" class="form-control" id="exampleInputPassword1" placeholder="Telephone">
      </div>

      <button type="submit"  style="border-radius:15px;  border:1px  solid #5d778d;  "  class="mt-4 btn btn-primary">supprimer</button>
    </form>
  </div>

</div>

   <label> Les données traitées :</label><br /><br />
<span>
    Selon le cas, le site « Berilish » traite les données personnelles, directement collectées auprès du l’Utilisateur. La société Berilish ses différentes filiales et succursales sont également susceptible d’être destinataire de données qui ont été collectées par un tiers auprès de l’Utilisateur.
    Le site « Berilish » ne traite aucune donnée ou une catégorie de données que si elle est strictement nécessaire à la finalité poursuivie.
    Vous pouvez retrouver des informations sur ces finalités définies à l'article « A quoi servent les données ? ».
    La société Berilish traite les catégories de données suivantes :

    <ul>
        <li> Données d’identification : Nom, prénom, carte nationale d’identité, …</li>
        <li>Données de contact : Adresse postale, email, numéro de téléphone…</li>
        <li>Données familiales : Situation familiale, nombre d’enfants, …</li>
        <li>Données professionnelles</li>
        <li>Produits et services détenus ou utilisés</li>
    </ul>
   
</span><br /><br />
    

<label> A quoi servent les données ?

</label><br /><br />
<span>
    Les données à caractère personnel de l'Utilisateur collectées sur le site « Berilish » font l'objet de traitements informatiques par la société Berilish ses filiales et ses succursales, pour la réalisation des Services, le fonctionnement des Outils et Moteurs de Recherche proposés par l'intermédiaire du Site, décrits à l’article «V- Informations sur les Services et les Outils proposés ».
    Dans l'intérêt de la société Berilish, les données à caractère personnel communiquées par l'Utilisateur peuvent faire l'objet de traitements pour l’informer des différentes offres d'assurances ou de services commercialisés par tout moyen de communication détenus par les différentes entités de la société Berilish, filiales et succursales en exclusivité.
    Si l'Utilisateur l'accepte, il peut également recevoir les offres des partenaires de la société Berilish.
    L'Utilisateur peut à tout moment faire valoir ses choix et, le cas échéant, son opposition à la prospection selon les modalités décrites ci-après dans la rubrique « Vos droits »).
</span><br /><br />





<label> Destinations des données :
</label><br /><br />
<span>
    En fournissant ces informations, l’Utilisateur accepte expressément qu’elles soient traitées par les entités de la société Berilish filiales et succursales en exclusivité, aux fins indiquées précédemment ainsi qu’aux fins rappelées à la fin de chaque formulaire.
    Lorsque l'Utilisateur demande à être mis en relation avec un conseiller commercial par le biais des formulaire « ÊTRE RAPPELÉ(e) », « RECEVOIR UN DEVIS », « NOUS VOUS RAPPELONS », «  RENCONTRER UN CONSEILLER », « SOUSCRIRE » ou sans le demander, il autorise à la société Berilish, ses différentes entités filiales et Succursales concernées, situées sur le territoire français ou en dehors de l'Union Européenne, à communiquer aux conseillers(ères), commerciaux(alles), salarié(e)s ou mandataires de la société Berilish les informations enregistrées afin que ce dernier puisse contacter l’Utilisateur en temps réel pour finaliser son dossier ou le conseiller.
    L’Utilisateur consent à être démarché ultérieurement dans les limites de la durée légale de conservation des données suivant la réglementation RGPD, ‘‘3 ans pour les données inactives et recueil du consentement pour les cookies au-delà de 13 mois’’.
    Les données collectées sont destinées aux services internes de la société Berilish et ses différentes entités, filiales et succursales.
    Les données peuvent également être traitées par des partenaires de Berilish.
    Il s’agit des situations où des partenaires interviennent pour la fourniture de prestations.
    Il peut également s’agir de traitements pour lesquels un consentement est sollicité.
</span><br /><br />



<label> Durée de conservation     :
</label><br /><br />
<span>
    Berilish ne conserve pas vos données au-delà de la durée nécessaire pour les opérations pour lesquelles elles ont été collectées 
    (assistance par exemple), ou de celles prévues par les normes et autorisations de la CNIL (pack de conformité pour le secteur de l’assurance)
     ou par la loi (telles que les prescriptions légales),
</span><br /><br />

<label> Vos droits :
</label><br /><br />
<span>
    Berilish ne conserve pas vos données au-delà de la durée nécessaire pour les opérations pour lesquelles elles ont été collectées (assistance par exemple), ou de celles prévues par les normes et autorisations de la CNIL (pack de conformité pour le secteur de l’assurance)
     ou par la loi (telles que les prescriptions légales),

    <ul>
        <li>Droit d’accès : l’utilisateur peut accéder à ses données pour les modifier, ou bien demander une copie de ses informations personnelles.
        </li>
        <li>Droit de rectification : l’utilisateur peut demander à Berilish la correction d’informations inexactes sur sa base de données.
        </li>
        <li>Droit à l’effacement : l’utilisateur peut demander la suppression de ses données personnelles en remplissant le formulaire de « droit à l’oubli ».
        </li>
        <li>Droit d’opposition : l’utilisateur peut s’opposer à tout moment au traitement de ses données personnelles.
        </li>
        <li>Droit de limitation du traitement : l’utilisateur peut demander la suspension d’un traitement le concernant le temps d’une vérification.
        </li>
        <li>Droit à la portabilité : l’utilisateur peut obtenir les données personnelles qu’il a fourni.
        </li>
    </ul>
    Vous pouvez exercer vos droits en contactant notre DPO dpo@Berilish.fr ou bien en nous écrivant à l’adresse postale Berilish, 54 AV DE LA MOTTE PICQUET - 75015 PARIS , en joignant une photocopie de sa pièce d'identité (recto-verso).
    
</span><br /><br />


<label> Délais de réponse :
</label><br /><br />
<span>
    Le site « Berilish » s’engage à répondre à votre demande d’accès, de rectification ou d’opposition ou toute autre demande complémentaire d’informations dans un délai raisonnable qui ne saurait dépasser un mois à compter de la réception de votre demande.
    Toutefois la société Berilish met en données vos contacts dans une liste d’opposition pour qu’aucune sollicitation ne soit effectuée dans le futur.
    
</span><br /><br />




<label>Politique d’utilisation des cookies
</label><br /><br />

<ul>
    <li>Durée de conservation des cookies :</li>
</ul>
<span>

La société Berilish, s’est doté d’une politique et d’une gouvernance dédiées à la protection des données à caractère personnel à l’international vu sa présence dans plusieurs pays.
Elles comprennent un contrôle strict des transferts des données de l’Utilisateur recueillies par le biais des divers formulaires présents sur le site « Berilish » et autres sites de la Société Berilish, en particulier lorsqu’ils se font hors de l’Union européenne et sa législation protectrice, dans des conditions conformes à la réglementation.
Les données de l’Utilisateur sont susceptibles d’être transférées hors Union Européenne, dans la mesure où c’est le cas, elles sont traitées en exclusivité par les entités du Groupe, filiales et succursales suivantes en-dehors de l’Union européenne : Une succursale de la Société Berilish située en Tunisie à des fins de gestion des contrats d’assurance et de télémarketing.
Une société de la Société Berilish située en Tunisie pour certaines opérations de maintenance informatique, certains traitements comptables, Ces transferts sont encadrés par des règles d'entreprise contraignantes et des clauses contractuelles types, au sujet desquelles vous pouvez obtenir un complément d'information auprès de dpo@Berilish.fr.
</span>

<br /><br />
<ul>
    <li>Droit d’opposition :    </li>
</ul>
<span>

    Les cookies peuvent être utilisés pour des fins statistiques notamment pour optimiser les services rendus à l'utilisateur, à partir du traitement des informations concernant la fréquence d'accès, la personnalisation des pages ainsi que les opérations réalisées et les informations consultées.
    Vous êtes informé que l'éditeur est susceptible de déposer des cookies sur votre terminal.
    Le cookie enregistre des informations relatives à la navigation sur le service (les pages que vous avez consultées, la date et l'heure de la consultation...) que nous pourrons lire lors de vos visites ultérieures.</span>


<br /><br />

      </div>
    </div>
  </div>
</div>
