import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-buttonretour',
  templateUrl: './buttonretour.component.html',
  styleUrls: ['./buttonretour.component.css']
})
export class ButtonretourComponent implements OnInit {
  @Input() test:number = 0;
  @Input()  label!: string;
  champlabel :boolean =false ;

  @Output()changepreviouspage : EventEmitter<number> = new EventEmitter();
  constructor() { }

  ngOnInit(): void {
    if ( this.label == 'Non')
    this.champlabel = true;

  }

  
  public Passpreviouspage(){
    this.changepreviouspage.emit(-1);
    
  }
}
