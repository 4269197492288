export class Bike {
    purchaseDate:string="";
    purchasePrice: number;
    classification: BikeclassificationEnum;
    type:BiketypeEnum;
    usage:BikeusageEnum;
    TrackerGPS:string;
    etchingNumber:string;
    maximumSpeed:number;
    serialNumber:string;
    brand:string;
    model:string;
}

export enum BiketypeEnum {
   Standard='Standard',
   Electric='Électrique',
    
  }
  export enum BikeclassificationEnum {
   'Road Bike'='Vélo de route',
   'Off-road Bike'='Vélo tout-terrain',
   'Racing Bike'='Vélo de course',
 }
 export enum BikeusageEnum {
   'Private Use and Commuting'='Usage privé et déplacements',
   'Professional Use'='Utilisation professionnelle',
 }
 export enum BikeTrackerGPSEnum {
   Yes='Oui',
   No='Non',   
 }