import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { Bike, BikeclassificationEnum, BiketypeEnum, BikeusageEnum , BikeTrackerGPSEnum } from 'src/app/shared/models/bike';
import { ApicreationService } from 'src/app/core/services/apicreationservice.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { GeneriqueService } from 'src/app/core/services/Generiqservice/generiqueservice.service';
import { DatePipe, DOCUMENT } from '@angular/common';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import swal from 'sweetalert2';

@Component({
  selector: 'app-fiche-velo',
  templateUrl: './fiche-velo.component.html',
  styleUrls: ['./fiche-velo.component.css']
})
export class FicheVeloComponent implements OnInit {
  @Input() pagedata:number = 0;
  status  :boolean = true;
  formBike: FormGroup;
  bikeusageEnum =BikeusageEnum;
  bikeGpsEnum =BikeTrackerGPSEnum;
  biketypeEnum=BiketypeEnum ;
  biketrackerEnum=BiketypeEnum ;
   @Output() ondatachanged = new EventEmitter<any>();
  bikeclassification=BikeclassificationEnum
  @Output()changepagenumber : EventEmitter<number> = new EventEmitter();
  retour:string  = "Retour"
  suivant:string ="Suivant";
  storeRef: string = '';
  formulaireCheck:boolean=false; 
  bike : Bike  = new Bike();
  myDate = new Date();
  constructor( private datePipe: DatePipe,private router: Router , @Inject(DOCUMENT) private document: Document, private generiqueService  :GeneriqueService,private apicreationService : ApicreationService, private activatedRoute: ActivatedRoute) {

 
  }

  ngOnInit(): void {
    this.myDate = new Date();

    this.apicreationService.checkedoption = 3 ; 
    this.apicreationService.rate = [];
    this.apicreationService.ratedata = [];
    this.bike = new Bike();
    this.formBike = new FormGroup({
      "Date dachat": new FormControl(),
      "Prix dachat": new FormControl( '', [Validators.min(100),Validators.max(8000)]),
      Classification: new FormControl(),
      Type: new FormControl(),
      Usage: new FormControl(),
      Gps: new FormControl(),
      Formulaire: new FormControl(),
      Formulairedeux: new FormControl(),
      FormulaireCGU: new FormControl(),
      "Vitesse maximum": new FormControl( '', Validators.max(25)),
      "Numero de gravage": new FormControl(),
      "N°série": new FormControl(),
      Marque: new FormControl(),
      Modèle: new FormControl(),
    });

     if ( localStorage.getItem('Bike') !== null)
     {
       this.bike = JSON.parse(localStorage.getItem('Bike')!);  
       this.formBike.controls['Formulaire'].setValue(localStorage.getItem('Formulaire')=='true'?true:false) ;
       this.formBike.controls['FormulaireCGU'].setValue(localStorage.getItem('FormulaireCGU')=='true'?true:false) ;
       this.formBike.controls['Formulairedeux'].setValue(localStorage.getItem('Formulairedeux')=='true'?true:false) ;
    }else{
     

      this.bike.purchaseDate=this.datePipe.transform(this.myDate, 'yyyy-MM-dd')!;
     
    } 
  }

  
  Previouspage(valueemitted :any){
          localStorage.setItem('Formulaire',this.formBike.controls['Formulaire'].value)
          localStorage.setItem('FormulaireCGU',this.formBike.controls['FormulaireCGU'].value)
          localStorage.setItem('Formulairedeux',this.formBike.controls['Formulairedeux'].value)
    localStorage.setItem('Bike', JSON.stringify(this.bike));
    this.changepagenumber.emit(valueemitted)
  }
  
  Nextpage(valueemitted :any){
     localStorage.setItem('Formulaire',this.formBike.controls['Formulaire'].value)
    localStorage.setItem('FormulaireCGU',this.formBike.controls['FormulaireCGU'].value)
    localStorage.setItem('Formulairedeux',this.formBike.controls['Formulairedeux'].value) 
  
    this.status = true ;
    this.bike.purchaseDate= this.datePipe.transform(this.bike.purchaseDate, "yyyy-MM-dd")?.toString() || '';
 
    const mapped = Object.keys(this.formBike.controls).map(key => 
      ({type: key, value: this.formBike.controls[key]} )
    );
    mapped.forEach((value: { type: string; value: any;}) => {

    if(  value.value.status === "INVALID" && (value.value.value  === null ||value.value.value  === "" || value.value.value === undefined   ))
    {

       this.alertWarning(value.type +' est invalide!' );
       this.status = false ;
    }
    if ( value.value.value !== null && value.value.value === false )
    { 
    if ( value.type === 'Formulairedeux')
    { 
         var  idform = this.document.getElementById('invalidCheck3');
         idform?.classList.add('is-invalid');
         this.alertWarning(' Merci de vérifier les cases à cocher !' );
         this.status = false;
    }

    else  if ( value.type === 'FormulaireCGU')
    { 
         var  idform = this.document.getElementById('invalidCheck4');
         idform?.classList.add('is-invalid');
         this.alertWarning('Merci de vérifier les cases à cocher !' );
         this.status = false;
    }
     
   }
    else  if ( value.value.status === "INVALID" &&   (value.value.value  === null ||value.value.value  === "" || value.value.value === undefined   ) )
       {
          
          if ( value.type === 'Formulairedeux' ||  value.type === 'FormulaireCGU'  )
          {
            this.alertWarning('Merci de vérifier les cases à cocher !' );
            this.status = false;
          }
          else {
            this.alertWarning("Vous devez remplir les champs obligatoires");
            this.status = false;
          }
       }else if ( value.value.status === "INVALID" && (value.value.value  !== null ||value.value.value  !== "" || value.value.value !== undefined)){
          if (value.type=="Prix dachat"){
            this.alertWarning("Vous devez verfier  les champs obligatoires");
            this.status = false;
          }else if (value.type=='Vitesse maximum'){
            this.alertWarning('La vitesse maximum de votre vélo ne vous permet pas de souscrire à cette assurance ');
            this.status = false;
          }
      
       }
     
     
    });
    this.activatedRoute.paramMap.subscribe((params: ParamMap)=> {
      this.storeRef = params.get('storeRef')!;
    
    });
 if ( this.status )
 {

    if (this.isOverTwoYear(new Date(this.bike.purchaseDate))) {
      this.alertWarning("La souscription n'est envisageable que pour les achats de moins de 24 mois.");
    } else{
      localStorage.setItem('Bike', JSON.stringify(this.bike));
      this.apicreationService.pushInfoBike( this.bike) 
      this.changepagenumber.emit(valueemitted)
    }
   
  

}
  }
  alertWarning(error :any) {
    swal.fire({
      title:'',
      icon: 'warning',
      html: error,
      showConfirmButton: true,
      showCloseButton: true,
      confirmButtonText: 'Fermer',
      confirmButtonColor: '#e75e5e',
    })
  }
  getNumber(event: number) {
   if ( event > 8000)
   return ; 
    
  }



  goToForm() {
    window.open('form/formulaire', "_blank");
  }



  isActiveformulaireCG(event:any) {
    var  idform = this.document.getElementById('invalidCheck4');
    if (event!.target.checked) {
      idform?.classList.remove("is-invalid"); 
    } else {
      idform?.classList.add('is-invalid');
    }
  }
  
  isActiveformulairedeux(event:any) {
    var  idform = this.document.getElementById('invalidCheck3');
    if (event!.target.checked) {
      idform?.classList.remove("is-invalid"); 
    } else {
      idform?.classList.add('is-invalid');
    }
  }


  isOverTwoYear(dateachat: Date) {
    var yearDifMs = Date.now() - dateachat.getTime();
    var yearDate = new Date(yearDifMs);
    var year = yearDate.getUTCFullYear() - 1970;
    if ((year > 1 ) || (year < 0)) {
      this.formBike.controls['Date dachat'].setErrors({
        notconfirmid: true,
      });
      return true;
    } else {
      return false;
    }
  } 

}
