export class Client {
    title:string;
    firstName: string;
    lastName: string;
    email:string;
    phone:string;
    mobile:string;
    birthDate:string;
    type:string;
    occupation:Occupation;
    comments:string;
    address:Address;
    company:Company;
    IBAN:string; 
    AccountHolder:string; 

}
export class Company {
    RS:string="";
    siren: string="";
    address: Address;
}



export class Address {
    streetNumber:string="";
    streetName: string="";
    city: string="";
    country:string="";
    postalCode:string="";
    supplement:string="";
}
   export enum ClienttypeEnum {
  
    'Private'='Privée',
    'Professionnal' ='Professionnelle',
     
   }
export enum Occupation {
    'Employee'='Employée',
    'Liberal or independant worker'='Travailleur libéral ou indépendant',
    'Loueur Independant'='Loueur Indépendant',
    'Office de tourisme, Mairie'='Loueur Office de tourisme, Mairie',
    'Camping, Hotels'='Camping, Hôtels',
    'Entreprise Privé'='Entreprise Privée',
    'Administration ou entreprise publique'='Administration ou entreprise publique',
    'Autre professionnel'='Autre professionnel',
    'Other'='Autre',
   }